import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as models from "../models";
import useAuth from "../components/AuthContextProvider";
import { Modal, TablePagination } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function DeliverablesListContent({ id }) {
  const [loading, setLoading] = React.useState(true);
  const [deliverables, setDeliverables] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [currentToken, setCurrentToken] = useState(); // pagination follows https://dev.to/onlybakam/implementing-pagination-with-aws-appsync-5d08
  const [nextToken, setNextToken] = useState();
  const [tokens, setTokens] = useState([]);
  const { ready, userID, accountTypeAgency, accountTypeTalent } = useAuth();
  const navigate = useNavigate();

  const handleOpen = (delId) => {
    if (!delId) {
      navigate(`/deliverable/add/${id}`);
    } else {
      navigate(`/deliverable/${delId}`);
    }
  };

  const handleChangePage = (event, newPage) => {
    if (newPage === page) {
      return;
    }
    setPage(newPage);
    if (page > newPage) {
      // backward
      setCurrentToken(tokens.pop());
      setTokens([...tokens]);
      setNextToken(null);
    } else {
      // forward
      setTokens((v) => [...v, currentToken]);
      setCurrentToken(nextToken);
      setNextToken(null);
    }
  };

  const resetPage = () => {
    setPage(0);
    setCurrentToken(null);
    setTokens([]);
    setNextToken(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resetPage();
  };

  useEffect(() => {
    if (ready) {
      fetchDeliverables();
    }
  }, [ready, id, currentToken, rowsPerPage]);

  const fetchDeliverables = () => {
    setLoading(true);
    const result = API.graphql({
      query: queries.listDeliverablesByProject,
      variables: {
        projectID: id,
        limit: rowsPerPage,
        nextToken: currentToken,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        if (accountTypeAgency) {
          var filtered = val.data.listDeliverablesByProject.items.filter(
            (x) => !x._deleted
          );
          setDeliverables(filtered);
          // setCount(filtered.length);
        } else if (accountTypeTalent) {
          var filtered = val.data.listDeliverablesByProject.items.filter(
            (x) => !x._deleted && x.deliverableResourceId == userID
          );
          setDeliverables(filtered);
          // setCount(filtered.length);
        }
        setNextToken(val.data.listDeliverablesByProject.nextToken);
        setLoading(false);
      })
      .catch(({ name, message }) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load deliverables", name, message);
        }
        // alert("Could not load deliverables, check your internet connection")
      });
  };

  return (
    <Box sx={{ caretColor: "transparent" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          mb: 2,
          caretColor: "transparent",
        }}
      >
        {/* <Title>Clients</Title> */}
        {loading && (
          <CircularProgress
            size={36}
            sx={{
              color: green[500],
            }}
          />
        )}
        {accountTypeAgency && (
          <Button
            data-amplify-analytics-on="click"
            data-amplify-analytics-name="Deliverables_AddClick"
            variant="contained"
            sx={{ m: 1 }}
            onClick={() => {
              handleOpen();
            }}
          >
            Add
          </Button>
        )}
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={-1} // to use server side pagination, see MUI doc
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ disabled: !nextToken }}
      ></TablePagination>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>Title</StyledTableCell>
              <StyledTableCell>Brief</StyledTableCell>
              <StyledTableCell>Budget</StyledTableCell>
              <StyledTableCell>Deadline</StyledTableCell>
              {accountTypeAgency && <StyledTableCell>Talent</StyledTableCell>}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {deliverables.length > 0 ? (
              deliverables.map((del) => {
                return (
                  <StyledTableRow
                    key={del.id}
                    onDoubleClick={(evt) => handleOpen(del.id)}
                  >
                    <StyledTableCell>{del.Title}</StyledTableCell>
                    <StyledTableCell>{del.Brief}</StyledTableCell>
                    <StyledTableCell>${del.Budget}</StyledTableCell>
                    <StyledTableCell>
                      {new Date(del.Deadline).toLocaleDateString("en-AU")}
                    </StyledTableCell>
                    {accountTypeAgency && (
                      <StyledTableCell>
                        {del.Resource && del.Resource.FirstName}
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                );
              })
            ) : (
              <StyledTableRow>
                <StyledTableCell>No deliverables to display</StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default function DeliverablesList({ id }) {
  return <DeliverablesListContent id={id} />;
}

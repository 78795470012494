import IconButton from '@mui/material/IconButton';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Storage } from 'aws-amplify';

export function S3Link({ file }) {
  const getLink = async () => {
    // get the signed URL string
    const result = await Storage.get(file, { download: true }); // get key from Storage.list
    const filename = file.slice(file.lastIndexOf('/')+1);    // get only the filename & extension
    saveFile(result.Body, filename);
  }

  const saveFile = async (blob, file) => {
    const a = document.createElement('a');
    a.download = file;
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  return (
    <div>
      <IconButton sx={{ border: 1, m: 1 }} onClick={getLink}>
        <AttachFileIcon />
      </IconButton>
    </div>
  );
}
import * as models from "../models";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { Button, Chip, Stack, Typography } from "@mui/material";
import useAuth from "./AuthContextProvider";
import { API, graphqlOperation } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import { XeroIntegration } from "../globals";

function getUsername(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload).preferred_username;
}

export function XeroLinkStatus() {
  const [loading, setLoading] = useState(true);
  const { userID, userOrgID } = useAuth();
  const [xeroLink, setXeroLink] = useState();
  const state = userID;
  const xeroURL = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${XeroIntegration.clientID}&redirect_uri=${XeroIntegration.redirectUri}&scope=${XeroIntegration.scope}&state=${state}`;
  const navigate = useNavigate();

  const getXeroLink = () => {
    const result = API.graphql({
      query: queries.listOAuthLinks,
      variables: {
        filter: {
          AppName: { eq: XeroIntegration.AppName },
          organisationOAuthLinksId: { eq: userOrgID },
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var filtered = val.data.listOAuthLinks.items.filter((x) => !x._deleted);
        // console.log(xeroEntry);
        if (filtered.length > 0) {
          setXeroLink(filtered[0]);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("Could not fetch integrations", err);
        alert("Could not fetch integrations, check your internet connection");
      });
  };

  const addXero = () => {
    const newXero = {
      owner: userID,
      linkState: models.OAuthLinkState.NOT_SET_UP,
      AppName: XeroIntegration.AppName,
      AppID: "",
      Authorisation: "",
      Token: "",
      organisationOAuthLinksId: userOrgID,
    };

    const exec = API.graphql({
      query: mutations.createOAuthLink,
      variables: { input: newXero },
    });
    exec
      .then((x) => {
        // console.log(x);
        window.location.href = "/organisation/integrations";
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const removeXero = async () => {
    const exec1 = await API.graphql({
      query: queries.listOAuthLinks,
      variables: {
        filter: {
          AppName: { eq: XeroIntegration.AppName },
          organisationOAuthLinksId: { eq: userOrgID },
        },
      },
    });
    const filtered = exec1.data.listOAuthLinks.items.filter((x) => !x._deleted);

    const link = {
      id: filtered[0].id,
      _version: filtered[0]._version,
    };
    const exec = API.graphql({
      query: mutations.deleteOAuthLink,
      variables: { input: link },
    });
    exec
      .then((x) => {
        // console.log(x);
        window.location.href = "/organisation/integrations";
      })
      .catch((x) => {
        console.log(x);
      });
  };

  useEffect(() => {
    setLoading(true);
    getXeroLink();
  }, []);

  if (loading) {
    return (
      <div>
        <CircularProgress
          size={32}
          sx={{
            color: green[500],
          }}
        />
      </div>
    );
  } else if (!xeroLink) {
    return (
      <div>
        {/* <Chip label="Non existent" color="error" /> */}
        {/* <IconButton sx={{ border: 1, m: 1 }}>
          <LinkIcon />
        </IconButton> */}
        {/*
          # TODO Add link to privacy policy
        */}
        <Button variant="contained" onClick={() => addXero()}>
          Add integration
        </Button>
      </div>
    );
  } else if (xeroLink.linkState === models.OAuthLinkState.NOT_SET_UP) {
    return (
      <div>
        <Chip label="Not Setup" color="error" />
        {/* <IconButton sx={{ border: 1, m: 1 }}>
          <LinkIcon />
        </IconButton> */}
        {/*
          # TODO Add link to privacy policy
        */}
        <Typography sx={{ mt: 2, mb: 1, fontWeight: "bold" }}>
          Step 1: Log into Xero
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Clicking the button below will open a new tab where you will be asked
          to log into Xero.
        </Typography>
        <Button
          variant="contained"
          // target="_blank"
          // component="a"
          // href={xeroURL}
          onClick={() =>
            (window.location.href = "https://go.xero.com/Dashboard/")
          }
        >
          Log into Xero
        </Button>
        <Typography sx={{ mt: 3, mb: 1, fontWeight: "bold" }}>
          Step 2: Connect to Xero
        </Typography>
        <Typography sx={{ mt: 1, mb: 1 }}>
          Clicking the button below will open a new tab where you will be asked
          to authorise CoFlowx to access your data.
        </Typography>
        <Button
          variant="contained"
          // target="_blank"
          // component="a"
          // href={xeroURL}
          onClick={() => (window.location.href = xeroURL)}
        >
          Connect to Xero
        </Button>
      </div>
    );
  } else if (xeroLink.linkState === models.OAuthLinkState.CONNECTED) {
    return (
      <Stack>
        <div>
          <Chip label="Connected" color="primary" />
          {/* <IconButton sx={{ border: 1, m: 1 }}>
          <LinkIconOff />
        </IconButton> */}
          <Button onClick={() => removeXero()}>Disconnect from Xero</Button>
        </div>
        <div style={{ marginTop: 10 }}>
          <Typography>Xero Username: {getUsername(xeroLink.Token)}</Typography>
          <a href="https://go.xero.com/Settings/ConnectedApps" target="_blank">Click here to view Xero App Connections</a>
        </div>
      </Stack>
    );
  } else if (xeroLink.linkState === models.OAuthLinkState.DISCONNECTED) {
    return (
      <div>
        <Chip label="Disconnected" color="success" />
        {/* <IconButton sx={{ border: 1, m: 1 }}>
          <LinkIcon />
        </IconButton> */}
        <Button
          variant="contained"
          target="_blank"
          component="a"
          href={xeroURL}
        >
          Reconnect to Xero
        </Button>
      </div>
    );
  }

  return <div>(err)</div>;
}
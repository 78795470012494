
export const listTalents = /* GraphQL */ `
query ListTalents(
  $filter: ModelTalentFilterInput
  $limit: Int
  $nextToken: String
) {
  listTalents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      User {
        id
        FirstName
        LastName
        Email
      }
      _deleted
    }
    nextToken
    startedAt
  }
}
`;

export const getProject = /* GraphQL */ `
query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    owner
    Organisation {
      id
      Name
    }
    Title
    Overview
    ClientBudget
    Budget
    Deliverables {
      items {
        id
        owner
        Title
        Brief
        Budget
        Allocated
        Deadline
        Invoices {
          items {
            id
            Talent {
              id
              UserID
              FirstName
              LastName
            }
            Status
            File
            Total
            Hours
            deliverableID
            _deleted
            _version
          }
        }
        projectID
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deliverableResourceId
      }
      nextToken
      startedAt
    }
    Client {
      id
      Name
    }
    StartDate
    Deadline
    ProjectStatus
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    Custom1
    Custom2
    Custom3
  }
}
`;

export const listProjectInvoices = /* GraphQL */ `
  query ListProjectsByOrg(
    $organisationProjectsId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByOrg(
      organisationProjectsId: $organisationProjectsId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        Organisation {
          id
          owner
        }
        organisationProjectsId
        updatedAt
        Title
        Overview
        ClientBudget
        Budget
        Deliverables {
          items {
            id
            Title
            Budget
            Allocated
            Deadline
            Resource {
              id
              UserID
              FirstName
              LastName
            }
            ResourceStatus
            Invoices {
              items {
                id
                Talent {
                  id
                  UserID
                  FirstName
                  LastName
                }
                Status
                Type
                Supplier
                File
                Total
                Hours
                TaxInclusive
                deliverableID
                Deliverables
                _deleted
                _version
              }
            }
            deliverableResourceId
            _version
            _deleted
          }
          nextToken
          startedAt
        }
        Client {
          id
          Name
        }
        StartDate
        Deadline
        ProjectStatus
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        projectClientId
      }
      nextToken
      startedAt
    }
  }
`;

export const listProjectInvoicesForTalent = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $talentId: ID
    ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        Organisation {
          id
          Name
        }
        Title
        Overview
        ClientBudget
        Budget
        Deliverables {
          items {
            id
            Title
            Brief
            Budget
            Allocated
            Deadline
            Resource {
              id
              FirstName
              LastName
            }
            ResourceStatus
            Invoices(filter: {invoiceTalentId: {eq: $talentId}}) {
              items {
                id
                Talent {
                  id
                  UserID
                  FirstName
                  LastName
                }
                Status
                Type
                Supplier
                File
                Total
                Hours
                TaxInclusive
                deliverableID
                Deliverables
                invoiceReferenceNumber
                Custom1 # used for notification when talent's invoice is approved
                _deleted
                _version
              }
            }
          }
        }
        Client {
          Name
        }
        Deadline
        ProjectStatus
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
  `;

export const listProjectProposals = /* GraphQL */ `
query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      Title
      Client {
        id
        Name
      }
      Deliverables {
        items {
          Title
          Proposals {
            items {
              id
              owner
              Amount
              Scope
              Approved
              Status
              Talent {
                id
                FirstName
                LastName
              }
              deliverableID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              proposalTalentId
            }
            nextToken
            startedAt
          }
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;

export const listProjectProposalsForTalent = /* GraphQL */ `
query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
  $talentId: ID  
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      owner
      Title
      Client {
        id
        Name
      }
      Deliverables {
        items {
          id
          Title
          Proposals(filter: {proposalTalentId: {eq: $talentId}}) {
            items {
              id
              _version
              _deleted
              Amount
              Scope
              Approved
              Status
              Talent {
                id
                FirstName
                LastName
              }
              proposalTalentId
              Custom3 #used for notification when a talent's proposal is approved
            }
            nextToken
            startedAt
          }
        }
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;

export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        Organisation {
          id
          Name
        }
        Title
        Overview
        ClientBudget
        Budget
        Deliverables {
          items {
            id
            Title
            Budget
            Allocated
            Deadline
            Resource {
              id
              UserID
              FirstName
              LastName
            }
            ResourceStatus
            deliverableResourceId
            _version
            _deleted    
          }
          nextToken
          startedAt
        }
        Client {
          id
          Name
        }
        StartDate
        Deadline
        ProjectStatus
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationProjectsId
        projectClientId
      }
      nextToken
      startedAt
    }
  }
`;

export const listProjectsForTalent = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $talentId: ID  
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        Organisation {
          id
          Name
        }
        Title
        Overview
        ClientBudget
        Budget
        Deliverables(filter: {deliverableResourceId: {eq: $talentId}}) {
          items {
            id
            Title
            Budget
            Allocated
            Deadline
            Resource {
              id
              UserID
              FirstName
              LastName
            }
            ResourceStatus
            deliverableResourceId
            _version
            _deleted    
            Custom3 # used for notification when a talent is assigned a deliverable
          }
          nextToken
          startedAt
        }
        Client {
          id
          Name
        }
        Deadline
        ProjectStatus
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationProjectsId
        projectClientId
      }
      nextToken
      startedAt
    }
  }
`;

export const listProjectsByOrg = /* GraphQL */ `
  query ListProjectsByOrg(
    $organisationProjectsId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByOrg(
      organisationProjectsId: $organisationProjectsId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        Organisation {
          id
          owner
        }
        organisationProjectsId
        updatedAt
        Title
        Overview
        ClientBudget
        Budget
        Deliverables {
          items {
            id
            Title
            Budget
            Allocated
            Deadline
            Resource {
              id
              UserID
              FirstName
              LastName
            }
            ResourceStatus
            deliverableResourceId
            _version
            _deleted
          }
          nextToken
          startedAt
          nextToken
          startedAt
        }
        Client {
          id
          Name
        }
        StartDate
        Deadline
        ProjectStatus
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        projectClientId
      }
      nextToken
      startedAt
    }
  }
`;

export const searchProjectsWithDeliverables = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: [SearchableProjectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProjectAggregationInput]
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        organisationProjectsId
        updatedAt
        Title
        Overview
        ClientBudget
        Budget
        Deliverables {
          items {
            id
            Title
            Budget
            Allocated
            Deadline
            Resource {
              id
              UserID
              FirstName
              LastName
            }
            ResourceStatus
            deliverableResourceId
            _version
            _deleted
          }
          nextToken
          startedAt
          nextToken
          startedAt
        }
        Client {
          id
          owner
          Name
          Address
          PrimaryContact
          PhoneNumber
          organisationClientsId
          updatedAt
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
        }
        StartDate
        Deadline
        ProjectStatus
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        projectClientId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const listInvoicesForTalent = /* GraphQL */ `
  query listInvoicesForTalent(
    $invoiceTalentId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(
      filter: {
        invoiceTalentId: {
          eq: $invoiceTalentId
        }
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        ToEmail
        Talent {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        Status
        Type
        Supplier
        File
        Total
        Hours
        deliverableID
        Deliverables
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        invoiceTalentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as models from "../models";
import useAuth from "../components/AuthContextProvider";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export function InvoiceApproval({ inv, fetchInvoices }) {
  const { userID } = useAuth();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    approveInvoice();
  };

  const approveInvoice = () => {
    const invoice = {
      id: inv.id,
      _version: inv._version,
      invoiceTalentId: inv.invoiceTalentId,
      Status: models.InvoiceStatus.APPROVED,
    };

    const exec = API.graphql({
      query: mutations.updateInvoice,
      variables: { input: invoice },
    });
    exec
      .then((x) => {
        // console.log(x);
        fetchInvoices();
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const declineInvoice = () => {
    const invoice = {
      id: inv.id,
      _version: inv._version,
      invoiceTalentId: inv.invoiceTalentId,
      Status: models.InvoiceStatus.REJECTED,
    };

    const exec = API.graphql({
      query: mutations.updateInvoice,
      variables: { input: invoice },
    });
    exec
      .then((x) => {
        // console.log(x);
        fetchInvoices();
      })
      .catch((x) => {
        console.log(x);
      });
  };

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={2000}
        onClose={handleClose}
        // action={action}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Invoice has been approved
        </Alert>
      </Snackbar>
      {inv.Status === models.InvoiceStatus.SUBMITTED && <IconButton
        color="success"
        sx={{ border: 1, m: 1 }}
        onClick={() => {
          handleClick();
        }}
      >
        <DoneIcon />
      </IconButton>}
      <IconButton
        color="warning"
        sx={{ border: 1, m: 1 }}
        onClick={() => {
          declineInvoice();
        }}
      >
        <ClearIcon />
      </IconButton>
    </>
  );
}

/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrgPermission = /* GraphQL */ `
  query GetOrgPermission($id: ID!) {
    getOrgPermission(id: $id) {
      id
      owner
      User {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Permission
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationPermissionsId
      orgPermissionUserId
      __typename
    }
  }
`;
export const listOrgPermissions = /* GraphQL */ `
  query ListOrgPermissions(
    $filter: ModelOrgPermissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        User {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        Permission
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationPermissionsId
        orgPermissionUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrgPermissions = /* GraphQL */ `
  query SyncOrgPermissions(
    $filter: ModelOrgPermissionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrgPermissions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        User {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        Permission
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationPermissionsId
        orgPermissionUserId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      owner
      ToEmail
      Talent {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Status
      Type
      Supplier
      File
      Total
      Hours
      deliverableID
      Deliverables
      invoiceReferenceNumber
      TaxInclusive
      DueDate
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invoiceTalentId
      __typename
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        ToEmail
        Talent {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        Status
        Type
        Supplier
        File
        Total
        Hours
        deliverableID
        Deliverables
        invoiceReferenceNumber
        TaxInclusive
        DueDate
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        invoiceTalentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInvoices = /* GraphQL */ `
  query SyncInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvoices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        ToEmail
        Talent {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        Status
        Type
        Supplier
        File
        Total
        Hours
        deliverableID
        Deliverables
        invoiceReferenceNumber
        TaxInclusive
        DueDate
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        invoiceTalentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProposal = /* GraphQL */ `
  query GetProposal($id: ID!) {
    getProposal(id: $id) {
      id
      owner
      ToEmail
      Amount
      Scope
      Approved
      Status
      Talent {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      deliverableID
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      proposalTalentId
      __typename
    }
  }
`;
export const listProposals = /* GraphQL */ `
  query ListProposals(
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProposals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        ToEmail
        Amount
        Scope
        Approved
        Status
        Talent {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        deliverableID
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        proposalTalentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProposals = /* GraphQL */ `
  query SyncProposals(
    $filter: ModelProposalFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProposals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        ToEmail
        Amount
        Scope
        Approved
        Status
        Talent {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        deliverableID
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        proposalTalentId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      UserID
      FirstName
      LastName
      Expertise
      Industry
      Portfolio
      PhoneNumber
      TaxID
      AccountType
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      SubscriptionStatus
      EmailVerified
      Email
      FirstTime
      ProfilePicture
      Custom1
      Custom2
      Custom3
      Attachments {
        items {
          id
          File
          Size
          Type
          Description
          Custom1
          Custom2
          Custom3
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationUsersId
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAttachment = /* GraphQL */ `
  query GetAttachment($id: ID!) {
    getAttachment(id: $id) {
      id
      File
      Size
      Type
      Description
      Custom1
      Custom2
      Custom3
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAttachments = /* GraphQL */ `
  query ListAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        File
        Size
        Type
        Description
        Custom1
        Custom2
        Custom3
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAttachments = /* GraphQL */ `
  query SyncAttachments(
    $filter: ModelAttachmentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        File
        Size
        Type
        Description
        Custom1
        Custom2
        Custom3
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAttachment2 = /* GraphQL */ `
  query GetAttachment2($id: ID!) {
    getAttachment2(id: $id) {
      id
      File
      Size
      Type
      Description
      Custom1
      Custom2
      Custom3
      contractID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAttachment2s = /* GraphQL */ `
  query ListAttachment2s(
    $filter: ModelAttachment2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttachment2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        File
        Size
        Type
        Description
        Custom1
        Custom2
        Custom3
        contractID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAttachment2s = /* GraphQL */ `
  query SyncAttachment2s(
    $filter: ModelAttachment2FilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAttachment2s(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        File
        Size
        Type
        Description
        Custom1
        Custom2
        Custom3
        contractID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getTalent = /* GraphQL */ `
  query GetTalent($id: ID!) {
    getTalent(id: $id) {
      id
      owner
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Contract {
        id
        owner
        Status
        Rate
        Files {
          nextToken
          startedAt
          __typename
        }
        SentDate
        AcceptDate
        StartDate
        ExpiryDate
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      talentOrganisationId
      talentUserId
      talentContractId
      __typename
    }
  }
`;
export const listTalents = /* GraphQL */ `
  query ListTalents(
    $filter: ModelTalentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTalents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        Contract {
          id
          owner
          Status
          Rate
          SentDate
          AcceptDate
          StartDate
          ExpiryDate
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        talentOrganisationId
        talentUserId
        talentContractId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncTalents = /* GraphQL */ `
  query SyncTalents(
    $filter: ModelTalentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncTalents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        User {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        Contract {
          id
          owner
          Status
          Rate
          SentDate
          AcceptDate
          StartDate
          ExpiryDate
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        talentOrganisationId
        talentUserId
        talentContractId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDeliverable = /* GraphQL */ `
  query GetDeliverable($id: ID!) {
    getDeliverable(id: $id) {
      id
      owner
      Title
      Brief
      Budget
      Allocated
      Deadline
      Resource {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      ResourceStatus
      projectID
      Invoices {
        items {
          id
          owner
          ToEmail
          Status
          Type
          Supplier
          File
          Total
          Hours
          deliverableID
          Deliverables
          invoiceReferenceNumber
          TaxInclusive
          DueDate
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          invoiceTalentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Proposals {
        items {
          id
          owner
          ToEmail
          Amount
          Scope
          Approved
          Status
          deliverableID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          proposalTalentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      deliverableResourceId
      __typename
    }
  }
`;
export const listDeliverables = /* GraphQL */ `
  query ListDeliverables(
    $filter: ModelDeliverableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliverables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        Title
        Brief
        Budget
        Allocated
        Deadline
        Resource {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        ResourceStatus
        projectID
        Invoices {
          nextToken
          startedAt
          __typename
        }
        Proposals {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deliverableResourceId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDeliverables = /* GraphQL */ `
  query SyncDeliverables(
    $filter: ModelDeliverableFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeliverables(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        Title
        Brief
        Budget
        Allocated
        Deadline
        Resource {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        ResourceStatus
        projectID
        Invoices {
          nextToken
          startedAt
          __typename
        }
        Proposals {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deliverableResourceId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listDeliverablesByProject = /* GraphQL */ `
  query ListDeliverablesByProject(
    $projectID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeliverableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeliverablesByProject(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        Title
        Brief
        Budget
        Allocated
        Deadline
        Resource {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        ResourceStatus
        projectID
        Invoices {
          nextToken
          startedAt
          __typename
        }
        Proposals {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        deliverableResourceId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      owner
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organisationProjectsId
      updatedAt
      Title
      Overview
      ClientBudget
      Budget
      Deliverables {
        items {
          id
          owner
          Title
          Brief
          Budget
          Allocated
          Deadline
          ResourceStatus
          projectID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deliverableResourceId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Client {
        id
        owner
        Name
        Address
        PrimaryContact
        PhoneNumber
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationClientsId
        updatedAt
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StartDate
      Deadline
      ProjectStatus
      Custom1
      Custom2
      Custom3
      createdAt
      _version
      _deleted
      _lastChangedAt
      projectClientId
      __typename
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationProjectsId
        updatedAt
        Title
        Overview
        ClientBudget
        Budget
        Deliverables {
          nextToken
          startedAt
          __typename
        }
        Client {
          id
          owner
          Name
          Address
          PrimaryContact
          PhoneNumber
          organisationClientsId
          updatedAt
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StartDate
        Deadline
        ProjectStatus
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        projectClientId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncProjects = /* GraphQL */ `
  query SyncProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationProjectsId
        updatedAt
        Title
        Overview
        ClientBudget
        Budget
        Deliverables {
          nextToken
          startedAt
          __typename
        }
        Client {
          id
          owner
          Name
          Address
          PrimaryContact
          PhoneNumber
          organisationClientsId
          updatedAt
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StartDate
        Deadline
        ProjectStatus
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        projectClientId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listProjectsByOrg = /* GraphQL */ `
  query ListProjectsByOrg(
    $organisationProjectsId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByOrg(
      organisationProjectsId: $organisationProjectsId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationProjectsId
        updatedAt
        Title
        Overview
        ClientBudget
        Budget
        Deliverables {
          nextToken
          startedAt
          __typename
        }
        Client {
          id
          owner
          Name
          Address
          PrimaryContact
          PhoneNumber
          organisationClientsId
          updatedAt
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StartDate
        Deadline
        ProjectStatus
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        projectClientId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchProjects = /* GraphQL */ `
  query SearchProjects(
    $filter: SearchableProjectFilterInput
    $sort: [SearchableProjectSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableProjectAggregationInput]
  ) {
    searchProjects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationProjectsId
        updatedAt
        Title
        Overview
        ClientBudget
        Budget
        Deliverables {
          nextToken
          startedAt
          __typename
        }
        Client {
          id
          owner
          Name
          Address
          PrimaryContact
          PhoneNumber
          organisationClientsId
          updatedAt
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StartDate
        Deadline
        ProjectStatus
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        projectClientId
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      owner
      FromName
      From {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ToName
      To {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      ToEmail
      Message
      Status
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationFromId
      invitationToId
      __typename
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        FromName
        From {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ToName
        To {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        ToEmail
        Message
        Status
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        invitationFromId
        invitationToId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncInvitations = /* GraphQL */ `
  query SyncInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        FromName
        From {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ToName
        To {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        ToEmail
        Message
        Status
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        invitationFromId
        invitationToId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      owner
      Name
      Address
      PrimaryContact
      PhoneNumber
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organisationClientsId
      updatedAt
      Custom1
      Custom2
      Custom3
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        Name
        Address
        PrimaryContact
        PhoneNumber
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationClientsId
        updatedAt
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        Name
        Address
        PrimaryContact
        PhoneNumber
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationClientsId
        updatedAt
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listClientsByOrg = /* GraphQL */ `
  query ListClientsByOrg(
    $organisationClientsId: ID!
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientsByOrg(
      organisationClientsId: $organisationClientsId
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        Name
        Address
        PrimaryContact
        PhoneNumber
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationClientsId
        updatedAt
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchClients = /* GraphQL */ `
  query SearchClients(
    $filter: SearchableClientFilterInput
    $sort: [SearchableClientSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableClientAggregationInput]
  ) {
    searchClients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        Name
        Address
        PrimaryContact
        PhoneNumber
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationClientsId
        updatedAt
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
export const getContract = /* GraphQL */ `
  query GetContract($id: ID!) {
    getContract(id: $id) {
      id
      owner
      Status
      Rate
      Files {
        items {
          id
          File
          Size
          Type
          Description
          Custom1
          Custom2
          Custom3
          contractID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      SentDate
      AcceptDate
      StartDate
      ExpiryDate
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContracts = /* GraphQL */ `
  query ListContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContracts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        Status
        Rate
        Files {
          nextToken
          startedAt
          __typename
        }
        SentDate
        AcceptDate
        StartDate
        ExpiryDate
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContracts = /* GraphQL */ `
  query SyncContracts(
    $filter: ModelContractFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContracts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        Status
        Rate
        Files {
          nextToken
          startedAt
          __typename
        }
        SentDate
        AcceptDate
        StartDate
        ExpiryDate
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      owner
      Name
      Address
      PhoneNumber
      Email
      Website
      TaxID
      Users {
        items {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          owner
          organisationProjectsId
          updatedAt
          Title
          Overview
          ClientBudget
          Budget
          StartDate
          Deadline
          ProjectStatus
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          projectClientId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          owner
          Name
          Address
          PrimaryContact
          PhoneNumber
          organisationClientsId
          updatedAt
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      OAuthLinks {
        items {
          id
          owner
          linkState
          AppName
          AppID
          Authorisation
          Token
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationOAuthLinksId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Permissions {
        items {
          id
          owner
          Permission
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationPermissionsId
          orgPermissionUserId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOrganisations = /* GraphQL */ `
  query SyncOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganisations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOAuthLink = /* GraphQL */ `
  query GetOAuthLink($id: ID!) {
    getOAuthLink(id: $id) {
      id
      owner
      linkState
      AppName
      AppID
      Authorisation
      Token
      Custom1
      Custom2
      Custom3
      organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationOAuthLinksId
      __typename
    }
  }
`;
export const listOAuthLinks = /* GraphQL */ `
  query ListOAuthLinks(
    $filter: ModelOAuthLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOAuthLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        linkState
        AppName
        AppID
        Authorisation
        Token
        Custom1
        Custom2
        Custom3
        organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationOAuthLinksId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOAuthLinks = /* GraphQL */ `
  query SyncOAuthLinks(
    $filter: ModelOAuthLinkFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOAuthLinks(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        linkState
        AppName
        AppID
        Authorisation
        Token
        Custom1
        Custom2
        Custom3
        organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationOAuthLinksId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;

import * as React from "react";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { CircularProgress, Alert, AlertTitle, Tab, Tabs } from "@mui/material";
import { green } from "@mui/material/colors";
import useAuth from "../components/AuthContextProvider";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { FormMode } from "../globals";
import TextInput from "../components/TextInput";
import { TabPanel, a11yProps } from "../components/TabPanel";
import { XeroLinkStatus } from "../components/XeroLinkStatus";

function OrganizationContent({ openTab }) {
  const { ready, userID, userOrgID } = useAuth();
  const [loading, setLoading] = useState(true);
  const [formMode, setformMode] = useState(FormMode.View);
  const [version, setVersion] = useState("");
  const [tab, setTab] = React.useState(0);

  // form states
  const [formName, setFormName] = useState("");
  const [formAddress, setFormAddress] = useState("");
  const [formPhoneNumber, setFormPhoneNumber] = useState("");
  const [formEmail, setFormEmail] = useState("");
  const [formWebsite, setFormWebsite] = useState("");
  const [formTaxID, setFormTaxID] = useState("");

  // result or error states
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (ready) {
      setLoading(true);
      loadOrganization();
      setLoading(false);
    }

    if (openTab) {
      handleTabChange(null, openTab);
    }
  }, [ready]);

  const setSuccess = (msg) => {
    setSuccessMsg(msg);
    setErrorMsg("");
  };

  const setError = (msg) => {
    setSuccessMsg("");
    setErrorMsg(msg);
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const loadOrganization = () => {
    console.log(`userOrgID: ${userOrgID}`);
    API.graphql({
      query: queries.getOrganisation,
      variables: { id: userOrgID },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then((val) => {
        setVersion(val.data.getOrganisation._version);
        setFormName(val.data.getOrganisation.Name || "");
        setFormAddress(val.data.getOrganisation.Address || "");
        setFormPhoneNumber(val.data.getOrganisation.PhoneNumber || "");
        setFormEmail(val.data.getOrganisation.Email || "");
        setFormWebsite(val.data.getOrganisation.Website || "");
        setFormTaxID(val.data.getOrganisation.TaxID || "");
      })
      .catch(({ name, message }) => {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load organisation detail", name, message);
        }
        // alert(
        //   "Could not load organisation detail, check your internet connection"
        // );
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // validatition
    if (!formName) {
      setError("name cann't be empty");
      return;
    }

    const org = {
      id: userOrgID,
      _version: version,
      Name: formName,
      Address: formAddress,
      PhoneNumber: formPhoneNumber,
      Email: formEmail,
      Website: formWebsite,
      TaxID: formTaxID,
    };

    API.graphql({
      query: mutations.updateOrganisation,
      variables: { input: org },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    })
      .then((val) => {
        setformMode(FormMode.View);
        setSuccess("Update Successfully.");
      })
      .catch((err) => {
        console.log(err);
        setError("Error when updating.");
      });
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Organisation Detail
            </Typography>
            {loading ? (
              <Container maxWidth="xl">
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="start"
                  // style={{ minHeight: '100vh' }}
                  sx={{ pt: 20 }}
                >
                  <CircularProgress
                    size={72}
                    sx={{
                      color: green[500],
                    }}
                  />
                </Grid>
              </Container>
            ) : (
              <Container maxWidth="xl">
                <Box
                  sx={{
                    p: 0,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    aria-label="tabs"
                  >
                    <Tab
                      label="General"
                      {...a11yProps(0)}
                      sx={{ textTransform: "none" }}
                    />
                    <Tab
                      label="Integrations"
                      {...a11yProps(1)}
                      sx={{ textTransform: "none" }}
                    />
                  </Tabs>
                  <TabPanel value={tab} index={0}>
                    <Box
                      component="form"
                      noValidate
                      sx={{ mt: 3 }}
                      method="POST"
                      onSubmit={handleSubmit}
                      onChange={(val) => setformMode(FormMode.Edit)}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextInput
                            name="name"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            autoFocus
                            value={formName}
                            onChange={(val) => setFormName(val.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            name="address"
                            fullWidth
                            id="address"
                            label="Address"
                            value={formAddress}
                            onChange={(val) => setFormAddress(val.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            name="phoneNumber"
                            fullWidth
                            id="phoneNumber"
                            label="Phone Number"
                            value={formPhoneNumber}
                            onChange={(val) =>
                              setFormPhoneNumber(val.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            name="email"
                            fullWidth
                            id="email"
                            label="Email"
                            value={formEmail}
                            onChange={(val) => setFormEmail(val.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            name="website"
                            fullWidth
                            id="website"
                            label="Website"
                            value={formWebsite}
                            onChange={(val) => setFormWebsite(val.target.value)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextInput
                            name="taxID"
                            fullWidth
                            id="taxID"
                            label="Tax ID"
                            onChange={(val) => setFormTaxID(val.target.value)}
                            value={formTaxID}
                          />
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, height: "60px" }}
                        disabled={formMode === FormMode.View}
                      >
                        UPDATE
                      </Button>
                      {successMsg && (
                        <Alert severity="success">{successMsg}</Alert>
                      )}
                      {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
                    </Box>
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <Grid container direction="row" spacing={3}>
                      <Grid item lg={2}>
                        <Typography component="h2">Xero Integration</Typography>
                      </Grid>
                      <Grid item lg={6}>
                        <XeroLinkStatus />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Box>
              </Container>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default function Organization({ tab }) {
  return <OrganizationContent openTab={tab} />;
}

// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const InvitationStatus = {
  "SENT": "SENT",
  "ACCEPTED": "ACCEPTED",
  "REJECTED": "REJECTED"
};

const ContractStatus = {
  "UNDEFINED": "UNDEFINED",
  "SENT": "SENT",
  "ACCEPTED": "ACCEPTED",
  "REJECTED": "REJECTED",
  "EXPIRED": "EXPIRED"
};

const InvoiceStatus = {
  "SUBMITTED": "SUBMITTED",
  "APPROVED": "APPROVED",
  "REJECTED": "REJECTED"
};

const ProjectStatus = {
  "OPEN": "OPEN",
  "IN_PROGRESS": "IN_PROGRESS",
  "REVIEW": "REVIEW",
  "CLOSED": "CLOSED"
};

const ResourceStatus = {
  "NOT_ASSIGNED": "NOT_ASSIGNED",
  "IN_REVIEW": "IN_REVIEW",
  "ASSIGNED": "ASSIGNED"
};

const Invoicetype = {
  "SUPPLIER": "SUPPLIER",
  "TALENT": "TALENT"
};

const ProposalStatus = {
  "SENT": "SENT",
  "ACCEPTED": "ACCEPTED",
  "DECLINED": "DECLINED",
  "SUBMITTED": "SUBMITTED",
  "APPROVED": "APPROVED",
  "REJECTED": "REJECTED"
};

const SubscriptionStatus = {
  "TRIAL": "TRIAL",
  "MONTHLY": "MONTHLY",
  "ANNUAL": "ANNUAL"
};

const OrgPermissionType = {
  "ADMIN": "ADMIN",
  "BILLING": "BILLING",
  "MEMBER": "MEMBER",
  "VIEWER": "VIEWER",
  "GUEST": "GUEST"
};

const AccountType = {
  "AGENCY": "AGENCY",
  "TALENT": "TALENT",
  "DEV": "DEV"
};

const OAuthLinkState = {
  "NOT_SET_UP": "NOT_SET_UP",
  "CONNECTED": "CONNECTED",
  "DISCONNECTED": "DISCONNECTED"
};

const { OrgPermission, Invoice, Proposal, User, Attachment, Attachment2, Talent, Deliverable, Project, Invitation, Client, Contract, Organisation, OAuthLink } = initSchema(schema);

export {
  OrgPermission,
  Invoice,
  Proposal,
  User,
  Attachment,
  Attachment2,
  Talent,
  Deliverable,
  Project,
  Invitation,
  Client,
  Contract,
  Organisation,
  OAuthLink,
  InvitationStatus,
  ContractStatus,
  InvoiceStatus,
  ProjectStatus,
  ResourceStatus,
  Invoicetype,
  ProposalStatus,
  SubscriptionStatus,
  OrgPermissionType,
  AccountType,
  OAuthLinkState
};
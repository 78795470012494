import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Title from "../components/Title";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import * as queries from "../graphql/queries";
import useAuth from "../components/AuthContextProvider";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Modal,
  Select,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import TextInput from "../components/TextInput";
import { S3Link } from "../components/S3Link";
import { InvoiceStatus } from "../components/InvoiceStatus";

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
}));

function Row(props) {
  const { del, index } = props;
  const [projectDetails, setProjectDetails] = useState({});
  const [deliverableDetails, setDeliverableDetails] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const projectData = await getProjectDetails(del.projectID);
      const deliverableData = await getDeliverableDetails(del.deliverableID);
      setProjectDetails(projectData);
      setDeliverableDetails(deliverableData);
      setLoadingDetails(false);
    }
    fetchData();
  }, [del.projectID, del.deliverableID]);

  const getProjectDetails = async (id) => {
    const exec = await API.graphql({
      query: queries.getProject,
      variables: { id: id },
    });
    return {
      projectName: exec.data.getProject.Title,
      agencyName: exec.data.getProject.Organisation.Name,
    };
  };

  const getDeliverableDetails = async (id) => {
    const exec = await API.graphql({
      query: queries.getDeliverable,
      variables: { id: id },
    });
    return {
      deliverableName: exec.data.getDeliverable.Title,
      talentName: exec.data.getDeliverable.Resource.FirstName,
      deliverableBudget: exec.data.getDeliverable.Budget,
    };
  };

  if (loadingDetails) {
    return (
      <TableRow>
        <TableCell colSpan={6}>
          <CircularProgress />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <Grid container item spacing={2} sx={{ m: 0.5 }} key={index}>
        <Grid item xs={2}>
          {/* Project */}
          <FormControl>
            <TextInput
              required
              id={`project-${index}`}
              name="project"
              label="Project"
              fullWidth
              variant="outlined"
              value={projectDetails.projectName}
              readOnly
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          {/* Deliverable */}
          <FormControl>
            <TextInput
              required
              id={`deliverable-${index}`}
              name="deliverable"
              label="Deliverable"
              fullWidth
              variant="outlined"
              value={deliverableDetails.deliverableName}
              readOnly
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          {/* Hours */}
          <FormControl>
            <TextInput
              required
              id={`hours-${index}`}
              name="hours"
              label="Hours"
              fullWidth
              variant="outlined"
              value={del.hours}
              readOnly
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          {/* Total */}
          <FormControl>
            <TextInput
              required
              id={`total-${index}`}
              name="total"
              label="Total"
              fullWidth
              variant="outlined"
              value={del.total}
              readOnly
            />
          </FormControl>
        </Grid>
        <Grid item xs={1}>
          <FormControlLabel
            label="GST"
            control={<Checkbox name="gst" checked={del.tax} />}
            readOnly
          />
        </Grid>
      </Grid>
    </>
  );
}

function InvoiceContent({ invoiceId }) {
  const { id } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [deliverables, setDeliverables] = useState([]);
  const [dueDate, setDueDate] = React.useState("");
  const [invRefNumber, setInvRefNumber] = React.useState("");
  const [formTotalHours, setFormTotalHours] = useState(0.0);
  const [formTotalAmount, setFormTotalAmount] = useState(0.0);
  const [fileLink, setFileLink] = useState("");
  const [talentName, setTalentName] = useState("");
  const [status, setStatus] = useState("");
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState();
  const { ready, userID, accountTypeAgency, accountTypeTalent } = useAuth();

  useEffect(() => {
    if (ready) {
      fetchDeliverablesFromInvoice();
    }
  }, [ready, invoiceId]);

  const fetchAgencyName = (id) => {
    const result = API.graphql({
      query: queries.getOrganisation,
      variables: { id: id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((x) => {
        setAgency(x.data.getOrganisation.Name);
      })
      .catch(({ name, message }) => {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not get organisation name", name, message);
        }
        alert(
          "Could not get organisation name, check your internet connection"
        );
      });
  };

  const fetchDeliverablesFromInvoice = () => {
    setLoading(true);
    const result = API.graphql({
      query: queries.getInvoice,
      variables: { id: id, limit: 1000 },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var invoiceDeliverables = JSON.parse(val.data.getInvoice.Deliverables);
        setDeliverables(invoiceDeliverables);
        setInvRefNumber(val.data.getInvoice.invoiceReferenceNumber);
        setDueDate(val.data.getInvoice.DueDate);
        setFormTotalAmount(val.data.getInvoice.Total);
        setFormTotalHours(val.data.getInvoice.Hours);
        setFileLink(val.data.getInvoice.File);
        setStatus(val.data.getInvoice.Status);
        fetchAgencyName(val.data.getInvoice.Custom2);
        setTalentName(
          val.data.getInvoice.Talent.FirstName +
            " " +
            val.data.getInvoice.Talent.LastName
        );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.message && error.message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load deliverables", error);
        }
        // alert("Could not load deliverables, check your internet connection")
      });
  };

  return (
    <Box
      component="main"
      sx={{
        caretColor: "transparent",
        mb: 3,
      }}
    >
      <Box
        sx={{
          mb: 2,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>
              <strong>Invoice details</strong>
            </Title>
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.75)", fontSize: "0.9rem" }}
            >
              View the list of deliverables in the invoice
            </Typography>
          </Stack>
        </Box>
      </Box>
      <Container maxWidth="xl">
        <Box
          component="form"
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column",
            ml: 10,
          }}
        >
          <Grid container xs={12}>
            {accountTypeTalent && <Grid container item spacing={2} sx={{ m: 0.5 }}>
              <Grid item xs={6.125}>
                <TextInput
                  required
                  id="agency"
                  name="agency"
                  fullWidth
                  variant="outlined"
                  value={agency}
                  readOnly
                />
              </Grid>
            </Grid>}
            <Grid container item spacing={2} sx={{ m: 0.5 }}>
              <Grid item xs={6.125}>
                <TextInput
                  required
                  id="invoice-Reference"
                  name="invoiceRefNumber"
                  label="Invoice Reference Number"
                  fullWidth
                  variant="outlined"
                  value={invRefNumber}
                  readOnly
                />
              </Grid>
              <Grid item xs={2}>
                <TextInput
                  required
                  id="dueDate"
                  name="dueDate"
                  label="Due Date"
                  type="date"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start"></InputAdornment>
                    ),
                  }}
                  value={dueDate}
                  readOnly
                />
              </Grid>
            </Grid>
            <Grid item>
              <Grid container item spacing={2} sx={{ m: 0.5 }}>
                <Grid item>
                  Status: <InvoiceStatus status={status} />
                </Grid>
              </Grid>
            </Grid>
            {accountTypeAgency && (
              <Grid item xs={12}>
                <Grid container item spacing={2} sx={{ m: 0.5 }}>
                  <Grid item xs={2}>
                    <TextInput
                      required
                      readOnly
                      id="talentName"
                      name="talentName"
                      label="Talent Name"
                      value={talentName}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} sx={{ my: 2 }}>
            {deliverables.map((data, index) => (
              <Row key={index} del={data} index={index} />
            ))}
          </Grid>
          <Grid item xs={12}>
            <Grid container item spacing={2} sx={{ m: 0.5 }}>
              <Grid item xs={2}>
                <TextInput
                  required
                  readOnly
                  id="totalHours"
                  name="totalHours"
                  label="Total Invoice Hours"
                  type="number"
                  value={formTotalHours}
                />
              </Grid>
              <Grid item xs={2}>
                <TextInput
                  required
                  readOnly
                  id="totalAmount"
                  name="totalAmount"
                  label="Total Invoice Amount"
                  type="number"
                  value={formTotalAmount}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container item spacing={2} sx={{ m: 0.5 }}>
              <Grid item xs={2}>
                File containing the invoice:
                {fileLink && <S3Link file={fileLink} />}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default function InvoiceView({ id }) {
  return <InvoiceContent invoiceId={id} />;
}

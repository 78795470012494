/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrgPermission = /* GraphQL */ `
  mutation CreateOrgPermission(
    $input: CreateOrgPermissionInput!
    $condition: ModelOrgPermissionConditionInput
  ) {
    createOrgPermission(input: $input, condition: $condition) {
      id
      owner
      User {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Permission
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationPermissionsId
      orgPermissionUserId
      __typename
    }
  }
`;
export const updateOrgPermission = /* GraphQL */ `
  mutation UpdateOrgPermission(
    $input: UpdateOrgPermissionInput!
    $condition: ModelOrgPermissionConditionInput
  ) {
    updateOrgPermission(input: $input, condition: $condition) {
      id
      owner
      User {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Permission
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationPermissionsId
      orgPermissionUserId
      __typename
    }
  }
`;
export const deleteOrgPermission = /* GraphQL */ `
  mutation DeleteOrgPermission(
    $input: DeleteOrgPermissionInput!
    $condition: ModelOrgPermissionConditionInput
  ) {
    deleteOrgPermission(input: $input, condition: $condition) {
      id
      owner
      User {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Permission
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationPermissionsId
      orgPermissionUserId
      __typename
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      owner
      ToEmail
      Talent {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Status
      Type
      Supplier
      File
      Total
      Hours
      deliverableID
      Deliverables
      invoiceReferenceNumber
      TaxInclusive
      DueDate
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invoiceTalentId
      __typename
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      owner
      ToEmail
      Talent {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Status
      Type
      Supplier
      File
      Total
      Hours
      deliverableID
      Deliverables
      invoiceReferenceNumber
      TaxInclusive
      DueDate
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invoiceTalentId
      __typename
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      owner
      ToEmail
      Talent {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Status
      Type
      Supplier
      File
      Total
      Hours
      deliverableID
      Deliverables
      invoiceReferenceNumber
      TaxInclusive
      DueDate
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invoiceTalentId
      __typename
    }
  }
`;
export const createProposal = /* GraphQL */ `
  mutation CreateProposal(
    $input: CreateProposalInput!
    $condition: ModelProposalConditionInput
  ) {
    createProposal(input: $input, condition: $condition) {
      id
      owner
      ToEmail
      Amount
      Scope
      Approved
      Status
      Talent {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      deliverableID
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      proposalTalentId
      __typename
    }
  }
`;
export const updateProposal = /* GraphQL */ `
  mutation UpdateProposal(
    $input: UpdateProposalInput!
    $condition: ModelProposalConditionInput
  ) {
    updateProposal(input: $input, condition: $condition) {
      id
      owner
      ToEmail
      Amount
      Scope
      Approved
      Status
      Talent {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      deliverableID
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      proposalTalentId
      __typename
    }
  }
`;
export const deleteProposal = /* GraphQL */ `
  mutation DeleteProposal(
    $input: DeleteProposalInput!
    $condition: ModelProposalConditionInput
  ) {
    deleteProposal(input: $input, condition: $condition) {
      id
      owner
      ToEmail
      Amount
      Scope
      Approved
      Status
      Talent {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      deliverableID
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      proposalTalentId
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      UserID
      FirstName
      LastName
      Expertise
      Industry
      Portfolio
      PhoneNumber
      TaxID
      AccountType
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      SubscriptionStatus
      EmailVerified
      Email
      FirstTime
      ProfilePicture
      Custom1
      Custom2
      Custom3
      Attachments {
        items {
          id
          File
          Size
          Type
          Description
          Custom1
          Custom2
          Custom3
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationUsersId
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      UserID
      FirstName
      LastName
      Expertise
      Industry
      Portfolio
      PhoneNumber
      TaxID
      AccountType
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      SubscriptionStatus
      EmailVerified
      Email
      FirstTime
      ProfilePicture
      Custom1
      Custom2
      Custom3
      Attachments {
        items {
          id
          File
          Size
          Type
          Description
          Custom1
          Custom2
          Custom3
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationUsersId
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      UserID
      FirstName
      LastName
      Expertise
      Industry
      Portfolio
      PhoneNumber
      TaxID
      AccountType
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      SubscriptionStatus
      EmailVerified
      Email
      FirstTime
      ProfilePicture
      Custom1
      Custom2
      Custom3
      Attachments {
        items {
          id
          File
          Size
          Type
          Description
          Custom1
          Custom2
          Custom3
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationUsersId
      __typename
    }
  }
`;
export const createAttachment = /* GraphQL */ `
  mutation CreateAttachment(
    $input: CreateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    createAttachment(input: $input, condition: $condition) {
      id
      File
      Size
      Type
      Description
      Custom1
      Custom2
      Custom3
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAttachment = /* GraphQL */ `
  mutation UpdateAttachment(
    $input: UpdateAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    updateAttachment(input: $input, condition: $condition) {
      id
      File
      Size
      Type
      Description
      Custom1
      Custom2
      Custom3
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAttachment = /* GraphQL */ `
  mutation DeleteAttachment(
    $input: DeleteAttachmentInput!
    $condition: ModelAttachmentConditionInput
  ) {
    deleteAttachment(input: $input, condition: $condition) {
      id
      File
      Size
      Type
      Description
      Custom1
      Custom2
      Custom3
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createAttachment2 = /* GraphQL */ `
  mutation CreateAttachment2(
    $input: CreateAttachment2Input!
    $condition: ModelAttachment2ConditionInput
  ) {
    createAttachment2(input: $input, condition: $condition) {
      id
      File
      Size
      Type
      Description
      Custom1
      Custom2
      Custom3
      contractID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateAttachment2 = /* GraphQL */ `
  mutation UpdateAttachment2(
    $input: UpdateAttachment2Input!
    $condition: ModelAttachment2ConditionInput
  ) {
    updateAttachment2(input: $input, condition: $condition) {
      id
      File
      Size
      Type
      Description
      Custom1
      Custom2
      Custom3
      contractID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteAttachment2 = /* GraphQL */ `
  mutation DeleteAttachment2(
    $input: DeleteAttachment2Input!
    $condition: ModelAttachment2ConditionInput
  ) {
    deleteAttachment2(input: $input, condition: $condition) {
      id
      File
      Size
      Type
      Description
      Custom1
      Custom2
      Custom3
      contractID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createTalent = /* GraphQL */ `
  mutation CreateTalent(
    $input: CreateTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    createTalent(input: $input, condition: $condition) {
      id
      owner
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Contract {
        id
        owner
        Status
        Rate
        Files {
          nextToken
          startedAt
          __typename
        }
        SentDate
        AcceptDate
        StartDate
        ExpiryDate
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      talentOrganisationId
      talentUserId
      talentContractId
      __typename
    }
  }
`;
export const updateTalent = /* GraphQL */ `
  mutation UpdateTalent(
    $input: UpdateTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    updateTalent(input: $input, condition: $condition) {
      id
      owner
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Contract {
        id
        owner
        Status
        Rate
        Files {
          nextToken
          startedAt
          __typename
        }
        SentDate
        AcceptDate
        StartDate
        ExpiryDate
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      talentOrganisationId
      talentUserId
      talentContractId
      __typename
    }
  }
`;
export const deleteTalent = /* GraphQL */ `
  mutation DeleteTalent(
    $input: DeleteTalentInput!
    $condition: ModelTalentConditionInput
  ) {
    deleteTalent(input: $input, condition: $condition) {
      id
      owner
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      User {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      Contract {
        id
        owner
        Status
        Rate
        Files {
          nextToken
          startedAt
          __typename
        }
        SentDate
        AcceptDate
        StartDate
        ExpiryDate
        Custom1
        Custom2
        Custom3
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      talentOrganisationId
      talentUserId
      talentContractId
      __typename
    }
  }
`;
export const createDeliverable = /* GraphQL */ `
  mutation CreateDeliverable(
    $input: CreateDeliverableInput!
    $condition: ModelDeliverableConditionInput
  ) {
    createDeliverable(input: $input, condition: $condition) {
      id
      owner
      Title
      Brief
      Budget
      Allocated
      Deadline
      Resource {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      ResourceStatus
      projectID
      Invoices {
        items {
          id
          owner
          ToEmail
          Status
          Type
          Supplier
          File
          Total
          Hours
          deliverableID
          Deliverables
          invoiceReferenceNumber
          TaxInclusive
          DueDate
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          invoiceTalentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Proposals {
        items {
          id
          owner
          ToEmail
          Amount
          Scope
          Approved
          Status
          deliverableID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          proposalTalentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      deliverableResourceId
      __typename
    }
  }
`;
export const updateDeliverable = /* GraphQL */ `
  mutation UpdateDeliverable(
    $input: UpdateDeliverableInput!
    $condition: ModelDeliverableConditionInput
  ) {
    updateDeliverable(input: $input, condition: $condition) {
      id
      owner
      Title
      Brief
      Budget
      Allocated
      Deadline
      Resource {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      ResourceStatus
      projectID
      Invoices {
        items {
          id
          owner
          ToEmail
          Status
          Type
          Supplier
          File
          Total
          Hours
          deliverableID
          Deliverables
          invoiceReferenceNumber
          TaxInclusive
          DueDate
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          invoiceTalentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Proposals {
        items {
          id
          owner
          ToEmail
          Amount
          Scope
          Approved
          Status
          deliverableID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          proposalTalentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      deliverableResourceId
      __typename
    }
  }
`;
export const deleteDeliverable = /* GraphQL */ `
  mutation DeleteDeliverable(
    $input: DeleteDeliverableInput!
    $condition: ModelDeliverableConditionInput
  ) {
    deleteDeliverable(input: $input, condition: $condition) {
      id
      owner
      Title
      Brief
      Budget
      Allocated
      Deadline
      Resource {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      ResourceStatus
      projectID
      Invoices {
        items {
          id
          owner
          ToEmail
          Status
          Type
          Supplier
          File
          Total
          Hours
          deliverableID
          Deliverables
          invoiceReferenceNumber
          TaxInclusive
          DueDate
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          invoiceTalentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Proposals {
        items {
          id
          owner
          ToEmail
          Amount
          Scope
          Approved
          Status
          deliverableID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          proposalTalentId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      deliverableResourceId
      __typename
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      owner
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organisationProjectsId
      updatedAt
      Title
      Overview
      ClientBudget
      Budget
      Deliverables {
        items {
          id
          owner
          Title
          Brief
          Budget
          Allocated
          Deadline
          ResourceStatus
          projectID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deliverableResourceId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Client {
        id
        owner
        Name
        Address
        PrimaryContact
        PhoneNumber
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationClientsId
        updatedAt
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StartDate
      Deadline
      ProjectStatus
      Custom1
      Custom2
      Custom3
      createdAt
      _version
      _deleted
      _lastChangedAt
      projectClientId
      __typename
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      owner
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organisationProjectsId
      updatedAt
      Title
      Overview
      ClientBudget
      Budget
      Deliverables {
        items {
          id
          owner
          Title
          Brief
          Budget
          Allocated
          Deadline
          ResourceStatus
          projectID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deliverableResourceId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Client {
        id
        owner
        Name
        Address
        PrimaryContact
        PhoneNumber
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationClientsId
        updatedAt
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StartDate
      Deadline
      ProjectStatus
      Custom1
      Custom2
      Custom3
      createdAt
      _version
      _deleted
      _lastChangedAt
      projectClientId
      __typename
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      owner
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organisationProjectsId
      updatedAt
      Title
      Overview
      ClientBudget
      Budget
      Deliverables {
        items {
          id
          owner
          Title
          Brief
          Budget
          Allocated
          Deadline
          ResourceStatus
          projectID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          deliverableResourceId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Client {
        id
        owner
        Name
        Address
        PrimaryContact
        PhoneNumber
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organisationClientsId
        updatedAt
        Custom1
        Custom2
        Custom3
        createdAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StartDate
      Deadline
      ProjectStatus
      Custom1
      Custom2
      Custom3
      createdAt
      _version
      _deleted
      _lastChangedAt
      projectClientId
      __typename
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      owner
      FromName
      From {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ToName
      To {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      ToEmail
      Message
      Status
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationFromId
      invitationToId
      __typename
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      owner
      FromName
      From {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ToName
      To {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      ToEmail
      Message
      Status
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationFromId
      invitationToId
      __typename
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      owner
      FromName
      From {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ToName
      To {
        id
        UserID
        FirstName
        LastName
        Expertise
        Industry
        Portfolio
        PhoneNumber
        TaxID
        AccountType
        Organisation {
          id
          owner
          Name
          Address
          PhoneNumber
          Email
          Website
          TaxID
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SubscriptionStatus
        EmailVerified
        Email
        FirstTime
        ProfilePicture
        Custom1
        Custom2
        Custom3
        Attachments {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        organisationUsersId
        __typename
      }
      ToEmail
      Message
      Status
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      invitationFromId
      invitationToId
      __typename
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      owner
      Name
      Address
      PrimaryContact
      PhoneNumber
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organisationClientsId
      updatedAt
      Custom1
      Custom2
      Custom3
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      owner
      Name
      Address
      PrimaryContact
      PhoneNumber
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organisationClientsId
      updatedAt
      Custom1
      Custom2
      Custom3
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      owner
      Name
      Address
      PrimaryContact
      PhoneNumber
      Organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organisationClientsId
      updatedAt
      Custom1
      Custom2
      Custom3
      createdAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createContract = /* GraphQL */ `
  mutation CreateContract(
    $input: CreateContractInput!
    $condition: ModelContractConditionInput
  ) {
    createContract(input: $input, condition: $condition) {
      id
      owner
      Status
      Rate
      Files {
        items {
          id
          File
          Size
          Type
          Description
          Custom1
          Custom2
          Custom3
          contractID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      SentDate
      AcceptDate
      StartDate
      ExpiryDate
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateContract = /* GraphQL */ `
  mutation UpdateContract(
    $input: UpdateContractInput!
    $condition: ModelContractConditionInput
  ) {
    updateContract(input: $input, condition: $condition) {
      id
      owner
      Status
      Rate
      Files {
        items {
          id
          File
          Size
          Type
          Description
          Custom1
          Custom2
          Custom3
          contractID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      SentDate
      AcceptDate
      StartDate
      ExpiryDate
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteContract = /* GraphQL */ `
  mutation DeleteContract(
    $input: DeleteContractInput!
    $condition: ModelContractConditionInput
  ) {
    deleteContract(input: $input, condition: $condition) {
      id
      owner
      Status
      Rate
      Files {
        items {
          id
          File
          Size
          Type
          Description
          Custom1
          Custom2
          Custom3
          contractID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      SentDate
      AcceptDate
      StartDate
      ExpiryDate
      Custom1
      Custom2
      Custom3
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $input: CreateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    createOrganisation(input: $input, condition: $condition) {
      id
      owner
      Name
      Address
      PhoneNumber
      Email
      Website
      TaxID
      Users {
        items {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          owner
          organisationProjectsId
          updatedAt
          Title
          Overview
          ClientBudget
          Budget
          StartDate
          Deadline
          ProjectStatus
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          projectClientId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          owner
          Name
          Address
          PrimaryContact
          PhoneNumber
          organisationClientsId
          updatedAt
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      OAuthLinks {
        items {
          id
          owner
          linkState
          AppName
          AppID
          Authorisation
          Token
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationOAuthLinksId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Permissions {
        items {
          id
          owner
          Permission
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationPermissionsId
          orgPermissionUserId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateOrganisation = /* GraphQL */ `
  mutation UpdateOrganisation(
    $input: UpdateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    updateOrganisation(input: $input, condition: $condition) {
      id
      owner
      Name
      Address
      PhoneNumber
      Email
      Website
      TaxID
      Users {
        items {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          owner
          organisationProjectsId
          updatedAt
          Title
          Overview
          ClientBudget
          Budget
          StartDate
          Deadline
          ProjectStatus
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          projectClientId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          owner
          Name
          Address
          PrimaryContact
          PhoneNumber
          organisationClientsId
          updatedAt
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      OAuthLinks {
        items {
          id
          owner
          linkState
          AppName
          AppID
          Authorisation
          Token
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationOAuthLinksId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Permissions {
        items {
          id
          owner
          Permission
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationPermissionsId
          orgPermissionUserId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteOrganisation = /* GraphQL */ `
  mutation DeleteOrganisation(
    $input: DeleteOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    deleteOrganisation(input: $input, condition: $condition) {
      id
      owner
      Name
      Address
      PhoneNumber
      Email
      Website
      TaxID
      Users {
        items {
          id
          UserID
          FirstName
          LastName
          Expertise
          Industry
          Portfolio
          PhoneNumber
          TaxID
          AccountType
          SubscriptionStatus
          EmailVerified
          Email
          FirstTime
          ProfilePicture
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationUsersId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          owner
          organisationProjectsId
          updatedAt
          Title
          Overview
          ClientBudget
          Budget
          StartDate
          Deadline
          ProjectStatus
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          projectClientId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          owner
          Name
          Address
          PrimaryContact
          PhoneNumber
          organisationClientsId
          updatedAt
          Custom1
          Custom2
          Custom3
          createdAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Custom1
      Custom2
      Custom3
      OAuthLinks {
        items {
          id
          owner
          linkState
          AppName
          AppID
          Authorisation
          Token
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationOAuthLinksId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Permissions {
        items {
          id
          owner
          Permission
          Custom1
          Custom2
          Custom3
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          organisationPermissionsId
          orgPermissionUserId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createOAuthLink = /* GraphQL */ `
  mutation CreateOAuthLink(
    $input: CreateOAuthLinkInput!
    $condition: ModelOAuthLinkConditionInput
  ) {
    createOAuthLink(input: $input, condition: $condition) {
      id
      owner
      linkState
      AppName
      AppID
      Authorisation
      Token
      Custom1
      Custom2
      Custom3
      organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationOAuthLinksId
      __typename
    }
  }
`;
export const updateOAuthLink = /* GraphQL */ `
  mutation UpdateOAuthLink(
    $input: UpdateOAuthLinkInput!
    $condition: ModelOAuthLinkConditionInput
  ) {
    updateOAuthLink(input: $input, condition: $condition) {
      id
      owner
      linkState
      AppName
      AppID
      Authorisation
      Token
      Custom1
      Custom2
      Custom3
      organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationOAuthLinksId
      __typename
    }
  }
`;
export const deleteOAuthLink = /* GraphQL */ `
  mutation DeleteOAuthLink(
    $input: DeleteOAuthLinkInput!
    $condition: ModelOAuthLinkConditionInput
  ) {
    deleteOAuthLink(input: $input, condition: $condition) {
      id
      owner
      linkState
      AppName
      AppID
      Authorisation
      Token
      Custom1
      Custom2
      Custom3
      organisation {
        id
        owner
        Name
        Address
        PhoneNumber
        Email
        Website
        TaxID
        Users {
          nextToken
          startedAt
          __typename
        }
        Projects {
          nextToken
          startedAt
          __typename
        }
        Clients {
          nextToken
          startedAt
          __typename
        }
        Custom1
        Custom2
        Custom3
        OAuthLinks {
          nextToken
          startedAt
          __typename
        }
        Permissions {
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      organisationOAuthLinksId
      __typename
    }
  }
`;

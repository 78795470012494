import * as React from "react";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "../components/Copyright";
import TextInput from "../components/TextInput";
import logo from "../logo1.png";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const [errorPassword, setErrorPassword] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState("");
  const navigate = useNavigate();

  // make sure there is email in the url otherwise consider to be invalid and navigate to home page
  const queryParams = new URLSearchParams(window.location.search);
  const username = queryParams.get("email");
  if (!/\S+@\S+\.\S+/.test(username)) {
    navigate("/");
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      password: data.get("password"),
    });

    // validate form
    if (data.get("password").length === 0 && data.get("code").length === 0) {
      setErrorPassword(true);
      setErrorCode(true);
      setErrorMsg("Please enter new password and verification code");
      return;
    }
    if (data.get("password").length === 0) {
      setErrorPassword(true);
      setErrorMsg("New password can't be empty.");
      return;
    }
    if (data.get("code").length === 0) {
      setErrorCode(true);
      setErrorMsg("Verification code can't be empty");
      return;
    }

    
    Auth.forgotPasswordSubmit(
      username,
      data.get("code").trim(),
      data.get("password")
    )
      .then((data) => {
        console.log(data);
        setErrorMsg("");
        setErrorPassword(false);
        setErrorCode(false);
        setSuccessMsg("Password has been reset.");
        navigate("/signin");
      })
      .catch((err) => {
        // console.log(err);
        const desc = err.toString().split(":")[1];
        setErrorMsg(desc);
      });
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        p: 1,
        caretColor: "transparent",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ m: 2 }}>
              <img width="100px" src={logo} alt="Logo" />
            </Box>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <Typography sx={{ m: 2 }}>
              Please input verification code sent to you in email and new
              password.
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextInput
                margin="normal"
                required
                fullWidth
                error={errorPassword}
                name="password"
                label="New Password"
                type="password"
                id="password"
                autoComplete="new-password"
                onChange={() => setErrorPassword(false)}
              />
              <TextInput
                margin="normal"
                required
                fullWidth
                error={errorCode}
                name="code"
                label="Verification Code"
                id="code"
                onChange={() => setErrorCode(false)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
              <Box>
                {errorMsg && errorMsg.length > 0 && (
                  <Typography sx={{ mt: 2, mb: 3, color: "red" }}>
                    {errorMsg}
                  </Typography>
                )}
              </Box>
              <Box>
                {successMsg && successMsg.length > 0 && (
                  <Typography sx={{ mt: 2, mb: 3 }}>{successMsg}</Typography>
                )}
              </Box>
            </Box>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot username?
                </Link>
              </Grid>
              <Grid item xs>
                <Link href="/" variant="body2">
                  Click here to login
                </Link>
              </Grid>
            </Grid>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

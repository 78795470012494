import * as React from "react";
import { useNavigate, createSearchParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "../components/Copyright";
import TextInput from "../components/TextInput";
import logo from "../logo1.png";
import { Auth } from "aws-amplify";

export default function ForgotPassword() {
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [successMsg, setSuccessMsg] = React.useState("");
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get("email"),
    // });

    const email = data.get("email").trim(); // in case user inputs whitespace at the start and/or end
    // validate trimmed email address
    if (!/\S+@\S+\.\S+/.test(email)) {
      setErrorEmail(true);
      setErrorMsg("not a valid email.");
      return;
    }

    const mailSentMsg = `The link to reset password has been sent to ${email}. Please follow the instructions in the email. If you don't receive the email, please check if the email address is correct`;
    Auth.forgotPassword(email)
      .then((data) => {
        console.log(data);
        setErrorMsg("");
        setErrorEmail(false);
        setButtonDisabled(true);
        setSuccessMsg(mailSentMsg);

        navigate({
          pathname: "/resetpassword",
          search: `?${createSearchParams({ email: email })}`,
        }); // jump to reset password page, carry email over
      })
      .catch((err) => {
        const errString = err.toString();
        if (errString.split(":")[0] === "UserNotFoundException") {
          // do not report user not found, treat it as success, in case bad people scan user pool
          setErrorMsg("");
          setErrorEmail(false);
          setButtonDisabled(true);
          setSuccessMsg(mailSentMsg);
        }
      });
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        p: 1,
        caretColor: "transparent",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ m: 2 }}>
              <img width="100px" src={logo} alt="Logo" />
            </Box>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <Typography sx={{ m: 2 }}>
              We'll send you instructions to reset your password via email if
              you have an account with us.
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextInput
                margin="normal"
                required
                fullWidth
                error={errorEmail}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={() => {
                  setErrorEmail(false);
                  setErrorMsg("");
                  setSuccessMsg("");
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={buttonDisabled}
              >
                Check Account
              </Button>
              <Box>
                {errorMsg && errorMsg.length > 0 && (
                  <Typography sx={{ mt: 2, mb: 3, color: "red" }}>
                    {errorMsg}
                  </Typography>
                )}
              </Box>
              <Box>
                {successMsg && successMsg.length > 0 && (
                  <Typography sx={{ mt: 2, mb: 3 }}>{successMsg}</Typography>
                )}
              </Box>
            </Box>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot username?
                </Link>
              </Grid>
            </Grid> */}
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

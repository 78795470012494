import { ROUTES } from './routes';
import LayersIcon from '@mui/icons-material/Layers';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SettingsIcon from '@mui/icons-material/Settings'
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GroupsIcon from '@mui/icons-material/Groups';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { MENU_ACCESS } from './constants';

export const DRAWER_LIST = [
  {
    route: ROUTES.dashboard,
    literal: 'Dashboard',
    Icon: DashboardIcon,
    access: MENU_ACCESS.AGENCY_AND_TALENT,
  },
  {
    route: ROUTES.clients,
    literal: 'Clients',
    Icon: GroupsIcon,
    access: MENU_ACCESS.AGENCY,
    className: 'introtour-step-agencies',
  },
  {
    route: ROUTES.agencies,
    literal: 'Businesses',
    Icon: GroupsIcon,
    access: MENU_ACCESS.TALENT,
    className: 'introtour-step-clients',
  },
  {
    route: ROUTES.projects,
    literal: 'Projects',
    Icon: FolderCopyIcon,
    access: MENU_ACCESS.AGENCY_AND_TALENT,
    className: 'introtour-step-projects',
  },
  {
    route: ROUTES.talent,
    literal: 'Talent',
    Icon: PeopleIcon,
    access: MENU_ACCESS.AGENCY,
    className: 'introtour-step-talents',
  },
  {
    route: ROUTES.invitations,
    literal: 'Invitations',
    Icon: PeopleIcon,
    access: MENU_ACCESS.TALENT
  },
  {
    route: ROUTES.proposals,
    literal: 'Proposals',
    Icon: LayersIcon,
    access: MENU_ACCESS.AGENCY_AND_TALENT,
    className: 'introtour-step-proposals',
  },
  {
    route: ROUTES.invoices,
    literal: 'Invoices',
    Icon: AttachMoneyIcon,
    access: MENU_ACCESS.AGENCY_AND_TALENT,
    className: 'introtour-step-invoices',
  },
  {
    route: ROUTES.budget,
    literal: 'Budget',
    Icon: StackedLineChartIcon,
    access: MENU_ACCESS.AGENCY,
    className: 'introtour-step-budgets',
  },
  // {
  //   route: ROUTES.settings,
  //   literal: 'Settings',
  //   Icon: SettingsIcon,
  //   access: MENU_ACCESS.AGENCY_AND_TALENT
  // }
];

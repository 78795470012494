import React, { useEffect, useState, useContext, createContext } from "react";
import { API, graphqlOperation, Hub, Auth } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { graphqlGetAllItems } from "../graphql/functions";

const AuthContext = createContext();

function useAuth() {
  const [authChecked, setAuthChecked] = useState(false);
  const [ready, setReady] = useState(false);
  const [authed, setAuthed] = useState(false);
  const [user, setUser] = useState(null);
  const [userIDIAM, setUserIDIAM] = useState();
  const [userID, setUserID] = useState();
  const [userOrgID, setUserOrgID] = useState(null); // plesae do not remove null, see explanation where setUserOrgID() is called
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [accountTypeAgency, setAccountTypeAgency] = useState(false);
  const [accountTypeTalent, setAccountTypeTalent] = useState(false);

  const setAccountType = (type) => {
    if (type === "AGENCY") {
      setAccountTypeAgency(true);
      setAccountTypeTalent(false);
    } else if (type === "TALENT") {
      setAccountTypeTalent(true);
      setAccountTypeAgency(false);
    }
  };

  useEffect(() => {
    login();

    Hub.listen("auth", ({ payload }) => {
      // console.log("Hub.listen", payload);
    });
  }, []);

  /*
    Check if this is the first time user is logging in. If so then create a new entry in the User table
  */
  const firstTimeUserCheck = async (user) => {
    // Check if the user has been added to the User table
    // console.log(user);
    var existingUser = await graphqlGetAllItems(queries.listUsers, {
      filter: { Email: { eq: user["attributes"]["email"] } },
    });
    const val = existingUser;
    if (val) {
      if (val.length === 0) {
        // Add the user to the User table
        const newUser = {
          FirstName: user["attributes"]["given_name"],
          LastName: user["attributes"]["family_name"],
          PhoneNumber: user["attributes"]["phone_number"],
          Email: user["attributes"]["email"],
          EmailVerified: user["attributes"]["email_verified"],
          AccountType: user["attributes"]["custom:AccountType"],
          UserID: user["attributes"]["sub"],
          FirstTime: true,
        };

        const exec = await API.graphql({
          query: mutations.createUser,
          variables: { input: newUser },
        });
        if (exec.data.createUser.AccountType === "AGENCY") {
          setUserOrgID(await newAgencyToOrg(exec.data.createUser));
        }
        // console.log(exec);
        setUserIDIAM(user["attributes"]["sub"]);
        setUserID(exec.data.createUser["id"]);
        setFirstName(exec.data.createUser["FirstName"]);
        setAccountType(exec.data.createUser["AccountType"]);
        setEmail(exec.data.createUser["Email"]);
        setReady(true);
      } else {
        // TODO unset FirstTime

        // Get the user from the User table
        // console.log(val.data.listUsers);
        setUserIDIAM(val[0]["UserID"]); // setUserIDIAM(user['attributes']['sub']);
        setUserID(val[0]["id"]);
        setFirstName(val[0]["FirstName"]);
        setAccountType(val[0]["AccountType"]);
        if (val[0]["AccountType"] === "AGENCY") {
          // Talent shouldn't have an Org
          if (val[0]["Organisation"]) {
            setUserOrgID(val[0]["Organisation"]["id"]); // use org if already in one
          } else {
            const orgID = await newAgencyToOrg(val[0]); // create a new org
            if (orgID) {
              setUserOrgID(orgID);
            } else {
              setUserOrgID(null); // why set it to null, see explanation below
            }
          }
        } else {
          /* keep this unless you know what you're doing
           * if not set userOrgID, its value is undefined, when used in graphql filter like:
           * { filter: { organisationProjectsId: { eq: userOrgID } } }
           * it matches all records, not sure it's a bug or feature of Amplify Graphql API
           * fix like this first. by Chris Yin 29/Apr/2023
           */
          setUserOrgID(null);
        }

        setEmail(val[0]["Email"]);
        setReady(true);
      }
    }
  };

  const newAgencyToOrg = async (user) => {
    // the process of add a user to an org is not defined now, create an org for new agency user for the moment
    const createOrg = await API.graphql({
      query: mutations.createOrganisation,
      variables: {
        input: {
          owner: user["id"],
          Name: `${user["FirstName"]} ${user["LastName"]}'s Agency`,
          Email: user["Email"],
        },
      },
    });
    const addUserToOrg = await API.graphql({
      query: mutations.updateUser,
      variables: {
        input: {
          id: user["id"],
          _version: user["_version"],
          organisationUsersId: createOrg.data.createOrganisation["id"],
        },
      },
    });

    return addUserToOrg.data.updateUser["Organisation"]["id"];
  };

  const login = () => {
    setAuthChecked(false);
    return new Promise((res) => {
      const user = Auth.currentAuthenticatedUser();
      user
        .then((val) => {
          firstTimeUserCheck(val);
          setUser(val);
          setAuthed(true);
          setAuthChecked(true);
          res();
        })
        .catch((err) => {
          // console.log(err);
          setAuthChecked(true);
        });
    });
  };

  const logout = () => {
    setAuthed(false);
    return new Promise((res) => {
      setUser(null);
      setAuthed(false);
      res();
    });
  };

  return {
    ready,
    authed,
    authChecked,
    user,
    userID,
    userOrgID,
    userIDIAM,
    email,
    firstName,
    accountTypeAgency,
    accountTypeTalent,
    login,
    logout,
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export default function AuthConsumer() {
  return useContext(AuthContext);
}

import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { useFilePicker } from "use-file-picker";
import { getUser } from "../graphql/queries";
import { S3Link } from "./S3Link";
import { Avatar, CircularProgress, Container, Stack } from "@mui/material";
import { green } from "@mui/material/colors";

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

export function UserProfilePicture({ userID, version, readOnly, headerView }) {
  const [loading, setLoading] = React.useState(true);
  const [fileLink, setfileLink] = useState("");
  const [fileURL, setFileURL] = useState("");
  const [fileUploaded, setfileUploaded] = useState(false);
  const [
    openFileSelector,
    { filesContent, uploading, errors, plainFiles, clear },
  ] = useFilePicker({
    // multiple: true,
    readAs: "ArrayBuffer", // available formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: [".jpg"], // accepted file extensions
    limitFilesConfig: { min: 1, max: 1 }, // number of files
    // minFileSize: 1, // in megabytes
    maxFileSize: 5, // in megabytes
    // readFilesContent: false, // ignores file content
  });

  useEffect(() => {
    if (userID) {
      loadExistingAttachment();
    }
  }, [userID]);

  const loadExistingAttachment = () => {
    const exec = API.graphql({ query: getUser, variables: { id: userID } });
    exec.then(async (x) => {
      const profilePic = x.data.getUser.ProfilePicture;
      if (profilePic) {
        setfileUploaded(true);
        setfileLink(profilePic);
        const result = await Storage.get(profilePic);
        // console.log(result);
        setFileURL(result);
      }
      setLoading(false);
    });
  };

  const uploadFile = () => {
    console.log("Uploading...");
    console.log(filesContent[0]);
    const blob = new Blob([filesContent[0].content]); //, { type: "application/octet-stream;charset=utf-8" });
    // saveFile(blob, filesContent[0].name);
    // return;
    const filename = `public/Users/${userID}/${Date.now()}_Profile_Picture.jpg`;
    const upload = Storage.put(filename, blob, {
      resumable: true,
      completeCallback: (event) => {
        console.log(`Successfully uploaded ${event.key}`);
        let eventKey = filename;
        console.log(eventKey);
        updatePicture(eventKey);
      },
      progressCallback: (progress) => {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
      errorCallback: (err) => {
        console.error("Unexpected error while uploading", err);
      },
    });
  };

  const updatePicture = (fileKey) => {
    const userData = {
      id: userID,
      _version: version,
      ProfilePicture: fileKey,
    };
    console.log(userData);
    const exec = API.graphql({
      query: mutations.updateUser,
      variables: { input: userData },
    });
    exec
      .then((x) => {
        console.log(x);
        setfileLink(fileKey);
        setfileUploaded(true);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const deletePicture = () => {
    clear();
    const userData = {
      id: userID,
      _version: version,
      ProfilePicture: "",
    };

    const exec = API.graphql({
      query: mutations.updateUser,
      variables: { input: userData },
    });
    exec
      .then((x) => {
        console.log(x);
        setfileLink("");
        setfileUploaded(false);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  //   const getURL = async () => {
  //     const result = await Storage.get(fileLink);
  //     setFileURL(result);
  //   };

  // Show only picture in view only mode, eg in Talents page
  if (headerView) {
    return (
      <Avatar sx={{ width: 32, height: 32, m: 1 }} alt="Profile Picture">
        {fileURL ? (
          <img src={fileURL} width={32} height={32} />
        ) : (
          <img
            src={process.env.PUBLIC_URL + "user-profile-default-image.png"}
            width={32}
            height={32}
          />
        )}
      </Avatar>
    );
  }

  // Show only picture in view only mode, eg in Talents page
  if (readOnly) {
    return (
      <Avatar sx={{ width: 96, height: 96, m: 1 }} alt="Profile Picture">
        {fileURL ? (
          <img src={fileURL} width={96} height={96} />
        ) : (
          <img
            src={process.env.PUBLIC_URL + "user-profile-default-image.png"}
            width={96}
            height={96}
          />
        )}
      </Avatar>
    );
  }

  // Show all functions, eg in User Profile page
  return (
    <Box sx={{ border: 1, mb: 1, borderColor: "darkgrey", borderRadius: 1 }}>
      <Div>
        <Stack direction="row" alignItems="center">
          {fileUploaded ? (
            <>
              <Avatar
                sx={{ width: 96, height: 96, m: 1 }}
                alt="Profile Picture"
              >
                {fileURL ? (
                  <img src={fileURL} width={96} height={96} />
                ) : (
                  <img
                    src={
                      process.env.PUBLIC_URL + "user-profile-default-image.png"
                    }
                    width={96}
                    height={96}
                  />
                )}
              </Avatar>
            </>
          ) : (
            // <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
            <>
              <ErrorIcon sx={{ color: "red", mr: 1 }} />
              Upload your profile picture
            </>
          )}
        </Stack>
        {fileLink && !readOnly && (
          <Stack direction="row">
            <IconButton
              color="error"
              sx={{ border: 1, m: 1 }}
              onClick={() => {
                deletePicture();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        )}
      </Div>
      {!loading ? (
        !fileLink && (
          <>
            <Div>
              {plainFiles.map((file) => (
                <Div key={file.name}>{file.name}</Div>
              ))}
              <>
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => openFileSelector()}
                >
                  Select file
                </Button>
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => clear()}
                >
                  Clear
                </Button>
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => uploadFile()}
                >
                  Upload
                </Button>
              </>
            </Div>
            <Div style={{ color: "red" }}>
              {errors.length > 0 && "Errors: "}
              {errors.length > 0 &&
                errors[0].fileSizeTooSmall &&
                "File size is too small!"}
              {errors.length > 0 &&
                errors[0].fileSizeToolarge &&
                "File size is too large!"}
              {errors.length > 0 &&
                errors[0].readerError &&
                "Problem occured while reading file!"}
              {errors.length > 0 &&
                errors[0].maxLimitExceeded &&
                "Too many files"}
              {errors.length > 0 &&
                errors[0].minLimitNotReached &&
                "Not enough files"}
            </Div>
          </>
        )
      ) : (
        <CircularProgress
          size={72}
          sx={{
            color: green[500],
          }}
        />
      )}
    </Box>
  );
}

export const ATTACHMENT_TYPE_DRIVERSLICENSE = "Driver's License";
export const ATTACHMENT_TYPE_WORKERSCOMP = "Worker's Compensation Insurance";
export const ATTACHMENT_TYPE_PUBLICINDEMNITY = "Public Indemnity Insurance";
export const ATTACHMENT_TYPE_SERVICECONTRACT = "Service Contract";

export const TASK_INCOMPLETE = 0;
export const TASK_COMPLETE = 1;
export const TASK_WARNING = 2;
export const TASK_ERROR = 3;

export const FormMode = {
  New: 0,
  Edit: 1,
  View: 2
}

export const XeroIntegration = {
  AppName: "Xero",
  clientID: "B6380C3382384F1A9F86366E97582ED5",
  redirectUri: "https://app.coflowx.com/xerolink",
  scope: "openid offline_access profile email accounting.transactions accounting.contacts projects files",
}
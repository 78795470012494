import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Title from "../components/Title";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Input,
  InputBase,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import useAuth from "../components/AuthContextProvider";
import { ResourceStatus } from "../components/ResourceStatus";
import { Container } from "@mui/system";
import { CSVExporter } from "../components/CSVExporter";
import {
  listProjectsByOrg,
  listProjectsForTalent,
  searchProjectsWithDeliverables,
} from "../graphql/customQueries";
import TextInput from "../components/TextInput";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.head} .MuiTableSortLabel-root:hover`]: {
    bgcolor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.head} .Mui-active`]: {
    bgcolor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.head} .Mui-active .MuiTableSortLabel-icon`]: {
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.black,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function Row(props) {
  const { prj, userID, accountTypeAgency, accountTypeTalent } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  var deliverables;

  if (accountTypeAgency)
    deliverables = prj.Deliverables.items.filter((x) => !x._deleted);
  else if (accountTypeTalent)
    deliverables = prj.Deliverables.items.filter(
      (x) => !x._deleted && x.deliverableResourceId == userID
    );

  const handleProjectClick = (event, item) => {
    // console.log(item);
    navigate(`/project/${item}/general`);
  };

  const handleDeliverableClick = (event, item) => {
    // console.log(item);
    navigate(`/deliverable/${item}`);
  };

  return (
    <React.Fragment>
      <StyledTableRow
        hover
        key={prj.id}
        onDoubleClick={(evt) => handleProjectClick(evt, prj.id)}
      >
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        {accountTypeAgency ? (
          <StyledTableCell>{prj.Client && prj.Client.Name}</StyledTableCell>
        ) : (
          <StyledTableCell>{prj.Organisation.Name}</StyledTableCell>
        )}
        <StyledTableCell>
          {prj.Title} ({deliverables.length})
        </StyledTableCell>
        {accountTypeAgency && (
          <StyledTableCell>
            {new Date(prj.StartDate).toLocaleDateString("en-AU")}
          </StyledTableCell>
        )}
        {accountTypeAgency ? (
          <StyledTableCell>
            {new Date(prj.Deadline).toLocaleDateString("en-AU")}
          </StyledTableCell>
        ) : (
          <StyledTableCell></StyledTableCell>
        )}
        {accountTypeAgency ? (
          <StyledTableCell>${prj.Budget}</StyledTableCell>
        ) : (
          <StyledTableCell></StyledTableCell>
        )}
        {accountTypeAgency && <StyledTableCell></StyledTableCell>}
        {accountTypeAgency && <StyledTableCell></StyledTableCell>}
      </StyledTableRow>

      {open && (
        <>
          {deliverables.length > 0 ? (
            deliverables.map((del) => {
              if (
                (accountTypeTalent && del.deliverableResourceId == userID) ||
                accountTypeAgency
              ) {
                return (
                  <StyledTableRow
                    hover
                    key={del.id}
                    onDoubleClick={(evt) => handleDeliverableClick(evt, del.id)}
                  >
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>{del.Title}</StyledTableCell>
                    {accountTypeAgency && <StyledTableCell></StyledTableCell>}
                    <StyledTableCell>{del.Deadline}</StyledTableCell>
                    <StyledTableCell>${del.Budget}</StyledTableCell>
                    {accountTypeAgency && (
                      <StyledTableCell>{del.Allocated}%</StyledTableCell>
                    )}
                    {accountTypeAgency && (
                      <StyledTableCell>
                        {del.Resource == null ? (
                          <ResourceStatus name="" status={del.ResourceStatus} />
                        ) : (
                          <ResourceStatus
                            name={
                              del.Resource.FirstName +
                              " " +
                              del.Resource.LastName
                            }
                            status={del.ResourceStatus}
                          />
                        )}
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                );
              }
            })
          ) : (
            <StyledTableRow hover>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell>
                {accountTypeAgency && (
                  <>
                    There are no deliverables, click here to add
                    <Button
                      variant="contained"
                      sx={{ ml: 1 }}
                      onClick={() => navigate(`/deliverable/add/${prj.id}`)}
                    >
                      Add
                    </Button>
                  </>
                )}
                {accountTypeTalent && <>There are no deliverables</>}
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          )}
        </>
      )}
    </React.Fragment>
  );
}

function ProjectsContent() {
  const ASCENDING = 1;
  const DESCENDING = -1;

  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [currentToken, setCurrentToken] = useState(); // pagination follows https://dev.to/onlybakam/implementing-pagination-with-aws-appsync-5d08
  const [nextToken, setNextToken] = useState();
  const [tokens, setTokens] = useState([]);
  const [fetchMode, setFetchMode] = useState("FETCH"); // when switch between search and list, need to reset pages
  const [projects, setProjects] = useState([]);
  const [searchString, setSearchString] = useState("");
  const { ready, userID, userOrgID, accountTypeAgency, accountTypeTalent } =
    useAuth();
  const [sortingColumn, setSortingColumn] = useState("createdAt"); // newly created project at the top
  const [sortingOrder, setSortingOrder] = useState(DESCENDING);
  const navigate = useNavigate();

  useEffect(() => {
    if (!ready) {
      return;
    }
    if (!searchString) {
      fetchProjects();
    } else {
      // talent user use frontend search
      if (accountTypeTalent) {
        return;
      }

      // only fire search after user stop typing
      const timer = setTimeout(() => {
        searchProjects();
      }, 400);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [ready, currentToken, rowsPerPage, searchString]);

  const fetchProjects = () => {
    setLoading(true);
    if (fetchMode !== "FETCH") {
      resetPage();
      setFetchMode("FETCH");
    }
    if (accountTypeAgency) {
      const result = API.graphql({
        query: listProjectsByOrg,
        variables: {
          organisationProjectsId: userOrgID,
          sortDirection: "DESC",
          limit: rowsPerPage,
          nextToken: currentToken,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      result
        .then((val) => {
          var filtered = val.data.listProjectsByOrg.items.filter(
            (x) => !x._deleted
          );
          setProjects(filtered);
          setNextToken(val.data.listProjectsByOrg.nextToken);
          setLoading(false);
        })
        .catch(({ name, message }) => {
          setLoading(false);
          if (message.includes("No current user")) {
            window.location.reload();
          } else {
            console.log("Could not load projects", name, message);
          }
          // alert("Could not load projects, check your internet connection")
        });
      // console.log(result.data.listProjects.items);
    } else if (accountTypeTalent) {
      const result = API.graphql({
        query: listProjectsForTalent,
        variables: {
          talentId: userID,
          limit: rowsPerPage,
          nextToken: currentToken,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      result
        .then((val) => {
          // show only projects where the deliverable's resource is the current user
          var filtered = val.data.listProjects.items.filter(
            (x) =>
              !x._deleted &&
              x.Deliverables.items.find(
                (y) => !y._deleted && y.deliverableResourceId == userID
              )
          );
          setProjects(filtered);
          setNextToken(val.data.listProjects.nextToken);
          setLoading(false);
        })
        .catch(({ name, message }) => {
          setLoading(false);
          if (message.includes("No current user")) {
            window.location.reload();
          } else {
            console.log("Could not load projects", name, message);
          }
          // alert("Could not load projects, check your internet connection")
        });
      // console.log(result.data.listProjects.items);
    }
  };

  const searchProjects = () => {
    if (!searchString) {
      return;
    }

    setLoading(true);
    if (fetchMode !== "SEARCH") {
      resetPage();
      setFetchMode("SEARCH");
    }
    const result = API.graphql({
      query: searchProjectsWithDeliverables,
      variables: {
        limit: rowsPerPage,
        nextToken: currentToken,
        filter: {
          organisationProjectsId: { match: userOrgID },
          or: [
            { Title: { wildcard: `*${searchString}*` } },
            { Overview: { wildcard: `*${searchString}*` } },
          ],
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var filtered = val.data.searchProjects.items.filter((x) => !x._deleted);
        setProjects(filtered);
        setNextToken(val.data.searchProjects.nextToken);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("Could not load projects", err);
        alert("Could not load invitations, check your internet connection");
      });
  };

  const handleChangePage = (event, newPage) => {
    if (newPage === page) {
      return;
    }
    setPage(newPage);
    if (page > newPage) {
      // backward
      setCurrentToken(tokens.pop());
      setTokens([...tokens]);
      setNextToken(null);
    } else {
      // forward
      setTokens((v) => [...v, currentToken]);
      setCurrentToken(nextToken);
      setNextToken(null);
    }
  };

  const resetPage = () => {
    setPage(0);
    setCurrentToken(null);
    setTokens([]);
    setNextToken(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resetPage();
  };

  const filtered =
    projects &&
    projects.filter((x) =>
      x.Title.toString().toLowerCase().includes(searchString.toLowerCase())
    );
  filtered.sort((a, b) => {
    if (a[sortingColumn] > b[sortingColumn]) {
      return 1 * sortingOrder;
    } else if (a.StartDate < b.StartDate) {
      return -1 * sortingOrder;
    } else {
      return 0;
    }
  });

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      <Box
        sx={{
          mb: 2,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>Projects</Title>
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.75)", fontSize: "0.9rem" }}
            >
              View the list of projects and their deliverables
            </Typography>
          </Stack>
          {accountTypeAgency && (
            <div>
              <Button
                data-amplify-analytics-on="click"
                data-amplify-analytics-name="Projects_AddProjectClick"
                variant="contained"
                sx={{ m: 1, textTransform: "none" }}
                onClick={() => {
                  navigate("/project/add");
                }}
              >
                Add Project
              </Button>
            </div>
          )}
          {/* <CSVExporter data={projects} /> */}
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  border: 1,
                  borderRadius: 1,
                  borderColor: "darkgrey",
                }}
              >
                <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <SearchIcon />
                </IconButton>
                <TextInput
                  sx={{
                    outline: "none",
                    width: "400px",
                    "& fieldset": { border: "none" },
                  }}
                  value={searchString}
                  onChange={(val) => setSearchString(val.target.value)}
                  placeholder="Search project"
                />
              </Box>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={-1} //{accountTypeAgency ? -1 : count} // set to -1 to use server side pagination, see MUI doc
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{ disabled: !nextToken }}
              ></TablePagination>
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell></StyledTableCell>
                    {accountTypeAgency ? (
                      <StyledTableCell>Client</StyledTableCell>
                    ) : (
                      <StyledTableCell>Agency</StyledTableCell>
                    )}
                    {accountTypeAgency ? (
                      <StyledTableCell>Title</StyledTableCell>
                    ) : (
                      <StyledTableCell>Project Name</StyledTableCell>
                    )}
                    {accountTypeAgency && (
                      <StyledTableCell>
                        <TableSortLabel
                          active={sortingColumn === "StartDate"}
                          direction={
                            sortingOrder === ASCENDING ? "asc" : "desc"
                          }
                          onClick={(e) => {
                            setSortingColumn("StartDate");
                            setSortingOrder(-sortingOrder);
                          }}
                        >
                          StartDate
                        </TableSortLabel>
                      </StyledTableCell>
                    )}
                    <StyledTableCell>
                      <TableSortLabel
                        active={sortingColumn === "Deadline"}
                        direction={sortingOrder === ASCENDING ? "asc" : "desc"}
                        onClick={(e) => {
                          setSortingColumn("Deadline");
                          setSortingOrder(-sortingOrder);
                        }}
                      >
                        Deadline
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell>Budget</StyledTableCell>
                    {accountTypeAgency && (
                      <StyledTableCell>Allocated</StyledTableCell>
                    )}
                    {accountTypeAgency && (
                      <StyledTableCell>Talent</StyledTableCell>
                    )}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {accountTypeAgency &&
                    (projects.length > 0 ? (
                      projects.map((prj) => {
                        return (
                          <Row
                            key={prj.id}
                            prj={prj}
                            userID={userID}
                            accountTypeAgency={accountTypeAgency}
                            accountTypeTalent={accountTypeTalent}
                          />
                        );
                      })
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>
                          No projects to display
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </StyledTableRow>
                    ))}
                  {accountTypeTalent &&
                    (filtered.length > 0 ? (
                      filtered.map((prj) => {
                        return (
                          <Row
                            key={prj.id}
                            prj={prj}
                            userID={userID}
                            accountTypeAgency={accountTypeAgency}
                            accountTypeTalent={accountTypeTalent}
                          />
                        );
                      })
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell>
                          No projects to display
                        </StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                        <StyledTableCell></StyledTableCell>
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default function Projects() {
  return <ProjectsContent />;
}

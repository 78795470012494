import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { a, API, graphqlOperation, Storage } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import TextInput from "./TextInput";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid grey",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  caretColor: "transparent",
};

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

export function ClientAdd({
  userID,
  userOrgID,
  open,
  handleClose,
  onClientAdded,
}) {
  const [formName, setformName] = useState("");
  const [formNameHelper, setFormNameHelper] = useState("");
  const [formAddress, setformAddress] = useState("");
  const [formAddressHelper, setFormAddressHelper] = useState("");
  const [formPrimaryContact, setformPrimaryContact] = useState("");
  const [formContactHelper, setFormContactHelper] = useState("");
  const [formPhoneNumber, setformPhoneNumber] = useState("");
  const [formPhoneHelper, setFormPhoneHelper] = useState("");

  useEffect(() => {}, []);

  /*
  addNewClient
  */
  const addNewClient = () => {
    var valid = true;
    if (formName.trim() === "") {
      setformName("");
      setFormNameHelper("Name can't be empty");
      valid = false;
    }
    if (formAddress.trim() === "") {
      setformAddress("");
      setFormAddressHelper("Address can't be empty");
      valid = false;
    }
    if (formPrimaryContact.trim() === "") {
      setformPrimaryContact("");
      setFormContactHelper("Contact can't be empty");
      valid = false;
    }
    if (formPhoneNumber.trim() === "") {
      setformPhoneNumber("");
      setFormPhoneHelper("Phone Number can't be empty");
      valid = false;
    }

    if (!valid) {
      return;
    }

    const newClient = {
      owner: userID,
      Name: formName,
      Address: formAddress,
      PrimaryContact: formPrimaryContact,
      PhoneNumber: formPhoneNumber,
      organisationClientsId: userOrgID,
    };

    const exec = API.graphql({
      query: mutations.createClient,
      variables: { input: newClient },
    });
    exec
      .then((x) => {
        //console.log(x);
        if (onClientAdded) {
          onClientAdded(x.data.createClient);
        }
        handleClose();
      })
      .catch((x) => {
        console.log(x);
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              required
              id="Name"
              // name=""
              label="Name"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
              value={formName}
              helperText={formNameHelper}
              error={formNameHelper !== ""}
              onChange={(val) => {
                setformName(val.target.value);
                setFormNameHelper("");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              required
              id="Address"
              // name=""
              label="Address"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
              value={formAddress}
              helperText={formAddressHelper}
              error={formAddressHelper !== ""}
              onChange={(val) => {
                setformAddress(val.target.value);
                setFormAddressHelper("");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              required
              id="PrimaryContact"
              // name=""
              label="Primary Contact"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
              value={formPrimaryContact}
              helperText={formContactHelper}
              error={formContactHelper !== ""}
              onChange={(val) => {
                setformPrimaryContact(val.target.value);
                setFormContactHelper("");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              required
              id="PhoneNumber"
              // name=""
              label="Phone Number"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start"> </InputAdornment>
                ),
              }}
              value={formPhoneNumber}
              helperText={formPhoneHelper}
              error={formPhoneHelper !== ""}
              onChange={(val) => {
                setformPhoneNumber(val.target.value);
                setFormPhoneHelper("");
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              sx={{ width: 100, m: 1, p: 2 }}
              onClick={() => {
                addNewClient();
              }}
            >
              Add
            </Button>
            <Button
              variant="contained"
              sx={{ width: 100, m: 1, p: 2 }}
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
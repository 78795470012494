import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const MenuItem = ({
  route,
  literal,
  Icon,
  selected,
  onClick
}) => {
  return (
    <ListItem
      button
      selected={selected}
      sx={{
        "&.Mui-selected": {
          backgroundColor: "lightBlue",
          color: "black",
        },
        "&:hover": {
          backgroundColor: "primary.light",
          color: "black",
        },
      }}
      onClick={onClick}
    >
      <ListItemIcon
        sx={[
          { minWidth: "auto" },
          (theme) => ({
            paddingRight: theme.spacing(2),
          }),
        ]}
      >
        <Icon sx={{ color: "black" }} />
      </ListItemIcon>
      <ListItemText primary={literal} />
    </ListItem>
  );
};

export default MenuItem;

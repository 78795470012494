import * as React from 'react';
import { InputBase, TextField, Box } from '@mui/material';

function TextInput(props) {
  const [focused, setFocused] = React.useState(false);

  return (
    <Box
      sx={{ display: 'flex', caretColor: focused ? 'black' : 'transparent' }}
    >
      <TextField
        {...props}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </Box>
  );
}


export default TextInput;
import * as React from 'react';
import { useCallback, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../components/Title';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { API, graphqlOperation } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import useAuth from '../components/AuthContextProvider';
import { ProposalStatus } from '../components/ProposalStatus';
import { ProposalApproval } from '../components/ProposalApproval';
import { Button, Chip, CircularProgress, Grid, IconButton, Stack, TablePagination, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { green } from '@mui/material/colors';
import { listProjectProposals, listProjectProposalsForTalent } from '../graphql/customQueries';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as models from '../models';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function Row(props) {
  const { prj, userID, accountTypeAgency, accountTypeTalent, handleClick, fetchProposals } = props;
  const [open, setOpen] = React.useState(false);
  const deliverables = prj.Deliverables.items.filter((x) => !x._deleted);

  return (
    <React.Fragment>
      <StyledTableRow hover key={prj.id}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{prj.Title}</StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        {accountTypeAgency && <StyledTableCell></StyledTableCell>}
      </StyledTableRow>
      {open && <>
        {deliverables.length > 0 && deliverables.map((del) => {
          return del.Proposals.items.filter((x) => !x._deleted).map((pro) => {
            return (
              <StyledTableRow key={pro.id} onDoubleClick={(evt) => handleClick(evt, pro.id)}>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>{del.Title}</StyledTableCell>
                {accountTypeAgency && (pro.Talent ? <StyledTableCell>{pro.Talent.FirstName} {pro.Talent.LastName}</StyledTableCell> : <StyledTableCell><Chip label="Unassigned" color="primary" /></StyledTableCell>)}
                <StyledTableCell>${pro.Amount}</StyledTableCell>
                {/* <StyledTableCell>{pro.Scope}</StyledTableCell> */}
                <StyledTableCell>${pro.Approved}</StyledTableCell>
                <StyledTableCell><ProposalStatus status={pro.Status} /></StyledTableCell>
                <StyledTableCell><ProposalApproval pro={pro} fetchProposals={fetchProposals} /></StyledTableCell>
              </StyledTableRow>
            )
          })
        })}
      </>}
    </React.Fragment>
  );
}

function ProposalsContent() {
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const { ready, userID, accountTypeAgency, accountTypeTalent } = useAuth();

  useEffect(() => {
    if (ready) {
      fetchProposals();
    }
  }, [ready]);

  const fetchProposals = () => {
    setLoading(true);
    if (accountTypeAgency) {
      const result = API.graphql({
        query: listProjectProposals,
        variables: { filter: { owner: { eq: userID } } },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      result.then((val) => {
        var filtered = val.data.listProjects.items.filter((x) => !x._deleted);
        setProjects(filtered);
        getCount(filtered);
        setLoading(false);
      }).catch(({name, message}) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        }
        else {
          console.log("Could not load proposals", name, message);
        }
        // alert("Could not load proposals, check your internet connection")
      });
    }
    else if (accountTypeTalent) {
      const result = API.graphql({
        query: listProjectProposalsForTalent,
        variables: { talentId: userID },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      result.then((val) => {
        var filtered = val.data.listProjects.items.filter((x) => !x._deleted);
        setProjects(filtered);
        getCount(filtered);
        setLoading(false);
      })
      .catch(({ name, message }) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load proposals", name, message);
        }
        // alert("Could not load proposals, check your internet connection")
      });
  }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, item) => {
    // console.log(item);
    navigate(`/proposal/${item}`);
  }

  const getCount = (projs) => {
    var c = 0;
    projs.map((prj) => {
      prj.Deliverables.items.map((del) => {
        c += del.Proposals.items.filter((x) => !x._deleted).length;
      })
    });
    setCount(c);
  }

  const filtered = projects && projects.filter((prj) => {
    const deliverables = prj.Deliverables.items.filter((x) => !x._deleted);
    var count = 0;
    deliverables.length > 0 && deliverables.map((del) => {
      count += del.Proposals.items.filter((x) => !x._deleted).length;
    });
    return count > 0
  });

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: 'transparent',
        mb: 3
      }}
    >
      <Box sx={{ mb: 2, backgroundColor: '#ececec', borderBottom: '1px solid darkgrey' }}>
        <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', p: 2, pl: 3, pr: 3 }}>
          <Stack>
            <Title>Proposals</Title>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.75)', fontSize: '0.9rem' }}>
              {accountTypeAgency && 'View the proposals which have been requested'}
              {accountTypeTalent && 'View the proposals which have been sent to you'}
            </Typography>
          </Stack>
        </Box>
      </Box>
      {loading ?
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
        :
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>Project</StyledTableCell>
                    <StyledTableCell>Deliverable</StyledTableCell>
                    {accountTypeAgency && <StyledTableCell>Talent</StyledTableCell>}
                    <StyledTableCell>Amount</StyledTableCell>
                    <StyledTableCell>Approved</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    {accountTypeAgency && <StyledTableCell>Approval</StyledTableCell>}
                    {accountTypeTalent && <StyledTableCell>Submission</StyledTableCell>}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {filtered && filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prj) => {
                    return <Row key={prj.id} prj={prj} userID={userID} accountTypeAgency={accountTypeAgency} accountTypeTalent={accountTypeTalent} handleClick={handleClick} fetchProposals={fetchProposals} />
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>}
    </Box >
  );
}

export default function Proposals() {
  return <ProposalsContent />;
}
import * as React from 'react';
import Chip from '@mui/material/Chip';
import * as models from '../models';

export function ResourceStatus({ name, status }) {
  if (status == models.ResourceStatus.NOT_ASSIGNED) {
    return (
      <div>
        <Chip label="Unassigned" color="error" />
      </div>
    )
  }
  else if (status == models.ResourceStatus.IN_REVIEW) {
    return (
      <div>
        {name} <Chip label="In Review" color="primary" />
      </div>
    )
  }
  else if (status == models.ResourceStatus.ASSIGNED) {
    return (
      <div>
        {name} <Chip label="Assigned" color="success" />
      </div>
    )
  }

  return (
    <div>(err)</div>
  );
}

import { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import logo from "../logo1.png";
import useAuth from "../components/AuthContextProvider";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/system";
import { ROUTES } from "./routes";
import { Button } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import { UserProfilePicture } from "./UserProfilePicture";

const Header = () => {
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null);
  const {
    ready,
    user,
    userID,
    userOrgID,
    firstName,
    accountTypeAgency,
    accountTypeTalent,
    login,
  } = useAuth();
  const [organisationName, setOrganisationName] = useState("");
  const navigate = useNavigate();

  const openUserMenu = Boolean(anchorElUserMenu);
  const handleUserMenuClick = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setAnchorElUserMenu(null);
  };

  const fetchOrganisation = () => {
    const result = API.graphql({
      query: queries.getOrganisation,
      variables: { id: userOrgID },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        setOrganisationName(val.data.getOrganisation.Name);
      })
      .catch(({ name, message }) => {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load organisation", name, message);
        }
        // alert("Could not load organisation, check your internet connection")
      });
  };

  useEffect(() => {
    if (ready) {
      if (accountTypeAgency) fetchOrganisation();
    }
  }, [ready]);

  const signOut = async () => {
    try {
      await Auth.signOut();
      window.location.reload(); // reload page to reset all states; do not use navigate() here, otherwise old states could still in effect which cause bugs
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const getSalutation = () => {
    const d = new Date();
    const hours = d.getHours();
    if (hours >= 0 && hours < 12) {
      return "Good morning";
    } else if (hours >= 12 && hours < 18) {
      return "Good afternoon";
    } else if (hours >= 18) {
      return "Good evening";
    }
  };

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{
        backgroundColor: "white",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        caretColor: "transparent",
      }}
    >
      <Toolbar sx={{ flexWrap: "wrap" }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container item xs={4} direction="row" alignItems="center">
            <img style={{ width: "40px" }} src={logo} alt="Logo" />
            <Typography
              variant="h5"
              color="inherit"
              noWrap
              sx={{ ml: 1, fontSize: "1.2em", fontWeight: "fontWeightHeavy" }}
            >
              {/* <Link href="/dashboard" underline="none" color="inherit">
                CoFlowx
              </Link> */}
              CoFlowx
            </Typography>
          </Grid>
          <Grid item alignItems="center" justifyContent="space-evenly">
            <Stack direction="row" alignItems="center">
              {/* <Button
                variant="contained"
                sx={{
                  mr: 2,
                  textTransform: "none",
                  fontSize: 18,
                  backgroundColor: "orange",
                }}
                onClick={() => {
                  navigate(ROUTES.subscription);
                }}
              >
                Upgrade to Pro
              </Button> */}
              <Stack>
                <Typography>
                  {getSalutation()}, {firstName}
                </Typography>
                <Typography sx={{ fontSize: 14, textAlign: "right" }}>
                  {organisationName}
                </Typography>
              </Stack>
              <IconButton
                color="inherit"
                onClick={handleUserMenuClick}
                className="introtour-step-access"
              >
                {/* <Avatar sx={{ width: 32, height: 32, m: 1 }} alt="Some Girl">
                  <img src={process.env.PUBLIC_URL + "avatar.png"} />
                </Avatar> */}
                <UserProfilePicture
                    userID={userID}
                    headerView
                 />
              </IconButton>
            </Stack>
            <Menu
              id="basic-menu"
              anchorEl={anchorElUserMenu}
              open={openUserMenu}
              onClose={handleUserMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/profile");
                  handleUserMenuClose();
                }}
              >
                My Profile
              </MenuItem>
              {accountTypeAgency && (
                <MenuItem
                  onClick={() => {
                    navigate(ROUTES.organization);
                    handleUserMenuClose();
                  }}
                >
                  My Organisation
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  signOut();
                  handleUserMenuClose();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import * as React from "react";
import { useCallback, useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import DangerousIcon from "@mui/icons-material/Dangerous";
import ErrorIcon from "@mui/icons-material/Error";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import useAuth from "../components/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import {
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import {
  ATTACHMENT_TYPE_DRIVERSLICENSE,
  ATTACHMENT_TYPE_PUBLICINDEMNITY,
  ATTACHMENT_TYPE_WORKERSCOMP,
} from "../globals";
import {
  listProjectInvoices,
  listProjectInvoicesForTalent,
  listProjectProposals,
  listProjectProposalsForTalent,
  listProjectsForTalent,
} from "../graphql/customQueries";
import {
  TASK_COMPLETE,
  TASK_INCOMPLETE,
  TASK_WARNING,
  TASK_ERROR,
} from "../globals";
import {
  InvoiceStatus,
  InvitationStatus,
  ProposalStatus,
  ResourceStatus,
} from "../models";
import Title from "../components/Title";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardActionArea } from "@mui/material";
import { ROUTES } from "../components/routes";
import { useTour } from "@reactour/tour";
import { graphqlGetAllItems } from "../graphql/functions";

function DashboardContent() {
  const [loading, setLoading] = React.useState(true);
  const {
    ready,
    authed,
    userID,
    accountTypeAgency,
    accountTypeTalent,
    userOrgID,
    email,
  } = useAuth();
  const [attachmentCount, setAttachmentCount] = useState(0);
  const [projects, setProjects] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [talentCount, setTalentCount] = useState(0);
  const [invoiceCount, setInvoiceCount] = useState(0);
  const [taskList, setTaskList] = useState([]);
  const [notificationList, setNotificationList] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [count, setCount] = React.useState(0);
  const navigate = useNavigate();
  const { isOpen, setIsOpen } = useTour(); // used by reactour, can be deleted if no need to use tour

  useEffect(() => {
    if (ready) {
      setLoading(true);
      loadExistingAttachment();
      fetchInvoices();
      // fetchProjects();
      fetchTalent();
      fetchInvitations();
      fetchApprovedInvoices();
      fetchProposals();
      fetchApprovedProposals();
      fetchAssignedProjects();
      setLoading(false);
    }
  }, [ready]);

  const loadExistingAttachment = () => {
    const exec = API.graphql({
      query: queries.getUser,
      variables: { id: userID },
    });
    exec
      .then((x) => {
        // console.log(x.data.getUser);
        var ret1 = x.data.getUser.Attachments.items.find(
          (x) => x.Description == ATTACHMENT_TYPE_DRIVERSLICENSE
        );
        var ret2 = x.data.getUser.Attachments.items.find(
          (x) => x.Description == ATTACHMENT_TYPE_WORKERSCOMP
        );
        var ret3 = x.data.getUser.Attachments.items.find(
          (x) => x.Description == ATTACHMENT_TYPE_PUBLICINDEMNITY
        );
        var count = 0;
        if (ret1) count++;
        if (ret2) count++;
        if (ret3) count++;
        setAttachmentCount(count);
        if (accountTypeTalent && count < 3) {
          addTask(
            "Update profile (missing documents)",
            TASK_INCOMPLETE,
            "/profile"
          );
        } else if (accountTypeTalent && count == 3) {
          addTask(
            "Update profile (missing documents)",
            TASK_COMPLETE,
            "/profile"
          );
        }
      })
      .catch((err) => {
        console.log(err.errors);
      });
  };

  const openURL = (url) => {
    navigate(url);
  };

  const handleNotificationClick = (url, data) => {
    if (url.includes("invoice")) {
      const invoice = {
        id: data.id,
        _version: data._version,
        invoiceTalentId: data.invoiceTalentId,
        Custom1: "YES",
      };

      const exec = API.graphql({
        query: mutations.updateInvoice,
        variables: { input: invoice },
      });
      exec
        .then((x) => {
          console.log(x);
        })
        .catch((x) => {
          console.log(x);
        });
    } else if (url.includes("proposal")) {
      if (data) {
        const proposal = {
          id: data.id,
          _version: data._version,
          proposalTalentId: data.proposalTalentId,
          Custom3: "ACKNOWLEDGED",
        };

        const exec = API.graphql({
          query: mutations.updateProposal,
          variables: { input: proposal },
        });
        exec
          .then((x) => {
            console.log("Notification Acknowledged");
          })
          .catch((x) => {
            console.log(x);
          });
      }
    } else if (url.includes("project")) {
      if (data) {
        const deliverable = {
          id: data.id,
          _version: data._version,
          deliverableResourceId: data.deliverableResourceId,
          Custom3: "ACKNOWLEDGED",
        };

        const exec = API.graphql({
          query: mutations.updateDeliverable,
          variables: { input: deliverable },
        });
        exec
          .then((x) => {
            console.log("Notification Acknowledged");
          })
          .catch((x) => {
            console.log(x);
          });
      }
    }

    navigate(url);
  };

  const fetchProjects = async (invoices) => {
    if (accountTypeAgency) {
      try {
        var results = await graphqlGetAllItems(queries.listProjects, {
          filter: { organisationProjectsId: { eq: userOrgID } },
        });
        var filtered = results;
        setProjects(filtered);
        setProjectCount(filtered.length);
        setBudgetWarnings(invoices, filtered);
      } catch ({ name, message }) {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load projects", name, message);
        }
        // alert("Could not load projects, check your internet connection")
      }
      // console.log(result.data.listProjects.items);
    } else if (accountTypeTalent) {
      try {
        var results = await graphqlGetAllItems(listProjectsForTalent, {
          talentId: userID,
        });
        var filtered = results.filter(
          (x) =>
            !x._deleted &&
            x.Deliverables.items.find(
              (y) => !y._deleted && y.deliverableResourceId == userID
            )
        );
        setProjects(filtered);
        setProjectCount(filtered.length);
      } catch ({ name, message }) {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load projects", name, message);
        }
        // alert("Could not load projects, check your internet connection");
      }
      // console.log(result.data.listProjects.items);
    }
  };

  const fetchTalent = () => {
    const result = API.graphql({
      query: queries.listTalents,
      variables: { filter: { talentOrganisationId: { eq: userOrgID } } }, // @todo what if userOrgID is null
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var filtered = val.data.listTalents.items.filter((x) => !x._deleted);
        setTalentCount(filtered.length);
      })
      .catch(({ name, message }) => {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load talent", name, message);
        }
        // alert("Could not load talent, check your internet connection");
      });
  };

  const setBudgetWarnings = (invoices, projects) => {
    projects.map((prj) => {
      const invoicesTotal = getInvoicesTotal(invoices, prj);
      const perc = (
        ((prj.Budget - invoicesTotal) / prj.Budget) *
        100.0
      ).toFixed(0);
      if (perc < 15) {
        addTask(
          `Low budget warning (15%) for '${prj.Title}' project`,
          TASK_WARNING,
          `/project/${prj.id}/general`
        );
      } else if (perc < 10) {
        addTask(
          `Low budget warning (10%) for '${prj.Title}' project`,
          TASK_ERROR,
          `/project/${prj.id}/general`
        );
      }
    });
  };

  const fetchInvoices = async () => {
    if (accountTypeAgency) {
      try {
        var results = await graphqlGetAllItems(queries.listInvoices, {});
        var filtered = results.filter(
          (x) => x.Deliverables && x.Custom2 == userOrgID
        );
        setInvoices(filtered);
        var count = getUnapprovedInvoicesCount(filtered);
        if (count > 0) {
          addTask(`Approve invoices (${count})`, TASK_INCOMPLETE, "/invoices");
        }
        setInvoiceCount(count);
        //
        fetchProjects(filtered);
      } catch ({ name, message }) {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load invoices", name, message);
        }
        // alert("Could not load invoices, check your internet connection")
      }
    } else if (accountTypeTalent) {
      try {
        var results = await graphqlGetAllItems(queries.listInvoices, {
          filter: { owner: { eq: userID } },
        });
        var filtered = results.filter((x) => x.Deliverables);
        setInvoices(filtered);
        var count = getUnapprovedInvoicesCount(filtered);
        setInvoiceCount(count);
        //
        fetchProjects(filtered);
        // Get approved invoices
        const approvedInvoices = getApprovedInvoices(filtered);
        approvedInvoices.map((item, index) => {
          addNotification(
            `Your invoice '${item.invoiceReferenceNumber}' has been approved.`,
            TASK_INCOMPLETE,
            "/invoices",
            item
          );
        });
      } catch ({ name, message }) {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load invoices", name, message);
        }
        // alert("Could not load invoices, check your internet connection");
      }
    }
  };

  const fetchInvitations = () => {
    setLoading(true);
    if (accountTypeTalent) {
      const result = API.graphql({
        query: queries.listInvitations,
        variables: {
          filter: {
            ToEmail: { eq: email },
            Status: { eq: InvitationStatus.SENT },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      result
        .then((val) => {
          var filtered = val.data.listInvitations.items.filter(
            (x) => !x._deleted
          );
          setInvitations(filtered);
          setCount(filtered.length);
          setLoading(false);
          if (filtered.length > 0) {
            addTask(
              "You have new invitations",
              TASK_INCOMPLETE,
              "/Invitations"
            );
          }
        })
        .catch(({ name, message }) => {
          setLoading(false);
          if (message.includes("No current user")) {
            window.location.reload();
          } else {
            console.log("Could not load invitations", name, message);
          }
        });
    }
  };

  const fetchProposals = () => {
    setLoading(true);
    if (accountTypeAgency) {
      const result = API.graphql({
        query: listProjectProposals,
        variables: { filter: { owner: { eq: userID } } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      result
        .then((val) => {
          var filtered = val.data.listProjects.items.filter((x) => !x._deleted);
          const proposalCount = newProposalsCount(filtered);
          if (proposalCount > 1) {
            addNotification(
              `You have ${proposalCount} new Proposals.`,
              TASK_INCOMPLETE,
              "/proposals",
              null
            );
          } else if (proposalCount === 1) {
            addNotification(
              `You have ${proposalCount} new Proposal.`,
              TASK_INCOMPLETE,
              "/proposals",
              null
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("Could not load proposals", err);
          alert("Could not load proposals, check your internet connection");
        });
    } else if (accountTypeTalent) {
      const result = API.graphql({
        query: listProjectProposalsForTalent,
        variables: { talentId: userID },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      result
        .then((val) => {
          var filtered = val.data.listProjects.items.filter((x) => !x._deleted);
          const proposalCount = newProposalsCount(filtered);
          if (proposalCount > 1) {
            addNotification(
              `You have ${proposalCount} new Proposals.`,
              TASK_INCOMPLETE,
              "/proposals",
              null
            );
          } else if (proposalCount === 1) {
            addNotification(
              `You have ${proposalCount} new Proposal.`,
              TASK_INCOMPLETE,
              "/proposals",
              null
            );
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("Could not load proposals", err);
          alert("Could not load proposals, check your internet connection");
        });
    }
  };

  const fetchApprovedProposals = () => {
    setLoading(true);
    if (accountTypeTalent) {
      const result = API.graphql({
        query: listProjectProposalsForTalent,
        variables: { talentId: userID },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      result
        .then((val) => {
          var filtered = val.data.listProjects.items.filter((x) => !x._deleted);
          const approvedProposals = getApprovedProposals(filtered);
          approvedProposals.map((item, index) => {
            const deliverables = API.graphql({
              query: queries.getDeliverable,
              variables: { id: item.deliverableID },
              authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            deliverables
              .then((del) => {
                var deliverableTitle = del.data.getDeliverable.Title;
                addNotification(
                  `Your proposal for '${deliverableTitle}' has been approved.`,
                  TASK_COMPLETE,
                  "/proposals",
                  item
                );
              })
              .catch((err) => {
                console.log(err);
              });
            return null;
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("Could not load proposals", err);
          alert("Could not load proposals, check your internet connection");
        });
    }
  };

  const newProposalsCount = (list) => {
    let count = 0;
    list.map((item) => {
      if (!item._deleted) {
        item.Deliverables.items.map((del) => {
          return del.Proposals.items.map((prp) => {
            if (accountTypeAgency) {
              if (!prp._deleted && prp.Status === ProposalStatus.SUBMITTED) {
                count += 1;
              }
            } else if (accountTypeTalent) {
              if (!prp._deleted && prp.Status === ProposalStatus.SENT) {
                count += 1;
              }
            }
          });
        });
      }
    });
    return count;
  };

  const getApprovedProposals = (list) => {
    var c = [];
    list.map((item) => {
      if (!item._deleted) {
        item.Deliverables.items.map((del) => {
          return del.Proposals.items.map((prp) => {
            prp["deliverableID"] = del.id;
            if (
              !prp._deleted &&
              prp.Status === ProposalStatus.APPROVED &&
              prp.Custom3 !== "ACKNOWLEDGED"
            ) {
              c.push(prp);
            }
          });
        });
      }
    });
    return c;
  };

  const fetchAssignedProjects = () => {
    if (accountTypeTalent) {
      const result = API.graphql({
        query: listProjectsForTalent,
        variables: { talentId: userID, limit: 1000 },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      result
        .then((val) => {
          let filtered = val.data.listProjects.items.filter(
            (x) =>
              !x._deleted &&
              x.Deliverables.items.find(
                (y) => !y._deleted && y.deliverableResourceId === userID
              )
          );
          let assignedDeliverables = [];
          filtered.map((prj) => {
            prj.Deliverables.items.map((del) => {
              if (
                !del._deleted &&
                del.ResourceStatus === ResourceStatus.ASSIGNED &&
                del.Custom3 !== "ACKNOWLEDGED"
              ) {
                del["projectTitle"] = prj.Title;
                assignedDeliverables.push(del);
              }
              return null;
            });
            return null;
          });
          assignedDeliverables?.forEach((item) => {
            addNotification(
              `You are assigned a deliverable '${item.Title}' for project '${item.projectTitle}'.`,
              TASK_COMPLETE,
              "/projects",
              item
            );
          });
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log("Could not load projects", err);
          alert("Could not load projects, check your internet connection");
        });
    }
  };

  const getInvoicesTotal = (invs, prj) => {
    var sum = 0;
    if (invs) {
      invs.map((inv) => {
        if (inv.Deliverables) {
          var deliverables = JSON.parse(inv.Deliverables);
          if (deliverables) {
            deliverables.map((deliv) => {
              if (deliv.projectID === prj.id && inv.Status != "REJECTED") {
                sum += Number(deliv.subTotal);
              }
            });
          }
        }
      });
    }
    return sum;
  };

  const getUnapprovedInvoicesCount = (list) => {
    var c = 0;
    if (list) {
      list.map((inv) => {
        if (inv.Status === InvoiceStatus.SUBMITTED) {
          c += 1;
        }
      });
    }
    return c;
  };

  const getApprovedInvoices = (list) => {
    var c = [];
    if (list) {
      list.map((item) => {
        if (!item._deleted) {
          if (item.Deliverables.items) {
            item.Deliverables.items.map((del) => {
              if (del.Invoices) {
                return del.Invoices.items.map((inv) => {
                  if (
                    !inv._deleted &&
                    inv.Status === InvoiceStatus.APPROVED &&
                    inv.Custom1 !== "YES"
                  ) {
                    c.push(inv);
                  }
                });
              }
            });
          }
        }
      });
    }
    return c;
  };

  const fetchApprovedInvoices = () => {
    if (accountTypeTalent) {
      const result = API.graphql({
        query: listProjectInvoicesForTalent,
        variables: { talentId: userID, limit: 1000 },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      result
        .then((val) => {
          var filtered = val.data.listProjects.items.filter((x) => !x._deleted);
          const approvedInvoices = getApprovedInvoices(filtered);

          approvedInvoices.map((item, index) => {
            const deliverables = API.graphql({
              query: queries.getDeliverable,
              variables: { id: item.deliverableID },
              authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            deliverables
              .then((del) => {
                var deliverableTitle = del.data.getDeliverable.Title;
                addNotification(
                  `Your invoice for '${deliverableTitle}' has been approved.`,
                  TASK_COMPLETE,
                  "/invoices",
                  item
                );
              })
              .catch((err) => {
                console.log(err);
              });
            return null;
          });
        })
        .catch((err) => {
          console.log("Could not load projects", err);
          alert("Could not load projects, check your internet connection");
        });
    }
  };

  const addTask = (name, status, url) => {
    setTaskList((previous) => [...previous, { name, status, url }]);
  };

  const addNotification = (name, status, url, data) => {
    setNotificationList((previous) => [
      ...previous,
      { name, status, url, data },
    ]);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      <Box sx={{ mt: 2, mb: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Typography variant="h4" className="introtour-step-welcome">
              Welcome to CoFlowx
            </Typography>
          </Stack>
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl" className="introtour-step-dashboard">
          {/* <Grid item xs={12} sx={{ mb: 2 }}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Avatar sx={{ width: 76, height: 76, m: 2 }} alt="Some Girl">
                <img src={process.env.PUBLIC_URL + "avatar.png"} />
              </Avatar>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography>Have a wonderful day!</Typography>
              </Box>
            </Paper>
          </Grid> */}
          <Grid container spacing={3}>
            {/* {accountTypeTalent && <Grid item xs={12} md={6} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // height: 240,
              }}
            >
              <Typography sx={{ mb: 2 }}>Your profile</Typography>
              {attachmentCount < 3 && <>
                <Stack direction="row" alignItems="center">
                  <ErrorIcon sx={{ color: "red", mr: 1 }} />
                  <Typography>Please upload the {3 - attachmentCount} required document(s).</Typography>
                </Stack>
                <Button onClick={() => navigate('/profile')} >Update profile</Button>
              </>}
              {attachmentCount == 3 &&
                <Stack direction="row" alignItems="center">
                  <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
                  <Typography>Complete</Typography>
                </Stack>}
            </Paper>
          </Grid>} */}
            <Grid item xs={12} md={6} lg={4}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: 180,
                }}
                className="introtour-step-dashboard-category"
              >
                <CardActionArea
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="DashboardPage_ActiveProjectsClick"
                  onClick={() => navigate(ROUTES.projects)}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography sx={{ mb: 1, fontWeight: "bold" }}>
                      Active Projects
                    </Typography>
                    <Typography variant="h3">{projectCount}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            {accountTypeAgency && (
              <Grid item xs={12} md={6} lg={4}>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    height: 180,
                  }}
                >
                  <CardActionArea
                    data-amplify-analytics-on="click"
                    data-amplify-analytics-name="DashboardPage_TalentsClick"
                    onClick={() => navigate(ROUTES.talent)}
                  >
                    <CardContent sx={{ textAlign: "center" }}>
                      <Typography sx={{ mb: 1, fontWeight: "bold" }}>
                        Talents
                      </Typography>
                      <Typography variant="h3">{talentCount}</Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={4}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: 180,
                }}
              >
                <CardActionArea
                  data-amplify-analytics-on="click"
                  data-amplify-analytics-name="DashboardPage_InvoicesClick"
                  onClick={() => navigate(ROUTES.invoices)}
                >
                  <CardContent sx={{ textAlign: "center" }}>
                    <Typography sx={{ mb: 1, fontWeight: "bold" }}>
                      Invoices
                    </Typography>
                    <Typography variant="h3">{invoiceCount}</Typography>
                    <Typography>Waiting for approval</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 180,
                  maxHeight: 360,
                }}
              >
                <Typography sx={{ mb: 1, fontWeight: "bold" }}>
                  Task List
                </Typography>
                <TableContainer>
                  <Table aria-label="simple table">
                    {/* <TableHead>
                    <TableRow>
                      <TableCell>Task</TableCell>
                    </TableRow>
                  </TableHead> */}
                    {taskList.length > 0 ? (
                      <TableBody>
                        {taskList.map((row, index) => (
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            onClick={() => openURL(row.url)}
                          >
                            <TableCell component="th" scope="row">
                              <Stack direction="row" alignItems="center">
                                {TaskIcon(row)} {row.name}
                              </Stack>
                            </TableCell>
                            {/* <TableCell align="right">{row.calories}</TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            You have no tasks, super!
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 180,
                  maxHeight: 360,
                }}
              >
                <Typography sx={{ mb: 1, fontWeight: "bold" }}>
                  Notifications
                </Typography>
                <TableContainer>
                  <Table aria-label="simple table">
                    {/* <TableHead>
                    <TableRow>
                      <TableCell>Task</TableCell>
                    </TableRow>
                  </TableHead> */}
                    {notificationList.length > 0 ? (
                      <TableBody>
                        {notificationList.map((row, index) => (
                          <TableRow
                            hover
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            onClick={() =>
                              handleNotificationClick(row.url, row.data)
                            }
                          >
                            <TableCell component="th" scope="row">
                              <Stack direction="row" alignItems="center">
                                {TaskIcon(row)} {row.name}
                              </Stack>
                            </TableCell>
                            {/* <TableCell align="right">{row.calories}</TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : (
                      <TableBody>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row" align="center">
                            You have no notifications.
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  minHeight: 180,
                  maxHeight: 360,
                }}
              >
                <Typography>
                  Is this your first time using CoFlowx, click here to start a
                  tour:
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  Start Tour
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      )}
    </Box>
  );
}

function TaskIcon(task) {
  if (task.status === TASK_INCOMPLETE) {
    return <ErrorIcon sx={{ mr: 1, color: "red" }} />;
  } else if (task.status === TASK_COMPLETE) {
    return <CheckCircleIcon sx={{ mr: 1, color: "green" }} />;
  } else if (task.status === TASK_WARNING) {
    return <WarningIcon sx={{ mr: 1, color: "orange" }} />;
  } else if (task.status === TASK_ERROR) {
    return <DangerousIcon sx={{ mr: 1, color: "red" }} />;
  }
  return <div>x</div>;
}

export default function Dashboard() {
  return <DashboardContent />;
}

import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import axios from "axios";
import { Box, Button } from "@mui/material";
import useAuth from "../components/AuthContextProvider";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as models from "../models";
import { XeroIntegration } from "../globals";

export default function XeroLink() {
  const { userID, userOrgID, ready } = useAuth();
  const [step, setStep] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (ready) {
      let params = new URLSearchParams(location.search);
      if (location.search === "") {
        setStep("");
        console.log("Not started");
      } else if (location.search.includes("code=")) {
        setStep("Completed Step 1");
        console.log("Calling Step 2");
        console.log(params.get("code"));
        console.log(params.get("state"));
        callStep2(params.get("code"));
      }  
    }
  }, [ready, location.search]);

  const callStep2 = async (code) => {
    try {
      let config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "OPTIONS, POST",
          "Access-Control-Allow-Headers":
            "Access-Control-Allow-Origin,Access-Control-Allow-Methods,Access-Control-Allow-Headers,Content-Type",
        },
      };
      let data = {
        userOrgID,
        code,
      };
      const response = await axios.post(
        `https://trec1eb5a1.execute-api.ap-southeast-2.amazonaws.com/default/Xero_First_LinkUp`,
        data,
        config
      );
      console.log(response.data);
      navigate("/organisation");
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      {step === "" ?? <Link to="/">Go back to Xero Link Start</Link>}
      {step === "Step1" ?? <Box>Xero Link - Step 1</Box>}
      {/* {step === "Step2" ?? (
        <Box>
          Xero Link - Completed.
          <Button
            variant="contained"
            // target="_blank"
            // component="a"
            // href={xeroURL}
            onClick={() => navigate('/organisation')}
          >
            Return to Organisation Profile
          </Button>
        </Box>
      )} */}
    </>
  );
}

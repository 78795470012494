import * as React from "react";
import { useCallback, useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { API, ClientDevice, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as models from "../models";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import useAuth from "../components/AuthContextProvider";
import {
  CircularProgress,
  Alert,
  AlertTitle,
  Breadcrumbs,
  Chip,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  List,
  ListItem,
  ListItemIcon,
  StepConnector,
  stepConnectorClasses,
  Stepper,
  Tab,
  Tabs,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { TabPanel, a11yProps } from "../components/TabPanel";
import { FormMode } from "../globals";
import { getProject, listTalents } from "../graphql/customQueries";
import { ClientAdd } from "../components/ClientAdd";
import DeliverablesList from "../components/DeliverablesList";
import { Check, Circle } from "@mui/icons-material";
import { green } from "@mui/material/colors";
import TextInput from "../components/TextInput";
import Title from "../components/Title";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { graphqlGetAllItems } from "../graphql/functions";

function desc(a, b) {
  return b < a ? -1 : b > a ? 1 : b >= a ? 0 : NaN;
}

function asc(a, b) {
  return a < b ? -1 : a > b ? 1 : a >= b ? 0 : NaN;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function StepIcon(props) {
  const { active, completed, className } = props;

  return (
    <>
      {active && <Circle sx={{ color: "#eb4035" }} />}
      {completed && <Check color="success" />}
      {!active && !completed && <Circle color="disabled" />}
    </>
  );
}

const PROJECT_STEP_0 = "Create Project";
const PROJECT_STEP_1 = "Add Deliverables";
const PROJECT_STEP_2 = "Manage Project";
const PROJECT_STEP_3 = "Close Project";
const steps = [PROJECT_STEP_0, PROJECT_STEP_1, PROJECT_STEP_2, PROJECT_STEP_3];

const isStepOptional = (step) => {
  return step === 1 || step === 2; // zero-based index
};

function ProjectContent({ openTab }) {
  const [loading, setLoading] = React.useState(true);
  const [formMode, setformMode] = useState(FormMode.New);
  const [version, setVersion] = useState();
  const [projectID, setProjectID] = useState();
  const [formClient, setformClient] = useState("");
  const [formClientHelper, setFormClientHelper] = useState("");
  const [formTitle, setformTitle] = useState("");
  const [formTitleHelper, setFormTitleHelper] = useState("");
  const [formOverview, setformOverview] = useState("");
  const [formOverviewHelper, setFormOverviewHelper] = useState("");
  const [formClientBudget, setformClientBudget] = useState("");
  const [formClientBudgetHelper, setformClientBudgetHelper] = useState("");
  const [formBudget, setformBudget] = useState("");
  const [formBudgetHelper, setformBudgetHelper] = useState("");
  const [formStartDate, setformStartDate] = useState("");
  const [formStartDateHelper, setformStartDateHelper] = useState("");
  const [formDeadline, setformDeadline] = useState("");
  const [formDeadlineHelper, setformDeadlineHelper] = useState("");
  const [formDeliverables, setformDeliverables] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [formCustom1, setformCustom1] = useState({});
  const [clients, setClients] = useState([]);
  const [activeStep, setActiveStep] = React.useState(0);
  const [budgetList, setBudgetList] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();
  const { ready, userID, userOrgID, accountTypeAgency, accountTypeTalent } =
    useAuth();
  const [tab, setTab] = React.useState(0);
  const [openAccept, setOpenAccept] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchClients();
  };

  const handleClientAdded = useCallback((client) => {
    setformClient(client);
    //console.log(client);
  }, []);

  const handleCloseAcceptYes = () => {
    setOpenAccept(false);
    acceptProject();
  };
  const handleCloseAcceptNo = () => {
    setOpenAccept(false);
    declineProject();
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
    if (id) {
      if (newValue === 0) {
        navigate(`/project/${id}/general`);
      } else if (newValue === 1) {
        navigate(`/project/${id}/deliverables`);
      }
    }
  };

  const acceptProject = () => {
    formCustom1.firstAssignCheck = true;

    const project = {
      id: id,
      _version: version,
      ResourceStatus: "ASSIGNED",
      Custom1: JSON.stringify(formCustom1),
    };

    const exec = API.graphql({
      query: mutations.updateProject,
      variables: { input: project },
    });
    exec
      .then((x) => {
        console.log(x);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const declineProject = () => {
    const project = {
      id: id,
      _version: version,
      projectResourceId: null,
      ResourceStatus: "NOT_ASSIGNED",
    };

    const exec = API.graphql({
      query: mutations.updateProject,
      variables: { input: project },
    });
    exec
      .then((x) => {
        console.log(x);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const formValidate = () => {
    var valid = true;
    // check not empty
    if (formTitle.trim() === "") {
      setformTitle("");
      setFormTitleHelper("Proejct Title can't be empty");
      valid = false;
    }
    if (typeof formClient === "string" || formClient === null) {
      setformClient("");
      setFormClientHelper("Client can't be empty");
      valid = false;
    }
    if (formOverview.trim() === "") {
      setformOverview("");
      setFormOverviewHelper("Overview can't be empty");
      valid = false;
    }
    if (
      typeof formClientBudget === "string" &&
      formClientBudget.trim() === ""
    ) {
      setformClientBudget("");
      setformClientBudgetHelper("Client Fee can't be empty");
      valid = false;
    }
    if (typeof formBudget === "string" && formBudget.trim() === "") {
      setformBudget("");
      setformBudgetHelper("Project Budget can't be empty");
      valid = false;
    }
    if (formStartDate.trim() === "") {
      setformStartDate("");
      setformStartDateHelper("Start Date can't be empty");
      valid = false;
    }
    if (formDeadline.trim() === "") {
      setformDeadline("");
      setformDeadlineHelper("Deadline can't be empty");
      valid = false;
    }

    // no need to check more if some fields are empty
    if (!valid) {
      return false;
    }

    // deadline >= start date
    if (new Date(formDeadline) < new Date(formStartDate)) {
      setformDeadlineHelper("Deadline can't be before Start Date");
      valid = false;
    }

    // budget must be postive
    if (parseFloat(formClientBudget) < 0) {
      setformClientBudgetHelper("Client Fee can't be negative");
      valid = false;
    }
    if (parseFloat(formBudget) < 0) {
      setformBudgetHelper("Budget can't be negative");
      valid = false;
    }

    return valid;
  };

  /*
  addNewProject
  */
  const addNewProject = () => {
    if (!formValidate()) {
      return;
    }

    const newProject = {
      owner: userID,
      organisationProjectsId: userOrgID,
      projectClientId: formClient.id,
      Title: formTitle,
      Overview: formOverview,
      Budget: formBudget,
      ClientBudget: formClientBudget,
      Deadline: formDeadline,
      StartDate: formStartDate,
    };

    const exec = API.graphql({
      query: mutations.createProject,
      variables: { input: newProject },
    });
    exec
      .then((x) => {
        console.log(x);
        navigate(`/project/${x.data.createProject.id}/general`);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  /*
  updateProject
  */
  const updateProject = () => {
    if (!formValidate()) {
      return;
    }

    const project = {
      id: id,
      _version: version,
      organisationProjectsId: userOrgID,
      projectClientId: formClient.id,
      Title: formTitle,
      Overview: formOverview,
      ClientBudget: formClientBudget,
      Budget: formBudget,
      Deadline: formDeadline,
      StartDate: formStartDate,
    };

    const exec = API.graphql({
      query: mutations.updateProject,
      variables: { input: project },
    });
    exec
      .then((x) => {
        // console.log(x);
        navigate("/projects");
      })
      .catch((x) => {
        console.log(x);
      });
  };

  /*
  deleteProject
  */
  const deleteProject = () => {
    const project = {
      id: id,
      _version: version,
    };
    const exec = API.graphql({
      query: mutations.deleteProject,
      variables: { input: project },
    });
    exec
      .then((x) => {
        console.log(x);
        navigate("/projects");
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const fetchClients = async () => {
    setLoading(true);
    try {
      var results = await graphqlGetAllItems(queries.listClients, {
        filter: { organisationClientsId: { eq: userOrgID } },

      });
      var filtered = results.sort((a, b) => asc(a.Name, b.Name));
      setClients(filtered);
      setLoading(false);
    } catch ({ name, message }) {
      setLoading(false);
      if (message?.includes("No current user")) {
        window.location.reload();
      } else {
        console.log("Could not load clients", name, message);
      }
      // alert("Could not load projects, check your internet connection")
    }
  };

  const getInvoicesTotal = (prj) => {
    var sum = 0;
    prj.Deliverables.items
      .filter((x) => !x._deleted)
      .map((del) => {
        del.Invoices.items
          .filter((x) => !x._deleted)
          .map((inv) => {
            sum += inv.Total;
          });
      });
    return sum;
  };

  const getApprovedInvoicesTotal = (prj) => {
    var sum = 0;
    prj.Deliverables.items
      .filter((x) => !x._deleted)
      .map((del) => {
        del.Invoices.items
          .filter((x) => !x._deleted)
          .map((inv) => {
            if (inv.Status == models.InvoiceStatus.APPROVED) sum += inv.Total;
          });
      });
    return sum;
  };

  const getDelivarablesAllocated = (prj) => {
    var sum = 0;
    prj.Deliverables.items
      .filter((x) => !x._deleted)
      .map((del) => {
        sum += del.Allocated;
      });
    return sum;
  };

  const getDelivarablesAllocatedAmount = (prj) => {
    var sum = 0;
    prj.Deliverables.items
      .filter((x) => !x._deleted)
      .map((del) => {
        sum += del.Budget;
      });
    return sum;
  };

  const generateBudgetReport = (prj) => {
    let invoiced = getInvoicesTotal(prj);
    let approved = getApprovedInvoicesTotal(prj);
    var budList = [];
    const bud = [];
    bud["id"] = prj.id;
    bud["Project"] = prj.Title;
    bud["ClientBudget"] = prj.ClientBudget;
    bud["Budget"] = prj.Budget;
    bud["Allocated"] = getDelivarablesAllocated(prj);
    bud["AllocatedAmount"] = getDelivarablesAllocatedAmount(prj);
    bud["Balance"] = bud["Budget"] - bud["AllocatedAmount"];
    bud["ProfitForecastAmount"] = bud["ClientBudget"] - bud["AllocatedAmount"];
    bud["ProfitForecast"] =
      ((bud["ClientBudget"] - bud["AllocatedAmount"]) / bud["ClientBudget"]) *
      100.0;
    bud["Invoiced"] = invoiced;
    bud["BalanceActual"] = bud["Budget"] - bud["Invoiced"];
    bud["Approved"] = approved;
    // bud["Outstanding"] = bud["Budget"] - bud["Approved"];
    bud["ProfitAmount"] = bud["ClientBudget"] - bud["Invoiced"];
    bud["Profit"] =
      100.0 -
      ((bud["ClientBudget"] - bud["ProfitAmount"]) / bud["ClientBudget"]) *
        100.0;
    budList.push(bud);
    setBudgetList(budList);
  };

  const loadExistingProject = () => {
    if (id) {
      setProjectID(id);
      setformMode(FormMode.Edit);
      const exec = API.graphql({ query: getProject, variables: { id: id } });
      exec
        .then((x) => {
          setVersion(x.data.getProject._version);
          setformClient(x.data.getProject.Client);
          setformTitle(x.data.getProject.Title);
          setformOverview(x.data.getProject.Overview);
          setformClientBudget(x.data.getProject.ClientBudget);
          setformBudget(x.data.getProject.Budget);
          setformDeadline(x.data.getProject.Deadline);
          setformStartDate(x.data.getProject.StartDate);
          setformDeliverables(x.data.getProject.Deliverables);
          setProposals(x.data.getProject.Proposals);
          //console.log(x.data.getProject.Client);
          if (x.data.getProject.Custom1 === "") {
            setOpenAccept(true);
          } else {
            setformCustom1(JSON.parse(x.data.getProject.Custom1));
            if (
              x.data.getProject.ResourceStatus ==
                models.ResourceStatus.ASSIGNED &&
              formCustom1.firstAssignCheck == false
            ) {
              setOpenAccept(true);
            }
          }
          //
          generateBudgetReport(x.data.getProject);
          // Timeline object
          setActiveStep(1); // you're already in step 2 if you're viewing a project
          if (x.data.getProject.Deliverables.items.length > 0) setActiveStep(2);
        })
        .catch(({ name, message }) => {
          setLoading(false);
          if (message.includes("No current user")) {
            window.location.reload();
          } else {
            console.log("Could not load project detail", name, message);
          }
          // alert("Could not load project detail, check your internet connection")
        });
    }
  };

  /*
  useEffect
  */
  useEffect(() => {
    if (ready) {
      fetchClients();
      loadExistingProject();
    }

    if (openTab) {
      handleTabChange(null, openTab);
    }
  }, [ready, id]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      {/* <Container maxWidth="xl" sx={{ m: 2, ml: 0 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" href="/projects">
            Projects
          </Link>
          {formMode == FormMode.New && <Typography color="text.primary">New Project</Typography>}
          {formMode == FormMode.Edit && <Typography color="text.primary">View Project</Typography>}
        </Breadcrumbs>
      </Container> */}
      <ClientAdd
        userID={userID}
        userOrgID={userOrgID}
        open={open}
        handleClose={handleClose}
        onClientAdded={handleClientAdded}
      />
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>{formTitle}</Title>
          </Stack>
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {accountTypeTalent && (
              <Collapse in={openAccept}>
                <Grid item xs={12} sx={{ mb: 3 }}>
                  <Alert severity="info">
                    <AlertTitle>You have been added to this project</AlertTitle>
                    Do you accept?{" "}
                    <Button
                      variant="outlined"
                      sx={{ m: 1 }}
                      onClick={handleCloseAcceptYes}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{ m: 1 }}
                      onClick={handleCloseAcceptNo}
                    >
                      No
                    </Button>
                  </Alert>
                </Grid>
              </Collapse>
            )}
            {accountTypeAgency && (
              <div>
                {accountTypeAgency && (
                  <Box sx={{ m: 3 }}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        // if (isStepOptional(index)) {
                        //   labelProps.optional = (
                        //     <Box sx={{
                        //       display: 'flex',
                        //       flex: 1,
                        //       flexDirection: 'row',
                        //       justifyContent: 'center',
                        //     }}>
                        //       <Typography variant="caption">Optional</Typography>
                        //     </Box>
                        //   );
                        // }
                        // if (isStepSkipped(index)) {
                        //   stepProps.completed = false;
                        // }
                        return (
                          <Step key={label} {...stepProps}>
                            <StepLabel
                              StepIconComponent={StepIcon}
                              {...labelProps}
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        );
                      })}
                    </Stepper>
                  </Box>
                )}
                {formMode == FormMode.Edit && (
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tab}
                      onChange={handleTabChange}
                      aria-label="tabs"
                    >
                      <Tab
                        label="General"
                        {...a11yProps(0)}
                        sx={{ textTransform: "none" }}
                      />
                      <Tab
                        label="Deliverables"
                        {...a11yProps(1)}
                        sx={{ textTransform: "none" }}
                      />
                      {/* <Tab
                    label="Invoices"
                    {...a11yProps(2)}
                    sx={{ textTransform: "none" }}
                  />
                  <Tab
                    label="Proposals"
                    {...a11yProps(3)}
                    sx={{ textTransform: "none" }}
                  /> */}
                    </Tabs>
                  </Box>
                )}
                <TabPanel value={tab} index={0}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={3}
                  >
                    {accountTypeAgency && (
                      <Grid item xs={12} md={8} lg={6}>
                        <Grid container spacing={3}>
                          <Grid item xs={9} md={9} lg={12} sx={{ p: 0 }}>
                            <TextInput
                              required={accountTypeAgency}
                              disabled={accountTypeTalent}
                              id="title"
                              name="title"
                              label="Project Title"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {" "}
                                  </InputAdornment>
                                ),
                              }}
                              value={formTitle}
                              helperText={formTitleHelper}
                              error={formTitleHelper !== ""}
                              onChange={(val) => {
                                setformTitle(val.target.value);
                                setFormTitleHelper("");
                              }}
                              sx={{ width: "40vw" }}
                            />
                          </Grid>
                          <Grid item xs={8} md={8} lg={12}>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                              spacing={2}
                            >
                              {formClient && (
                                <TextInput
                                  required
                                  id="client"
                                  name="client"
                                  label="Client"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                  value={formClient ? formClient.Name : ""}
                                  sx={{ width: "20rem" }}
                                />
                              )}
                              {!formClient && (
                                <>
                                  <FormControl required>
                                    <InputLabel id="client">Client</InputLabel>
                                    <Select
                                      labelId="client"
                                      id="client"
                                      value={formClient}
                                      error={formClientHelper !== ""}
                                      label="client"
                                      onChange={(val) => {
                                        setformClient(val.target.value);
                                        setFormClientHelper("");
                                      }}
                                      sx={{ width: "20rem" }}
                                    >
                                      {clients &&
                                        clients.map((cli) => {
                                          return (
                                            <MenuItem key={cli.id} value={cli}>
                                              {cli.Name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                    <FormHelperText
                                      error={formClientHelper !== ""}
                                    >
                                      {formClientHelper}
                                    </FormHelperText>
                                  </FormControl>
                                  <Button
                                    variant="contained"
                                    sx={{ p: 2 }}
                                    onClick={() => {
                                      handleOpen();
                                    }}
                                  >
                                    Create
                                  </Button>
                                </>
                              )}
                              {formClient && (
                                <Button
                                  variant="contained"
                                  sx={{ p: 2 }}
                                  onClick={() => {
                                    setformClient(null);
                                  }}
                                >
                                  Unassign
                                </Button>
                              )}
                            </Stack>
                          </Grid>
                          <Grid item xs={9} md={9} lg={12} sx={{ p: 0 }}>
                            <TextInput
                              required
                              id="overview"
                              name="overview"
                              label="Overview"
                              fullWidth
                              multiline
                              rows={4}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {" "}
                                  </InputAdornment>
                                ),
                              }}
                              value={formOverview}
                              helperText={formOverviewHelper}
                              error={formOverviewHelper !== ""}
                              onChange={(val) => {
                                setformOverview(val.target.value);
                                setFormOverviewHelper("");
                              }}
                              // sx={{ width: 400 }}
                            />
                          </Grid>
                          <Grid item xs={4} md={4} lg={8}>
                            <Stack
                              direction="row"
                              sx={{ alignItems: "center" }}
                              spacing={1}
                            >
                              <TextInput
                                required
                                id="clientBudget"
                                name="clientBudget"
                                label="Client Fee"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                                type="number"
                                value={formClientBudget}
                                helperText={formClientBudgetHelper}
                                error={formClientBudgetHelper !== ""}
                                onChange={(val) => {
                                  setformClientBudget(val.target.value);
                                  setformClientBudgetHelper("");
                                }}
                                // sx={{ width: 160 }}
                              />
                              <Tooltip title="Client Fee is only be seen by you, and will not be shown to talent.">
                                <InfoIcon />
                              </Tooltip>
                            </Stack>
                          </Grid>
                          <Grid item xs={4} md={4} lg={8}>
                            <TextInput
                              required
                              id="budget"
                              name="budget"
                              label="Project Budget"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                              value={formBudget}
                              helperText={formBudgetHelper}
                              error={formBudgetHelper !== ""}
                              onChange={(val) => {
                                setformBudget(val.target.value);
                                setformBudgetHelper("");
                              }} // sx={{ width: 160 }}
                            />
                          </Grid>
                          <Grid item xs={8} md={8} lg={12}>
                            <TextInput
                              required
                              id="startDate"
                              name="startDate"
                              label="Start Date"
                              type="date"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    Date
                                  </InputAdornment>
                                ),
                              }}
                              value={formStartDate}
                              helperText={formStartDateHelper}
                              error={formStartDateHelper !== ""}
                              onChange={(val) => {
                                setformStartDate(val.target.value);
                                setformStartDateHelper("");
                              }}
                            />
                          </Grid>
                          <Grid item xs={8} md={8} lg={12}>
                            <TextInput
                              required
                              id="deadline"
                              name="deadline"
                              label="Deadline"
                              type="date"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    Date
                                  </InputAdornment>
                                ),
                              }}
                              value={formDeadline}
                              helperText={formDeadlineHelper}
                              error={formDeadlineHelper !== ""}
                              onChange={(val) => {
                                setformDeadline(val.target.value);
                                setformDeadlineHelper("");
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sx={{ mt: 4 }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {accountTypeAgency && formMode == FormMode.New && (
                        <Button
                          variant="contained"
                          sx={{ width: 100, m: 1, p: 2 }}
                          onClick={() => {
                            addNewProject();
                          }}
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="Projects_CreateProjectClick"
                        >
                          Create
                        </Button>
                      )}
                      {accountTypeAgency && formMode == FormMode.Edit && (
                        <Button
                          variant="contained"
                          sx={{ width: 100, m: 1, p: 2 }}
                          onClick={() => {
                            updateProject();
                          }}
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="Projects_UpdateProjectClick"
                        >
                          Update
                        </Button>
                      )}
                      {accountTypeAgency && formMode == FormMode.Edit && (
                        <Button
                          variant="contained"
                          sx={{ width: 100, m: 1, p: 2 }}
                          onClick={() => {
                            deleteProject();
                          }}
                          data-amplify-analytics-on="click"
                          data-amplify-analytics-name="Projects_DeleteProjectClick"
                        >
                          Delete
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        sx={{ width: 100, m: 1, p: 2 }}
                        onClick={() => {
                          navigate("/projects");
                        }}
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="Projects_CloseProjectClick"
                      >
                        Close
                      </Button>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  {/* <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={12} md={8} lg={6}>
              Deliverables
            </Grid>
          </Grid> */}
                  {!loading && <DeliverablesList id={projectID} />}
                  {/* <div>{budget.Budget}</div>
              <div>{budget.Invoiced}</div>
              <div>{budget.Balance}</div>
              <div>{budget.Approved}</div>
              <div>{budget.Outstanding}</div>
              <div>{budget.Profit}</div> */}
                  {accountTypeAgency && (
                    <div>
                      <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                        Current Project Budget Summary
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table aria-label="customized table">
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell>
                                <Stack direction="column">
                                  <div>Project</div>
                                  <div style={{ padding: 5, color: "#000000" }}>
                                    Project
                                  </div>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <div style={{ textAlign: "left" }}>
                                    Client Fee
                                  </div>
                                  <Tooltip title="Client's fee for the project">
                                    <IconButton color="primary">
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <div style={{ textAlign: "left" }}>
                                    Budgeted
                                  </div>
                                  <Tooltip title="Internal budget">
                                    <IconButton color="primary">
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <div style={{ textAlign: "left" }}>
                                    Allocated
                                  </div>
                                  <Tooltip title="Total amount allocated to talent">
                                    <IconButton color="primary">
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <div style={{ textAlign: "left" }}>
                                    Balance
                                  </div>
                                  <Tooltip title="Budgeted amount minus allocated">
                                    <IconButton color="primary">
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <div style={{ textAlign: "left" }}>
                                    Profit (Forecast)
                                  </div>
                                  <Tooltip title="Client budget minus allocated">
                                    <IconButton color="primary">
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <div style={{ textAlign: "left" }}>
                                    All Invoices
                                  </div>
                                  <Tooltip title="Total of all invoiced amount">
                                    <IconButton color="primary">
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </StyledTableCell>
                              {false && (
                                <StyledTableCell>
                                  <Stack
                                    direction="column"
                                    alignItems="flex-start"
                                  >
                                    <div style={{ textAlign: "left" }}>
                                      Approved Invoices
                                    </div>
                                    <Tooltip title="Total of all approved invoiced amount">
                                      <IconButton color="primary">
                                        <InfoIcon fontSize="small" />
                                      </IconButton>
                                    </Tooltip>
                                  </Stack>
                                </StyledTableCell>
                              )}
                              <StyledTableCell>
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <div style={{ textAlign: "left" }}>
                                    Balance (Actual)
                                  </div>
                                  <Tooltip title="Internal budget minus invoices">
                                    <IconButton color="primary">
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Stack
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <div style={{ textAlign: "left" }}>
                                    Profit (Actual)
                                  </div>
                                  <Tooltip title="Client budget minus invoices">
                                    <IconButton color="primary">
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {budgetList &&
                              budgetList.map((bud) => (
                                <StyledTableRow key={bud.id}>
                                  <StyledTableCell>
                                    {bud.Project}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    $
                                    {bud.ClientBudget.toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 0,
                                      }
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    $
                                    {bud.Budget.toLocaleString(undefined, {
                                      maximumFractionDigits: 0,
                                    })}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Stack direction="column">
                                      <div>
                                        $
                                        {bud.AllocatedAmount.toLocaleString(
                                          undefined,
                                          { maximumFractionDigits: 0 }
                                        )}
                                      </div>
                                      <div>
                                        (
                                        {bud.Allocated.toLocaleString(
                                          undefined,
                                          {
                                            maximumFractionDigits: 0,
                                          }
                                        )}
                                        %)
                                      </div>
                                    </Stack>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    $
                                    {bud.Balance.toLocaleString(undefined, {
                                      maximumFractionDigits: 0,
                                    })}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Stack
                                      direction="column"
                                      sx={{
                                        color:
                                          bud.ProfitForecastAmount >= 0
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      <div>
                                        $
                                        {bud.ProfitForecastAmount.toLocaleString(
                                          undefined,
                                          { maximumFractionDigits: 0 }
                                        )}
                                      </div>
                                      <div>
                                        (
                                        {bud.ProfitForecast.toLocaleString(
                                          undefined,
                                          { maximumFractionDigits: 0 }
                                        )}
                                        %)
                                      </div>
                                    </Stack>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    $
                                    {bud.Invoiced.toLocaleString(undefined, {
                                      maximumFractionDigits: 0,
                                    })}
                                  </StyledTableCell>
                                  {false && (
                                    <StyledTableCell>
                                      $
                                      {bud.Approved.toLocaleString(undefined, {
                                        maximumFractionDigits: 0,
                                      })}
                                    </StyledTableCell>
                                  )}
                                  <StyledTableCell>
                                    $
                                    {bud.BalanceActual.toLocaleString(
                                      undefined,
                                      {
                                        maximumFractionDigits: 0,
                                      }
                                    )}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Stack
                                      direction="column"
                                      sx={{
                                        color:
                                          bud.ProfitAmount >= 0
                                            ? "green"
                                            : "red",
                                      }}
                                    >
                                      <div>
                                        $
                                        {bud.ProfitAmount.toLocaleString(
                                          undefined,
                                          { maximumFractionDigits: 0 }
                                        )}
                                      </div>
                                      <div>
                                        (
                                        {bud.Profit.toLocaleString(undefined, {
                                          maximumFractionDigits: 0,
                                        })}
                                        %)
                                      </div>
                                    </Stack>
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  )}
                </TabPanel>
                {/* <TabPanel value={tab} index={2}></TabPanel>
            <TabPanel value={tab} index={3}></TabPanel> */}
              </div>
            )}
            {accountTypeTalent && (
              <div>{!loading && <DeliverablesList id={projectID} />}</div>
            )}
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default function ProjectView({ tab }) {
  return <ProjectContent openTab={tab} />;
}

/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_mobile_analytics_app_id": "0a7fa8315f9040598f6f63290a313c40",
    "aws_mobile_analytics_app_region": "ap-southeast-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "0a7fa8315f9040598f6f63290a313c40",
            "region": "ap-southeast-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://4lu4ny7xqjbijn2a3nlmpe2pte.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ilveiys7lbhstc3xikjjr26a74",
    "aws_cognito_identity_pool_id": "ap-southeast-2:4b45e99b-2cb9-4eaf-ba12-a8cbbe4d234c",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_TsrDLKeQb",
    "aws_user_pools_web_client_id": "5hks9ubt62vassclh64tk2j250",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "coflowxapp-storage-831cb31944927-dev",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;

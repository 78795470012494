import * as React from 'react';
import { useCallback, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../components/Title';
import SearchIcon from '@mui/icons-material/Search';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as models from '../models';
import useAuth from '../components/AuthContextProvider';
import { Container, Grid, IconButton, Stack, TablePagination, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { green } from '@mui/material/colors';
import { ClientAdd } from '../components/ClientAdd';
import TextInput from '../components/TextInput';
import { ContractStatus } from '../components/ContractStatus';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function AgenciesContent() {
  const [loading, setLoading] = React.useState(true);
  const [agencies, setAgencies] = useState([]);
  const [page, setPage] = React.useState(0);
  const [searchString, setSearchString] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const { ready, userID, userOrgID, email, accountTypeAgency, accountTypeTalent } = useAuth();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchAgencies();
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (ready) {
      fetchAgencies();
    }
  }, [ready]);

  const fetchAgencies = () => {
    setLoading(true);
    const result = API.graphql({
      query: queries.listTalents,
      variables: { filter: { talentUserId: { eq: userID } } },
      authMode: "AMAZON_COGNITO_USER_POOLS"
    });
    result.then((val) => {
      var filtered = val.data.listTalents.items.filter((x) => !x._deleted);
      setAgencies(filtered);
      setCount(filtered.length);
      setLoading(false);
    }).catch(({name, message}) => {
      setLoading(false);
      if (message.includes("No current user")) {
        window.location.reload();
      }
      else {
        console.log("Could not load invitations", name, message);
      }
      // alert("Could not load invitations, check your internet connection")
    });
  }

  const linkToContract = (id) => {
    navigate(`/contract/${id}/clients`)
  }

  const filtered = agencies && agencies.filter((x) =>
    x.Organisation.Name.toString().toLowerCase().includes(searchString.toLowerCase())
  );

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: 'transparent',
        mb: 3
      }}
    >
      <Box sx={{ mb: 2, backgroundColor: '#ececec', borderBottom: '1px solid darkgrey' }}>
        <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', p: 2, pl: 3, pr: 3 }}>
          <Stack>
            <Title>Clients</Title>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.75)', fontSize: '0.9rem' }}>
              View the list of agencies
            </Typography>
          </Stack>
        </Box>
      </Box>
      {loading ?
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
        :
        <Container maxWidth="xl">
          <ClientAdd userID={userID} userOrgID={userOrgID} open={open} handleClose={handleClose} />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box
              sx={{ display: 'flex', border: 1, borderRadius: 1, borderColor: 'darkgrey' }}
            >
              <IconButton sx={{ p: '10px' }} aria-label="menu">
                <SearchIcon />
              </IconButton>
              <TextInput
                sx={{ outline: 'none', width: '400px', "& fieldset": { border: 'none' } }}
                value={searchString}
                onChange={(val) => setSearchString(val.target.value)}
                placeholder="Search agency"
              />
            </Box>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            >
            </TablePagination>
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  {/* <StyledTableCell>Contract</StyledTableCell> */}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {filtered.length > 0 ? filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((cli) => {
                  return (
                    <StyledTableRow key={cli.id}>
                      <StyledTableCell>{cli.Organisation.Name }</StyledTableCell>
                      {/* <StyledTableCell>
                        <IconButton sx={{ border: 1, m: 1 }} onClick={() => linkToContract(cli.Contract.id)}>
                          <LaunchIcon />
                        </IconButton>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  );
                }) : <StyledTableRow>
                  <StyledTableCell>No agencies to display</StyledTableCell>
                  {/* <StyledTableCell></StyledTableCell> */}
                </StyledTableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Container>}
    </Box>
  );
}

export default function Agencies() {
  return <AgenciesContent />;
}
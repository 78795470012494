import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "../components/Copyright";
import { useNavigate } from "react-router-dom";
import logo from "../logo1.png";
import useAuth from "../components/AuthContextProvider";

function HomePage() {
  const navigate = useNavigate();
  const { authed } = useAuth();

  useEffect(() => {
    if (authed) {
      navigate("/dashboard");
    }
  }, [authed]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        p: 1,
        caretColor: "transparent",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ m: 2 }}>
              <img width="100px" src={logo} alt="Logo" />
            </Box>
            <Typography component="h1" variant="h5">
              Welcome to CoFlowx
            </Typography>
            <Button
              variant="contained"
              sx={{
                mt: 3,
                borderColor: "black",
                fontWeight: "fontWeightHeavy",
              }}
              onClick={() => {
                navigate("/signin");
              }}
            >
              Log in
            </Button>
            <Button
              variant="contained"
              sx={{
                mt: 3,
                borderColor: "black",
                fontWeight: "fontWeightHeavy",
              }}
              onClick={() => {
                navigate("/signup");
              }}
            >
              Create Account
            </Button>
            {/* <Grid item sx={{ mt: 3 }}>
          <Grid item xs>
            <Link href="#" variant="body2">
              Where am I?
            </Link>
          </Grid>
        </Grid> */}
          </Box>
          <Copyright sx={{ mt: 4, mb: 4 }} />
        </Container>
      </Box>
    </Box>
  );
}

export default HomePage;

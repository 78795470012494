import * as React from 'react';
import { useCallback, useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as queries from '../graphql/queries';
import * as models from '../models';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import useAuth from '../components/AuthContextProvider';
import { ProposalStatus } from '../components/ProposalStatus';
import { Breadcrumbs, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { green } from '@mui/material/colors';
import { ProposalApproval } from '../components/ProposalApproval';
import { Stack } from '@mui/system';
import { ATTACHMENT_TYPE_SERVICECONTRACT, FormMode } from '../globals';
import TextInput from '../components/TextInput';
import Title from '../components/Title';
import { ContractAttachmentUpload } from '../components/ContractAttachmentUpload';
import { S3Link } from '../components/S3Link';
import { Check, Circle } from '@mui/icons-material';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '&.Mui-disabled': {
    color: 'red'
  }
}));

function StepIcon(props) {
  const { active, completed, className } = props;

  return (
    <>
      {active && <Circle sx={{ color: '#eb4035' }} />}
      {completed && <Check color='success' />}
      {!active && !completed && <Circle color='disabled' />}
    </>
  );
}

const PROJECT_STEP_0 = "Not Defined";
const PROJECT_STEP_1 = "Sent";
const PROJECT_STEP_2 = "Accepted";
const steps = [PROJECT_STEP_0, PROJECT_STEP_1, PROJECT_STEP_2];

const isStepOptional = (step) => {
  return step === 1 || step === 2;  // zero-based index
};

function ContractViewContent() {
  const [loading, setLoading] = React.useState(true);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [formMode, setformMode] = useState(FormMode.New);
  const [version, setVersion] = useState();
  const [formStatus, setformStatus] = useState("");
  const [formFile, setformFile] = useState("");
  const [formRate, setformRate] = useState("");
  const [formSentDate, setformSentDate] = useState("");
  const [formAcceptDate, setformAcceptDate] = useState("");
  const [formStartDate, setformStartDate] = useState("");
  const [formExpiryDate, setformExpiryDate] = useState("");
  const navigate = useNavigate();
  const { id, ret } = useParams();
  const { ready, userID, accountTypeAgency, accountTypeTalent } = useAuth();
  const [activeStep, setActiveStep] = React.useState(0);

  const goBack = () => {
    if (ret) {
      navigate(`/${ret}`);
    } else {
      navigate("/dashboard");
    }
  };

  const sendContract = () => {
    var newDate = new Date();
    var dStart = newDate.toISOString().split("T")[0];

    const contract = {
      id: id,
      _version: version,
      Status: models.ContractStatus.SENT,
      SentDate: dStart,
      AcceptDate: formAcceptDate,
      StartDate: formStartDate,
      ExpiryDate: formExpiryDate,
      Rate: formRate,
    };

    const exec = API.graphql({
      query: mutations.updateContract,
      variables: { input: contract },
    });
    exec
      .then((x) => {
        console.log(x);
        setActiveStep(1);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const confirmAccept = () => {
    setDialogVisible(true);
  };

  const acceptContract = () => {
    var newDate = new Date();
    var dStart = newDate.toISOString().split("T")[0];

    const contract = {
      id: id,
      _version: version,
      Status: models.ContractStatus.ACCEPTED,
      AcceptDate: dStart,
    };

    const exec = API.graphql({
      query: mutations.updateContract,
      variables: { input: contract },
    });
    exec
      .then((x) => {
        console.log(x);
        setActiveStep(3);
        setDialogVisible(false);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const loadExistingContract = () => {
    var newDate = new Date();
    var dStart = newDate.toISOString().split("T")[0];
    newDate.setFullYear(newDate.getFullYear() + 1);
    var dEnd = newDate.toISOString().split("T")[0];
    if (id) {
      setformMode(FormMode.Edit);
      const exec = API.graphql({
        query: queries.getContract,
        variables: { id: id },
      });
      exec
        .then((x) => {
          setVersion(x.data.getContract._version);
          setformStatus(x.data.getContract.Status);
          setformRate(x.data.getContract.Rate);
          setformSentDate(x.data.getContract.SentDate);
          setformAcceptDate(x.data.getContract.AcceptDate);
          setformStartDate(x.data.getContract.StartDate ?? dStart);
          setformExpiryDate(x.data.getContract.ExpiryDate ?? dEnd);
          setLoading(false);
          //
          switch (x.data.getContract.Status) {
            case models.ContractStatus.UNDEFINED:
              setActiveStep(0);
              break;
            case models.ContractStatus.SENT:
              setActiveStep(1);
              break;
            case models.ContractStatus.ACCEPTED:
              setActiveStep(3);
              break;
          }
        })
        .catch(({ name, message }) => {
          setLoading(false);
          if (message.includes("No current user")) {
            window.location.reload();
          } else {
            console.log("Could not load contracts", name, message);
          }
          // alert("Could not load contracts, check your internet connection");
        });
    }
  };

  /*
  useEffect
  */
  useEffect(() => {
    if (ready) {
      loadExistingContract();
    }
  }, [ready, id]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      {/* <Container maxWidth="xl" sx={{ m: 2, ml: 0 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" href="/proposals">
            Proposals
          </Link>
          <Typography color="text.primary">View Proposal</Typography>
        </Breadcrumbs>
      </Container> */}
      {/* <Dialog
        open={true}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>Contract</Title>
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.75)", fontSize: "0.9rem" }}
            >
              A binding agreement between two parties
            </Typography>
          </Stack>
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
                mb: 3,
              }}
            >
              {/* <TextInput
                id="project"
                name="project"
                label="Project"
                InputProps={{
                  startAdornment: <InputAdornment position="start"> </InputAdornment>,
                }}
                value={formProject}
                readonly
                sx={{ width: '60vw', caretColor: 'transparent', m: 1 }}
              /> */}
            </Box>
            <Dialog
              open={dialogVisible}
              aria-labelledby="confirm-dialog-title"
              aria-describedby="confirm-dialog-description"
            >
              <DialogTitle id="confirm-dialog-title">
                {"Acceptance"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="confirm-dialog-description">
                  <div>
                    By clicking Yes, you accept the following:
                    <ol>
                      <li>
                        You have read and understood the terms & conditions of
                        CoFlowx
                      </li>
                      <li>
                        You have read and understood the terms & conditions
                        listed in the Service Contract
                      </li>
                    </ol>
                  </div>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  sx={{ width: 100, m: 1, p: 2 }}
                  onClick={() => {
                    acceptContract();
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  sx={{ width: 100, m: 1, p: 2 }}
                  onClick={() => {
                    setDialogVisible(false);
                  }}
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
            <Grid container direction="row" justifyContent="center" spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    // if (isStepOptional(index)) {
                    //   labelProps.optional = (
                    //     <Box sx={{
                    //       display: 'flex',
                    //       flex: 1,
                    //       flexDirection: 'row',
                    //       justifyContent: 'center',
                    //     }}>
                    //       <Typography variant="caption">Optional</Typography>
                    //     </Box>
                    //   );
                    // }
                    // if (isStepSkipped(index)) {
                    //   stepProps.completed = false;
                    // }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel StepIconComponent={StepIcon} {...labelProps}>
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
              {((accountTypeAgency && activeStep == 0) || activeStep > 0) && (
                <Grid item xs={12} md={12} lg={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={3}
                  >
                    <Grid item xs={6} md={6} lg={6}>
                      <Grid container spacing={3}>
                        {/* <Grid item>
                        <Typography sx={{ ml: 2, color: 'rgba(0, 0, 0, 0.75)', fontSize: '0.8rem' }}>Status</Typography>
                        <ContractStatus status={formStatus} />
                      </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                          <Stack direction="row">
                            <TextInput
                              disabled={true}
                              id="agency"
                              name="agency"
                              label="Agency"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {" "}
                                  </InputAdornment>
                                ),
                              }}
                              value="Agency"
                              // sx={{ width: 100 }}
                            />
                            <Box
                              sx={{
                                m: 1,
                                mt: 3,
                                width: "60px",
                                borderTop: "2px solid black",
                              }}
                            ></Box>
                            <TextInput
                              disabled={true}
                              id="talent"
                              name="talent"
                              label="Talent"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {" "}
                                  </InputAdornment>
                                ),
                              }}
                              value="Talent"
                              // sx={{ width: 100 }}
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={4} md={4} lg={8} sx={{ p: 0 }}>
                          <Typography
                            sx={{
                              ml: 2,
                              color: "rgba(0, 0, 0, 0.75)",
                              fontSize: "0.8rem",
                            }}
                          >
                            File
                          </Typography>
                          <ContractAttachmentUpload
                            contractId={id}
                            fileDescription={ATTACHMENT_TYPE_SERVICECONTRACT}
                            nodelete={activeStep >= 1}
                          />
                        </Grid>
                        <Grid item xs={4} md={4} lg={8}>
                          <TextInput
                            id="rate"
                            name="rate"
                            label="Rate"
                            disabled={activeStep > 0}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                            type="number"
                            value={formRate}
                            onChange={(val) => setformRate(val.target.value)}
                            // sx={{ width: 160 }}
                          />
                        </Grid>
                        <Grid item xs={4} md={4} lg={8}>
                          <TextInput
                            id="startDate"
                            name="startDate"
                            label="Start Date"
                            disabled={activeStep > 0}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  Date
                                </InputAdornment>
                              ),
                            }}
                            type="date"
                            value={formStartDate}
                            onChange={(val) => formStartDate(val.target.value)}
                            // sx={{ width: 160 }}
                          />
                        </Grid>
                        <Grid item xs={4} md={4} lg={8}>
                          <TextInput
                            id="expiryDate"
                            name="expiryDate"
                            label="Expiry Date"
                            disabled={activeStep > 0}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  Date
                                </InputAdornment>
                              ),
                            }}
                            type="date"
                            value={formExpiryDate}
                            onChange={(val) =>
                              setformExpiryDate(val.target.value)
                            }
                            // sx={{ width: 160 }}
                          />
                        </Grid>
                        {activeStep >= 1 && (
                          <Grid item xs={4} md={4} lg={8}>
                            <TextInput
                              id="sentDate"
                              name="sentDate"
                              label="Sent Date"
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    Date
                                  </InputAdornment>
                                ),
                              }}
                              type="date"
                              value={formSentDate}
                              // onChange={(val) => setformSentDate(val.target.value)}
                              // sx={{ width: 160 }}
                            />
                          </Grid>
                        )}
                        {activeStep >= 2 && (
                          <Grid item xs={4} md={4} lg={8}>
                            <TextInput
                              id="acceptDate"
                              name="acceptDate"
                              label="Accept Date"
                              disabled
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    Date
                                  </InputAdornment>
                                ),
                              }}
                              type="date"
                              value={formAcceptDate}
                              // onChange={(val) => setformAcceptDate(val.target.value)}
                              // sx={{ width: 160 }}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {accountTypeAgency && activeStep == 0 && (
                  <Button
                    variant="contained"
                    sx={{ width: 100, m: 1, p: 2 }}
                    onClick={() => {
                      sendContract();
                    }}
                  >
                    Send
                  </Button>
                )}
                {accountTypeTalent && activeStep == 1 && (
                  <Button
                    variant="contained"
                    sx={{ width: 100, m: 1, p: 2 }}
                    onClick={() => {
                      confirmAccept();
                    }}
                  >
                    Accept
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={{ width: 100, m: 1, p: 2 }}
                  onClick={() => {
                    goBack();
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default function ContractView() {
  return <ContractViewContent />;
}
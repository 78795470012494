import * as React from "react";
import Chip from "@mui/material/Chip";
import * as models from "../models";
import useAuth from "../components/AuthContextProvider";
import { Tooltip } from "@mui/material";

export function InvitationStatus({ status }) {
  const { accountTypeAgency, accountTypeTalent } = useAuth();

  if (accountTypeAgency && status == models.InvitationStatus.SENT) {
    return (
      <Tooltip
        placement="top"
        title="The invitation has been sent to the Talent"
      >
        <Chip label="Sent" color="primary" />
      </Tooltip>
    );
  } else if (accountTypeTalent && status == models.InvitationStatus.SENT) {
    return (
      <Tooltip placement="top" title="An invitation has been received">
        <Chip label="Received" color="primary" />
      </Tooltip>
    );
  } else if (accountTypeAgency && status == models.InvitationStatus.REJECTED) {
    return (
      <Tooltip placement="top" title="Your invitation has been rejected">
        <Chip label="Rejected" color="error" />
      </Tooltip>
    );
  } else if (accountTypeTalent && status == models.InvitationStatus.REJECTED) {
    return (
      <Tooltip placement="top" title="You rejected the invitation">
        <Chip label="Rejected" color="error" />
      </Tooltip>
    );
  } else if (accountTypeAgency && status == models.InvitationStatus.ACCEPTED) {
    return (
      <Tooltip placement="top" title="Your invitation has been accepted">
        <Chip label="Accepted" color="success" />
      </Tooltip>
    );
  } else if (accountTypeTalent && status == models.InvitationStatus.ACCEPTED) {
    return (
      <Tooltip placement="top" title="You accept the invitation">
        <Chip label="Accepted" color="success" />
      </Tooltip>
    );
  }

  return <div>(err)</div>;
}

// this file is used by @reactour library
// to add a new step:
// 1. add a className to target element, for example className="step-create-project", search step-profile for am example
// 2. add an entry in this file, the order of entries is order of steps of a tour

export const introTourAgency = [
  {
    selector: '.introtour-step-welcome',
    content: 'Hi there, welcome to CoFlowx! Let\'s start the tour'
  },
  {
    selector: '.introtour-step-dashboard',
    content: 'This is your dashboard where you can see a summary of your projects, invoices, etc.'
  },
  {
    selector: '.introtour-step-dashboard-category',
    content: 'Each category on the dashboard is clickable'
  },
  {
    selector: '.introtour-step-access',
    content: 'This is where you can configure your personal information, organisation and integrate with Xero'
  },
  {
    selector: '.introtour-step-open-side-menu',
    content: 'You can extend the side menu here'
  },
  {
    selector: '.introtour-step-agencies',
    content: 'You can add clients here or download from your Xero account'
  },
  {
    selector: '.introtour-step-talents',
    content: 'Invite talent here'
  },
  {
    selector: '.introtour-step-projects',
    content: 'Add new projects and invite talent to a project'
  },
  {
    selector: '.introtour-step-proposals',
    content: 'Approve any proposals from talent here'
  },
  {
    selector: '.introtour-step-invoices',
    content: 'Approve or decline invoices'
  },
  {
    selector: '.introtour-step-budgets',
    content: 'Budgets are automatically calculated from the project budgets and talent invoices'
  },
]


export const introTourTalent = [
  {
    selector: '.introtour-step-welcome',
    content: 'Hi there, welcome to CoFlowx! Let\'s start the tour.'
  },
  {
    selector: '.introtour-step-dashboard',
    content: 'This is your dashboard where you can see a summary of your projects, invoices, etc.'
  },
  {
    selector: '.introtour-step-dashboard-category',
    content: 'Each category on the dashboard is clickable'
  },
  {
    selector: '.introtour-step-access',
    content: 'This is where you can configure your personal information'
  },
  {
    selector: '.introtour-step-open-side-menu',
    content: 'You can extend the side menu here'
  },
  {
    selector: '.introtour-step-clients',
    content: 'View your agencies here'
  },
  {
    selector: '.introtour-step-projects',
    content: 'View your projects here'
  },
  {
    selector: '.introtour-step-proposals',
    content: 'Accept any proposals from agencies here'
  },
  {
    selector: '.introtour-step-invoices',
    content: 'Submit your invoices here'
  },
]

// Intro Tour
// 1 - This is where you add your organisation and integrate with Xero 
// 2 - You can extend the side menu here
// 3 - Each category on the dashboard is clickable
// 4 - (Clients) You can add clients here or download from your Xero account
// 5 - (Talent) Invite talent here
// 6 - (Projects) Add new projects and invite talent to a project
// 7 - (Proposals) Approve any proposals from talent here 
// 8 - (Invoices) - Approve or decline invoices
// 9 - (Budgets) - Budgets are automatically calculated from the project budgets and talent invoices

import * as React from "react";
import { useCallback, useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as models from "../models";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useAuth from "../components/AuthContextProvider";
import {
  CircularProgress,
  Breadcrumbs,
  Container,
  Link,
  Typography,
  Stack,
  Tabs,
  Tab,
  TableContainer,
  Table,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TabPanel, a11yProps } from "../components/TabPanel";
import { green } from "@mui/material/colors";
import { UserProfileAttachmentUpload } from "../components/UserProfileAttachmentUpload";
import {
  ATTACHMENT_TYPE_DRIVERSLICENSE,
  ATTACHMENT_TYPE_PUBLICINDEMNITY,
  ATTACHMENT_TYPE_SERVICECONTRACT,
  ATTACHMENT_TYPE_WORKERSCOMP,
  FormMode,
} from "../globals";
import TextInput from "../components/TextInput";
import Title from "../components/Title";
import { UserProfilePicture } from "../components/UserProfilePicture";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.black,
  // },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function UserProfileContent() {
  const phoneNumFormatMsg = "Please use mobile format +61XXXXXXXXXX";
  const [loading, setLoading] = React.useState(true);
  const [version, setVersion] = useState();
  const [formMode, setformMode] = useState(FormMode.New);
  const [formId, setformId] = useState("");
  const [formFirstName, setformFirstName] = useState("");
  const [formFirstNameHelper, setFormFirstNameHelper] = useState("");
  const [formLastName, setformLastName] = useState("");
  const [formLastNameHelper, setFormLastNameHelper] = useState("");
  const [formPhoneNumber, setformPhoneNumber] = useState("");
  const [formPhoneNumberHelper, setformPhoneNumberHelper] =
    useState(phoneNumFormatMsg);
  const [formEmail, setformEmail] = useState("");
  const [formExpertise, setformExpertise] = useState("");
  const [formIndustry, setformIndustry] = useState("");
  const [formPortfolio, setformPortfolio] = useState("");
  const [formTaxID, setformTaxID] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const { ready, userID, accountTypeAgency } = useAuth();
  const [tab, setTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const validPhoneNumber = (number) => {
    return /^\+610?[1-9]\d{8}$/.test(number); // starts with +61, then an optional 0, then one non-zero digit, then 8 digtis
  };

  const updateUserInfo = () => {
    var valid = true;
    if (formFirstName.trim() === "") {
      setformFirstName("");
      setFormFirstNameHelper("Frist Name can't be empty");
      valid = false;
    }
    if (formLastName.trim() === "") {
      setformLastName("");
      setFormLastNameHelper("Last Name can't be empty");
      valid = false;
    } else if (formPhoneNumber.trim() && !validPhoneNumber(formPhoneNumber)) {
      // only check format when phone number is not empty
      setformPhoneNumberHelper(phoneNumFormatMsg);
      valid = false;
    }

    if (!valid) {
      return;
    }

    var userInfo = {
      id: formId,
      _version: version,
      FirstName: formFirstName.trim(),
      LastName: formLastName.trim(),
      PhoneNumber: formPhoneNumber.trim(),
      Expertise: formExpertise ?? "",
      Industry: formIndustry ?? "",
      TaxID: formTaxID,
    };
    if (formPortfolio) userInfo.Portfolio = formPortfolio;

    const exec = API.graphql({
      query: mutations.updateUser,
      variables: { input: userInfo },
    });
    exec
      .then((x) => {
        // console.log(x);
        if (id) navigate("/talent");
        else navigate("/dashboard");
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const cancelUpdate = () => {
    if (id) navigate("/talent");
    else navigate("/dashboard");
  };

  const loadUserInfo = () => {
    var exec;
    setLoading(true);

    if (id) {
      setformMode(FormMode.Edit);
      setformId(id);
      exec = API.graphql({ query: queries.getUser, variables: { id: id } });
    } else {
      setformMode(FormMode.Edit);
      setformId(userID);
      exec = API.graphql({ query: queries.getUser, variables: { id: userID } });
    }
    exec.then((x) => {
      setVersion(x.data.getUser._version);
      setformFirstName(x.data.getUser.FirstName ?? "");
      setformLastName(x.data.getUser.LastName ?? "");
      setformPhoneNumber(x.data.getUser.PhoneNumber ?? "");
      setformEmail(x.data.getUser.Email ?? "");
      setformExpertise(x.data.getUser.Expertise ?? "");
      setformIndustry(x.data.getUser.Industry ?? "");
      setformPortfolio(x.data.getUser.Portfolio ?? "");
      setformTaxID(x.data.getUser.TaxID ?? "");
      //
      setLoading(false);
    })
    .catch(({ name, message }) => {
      setLoading(false);
      if (message.includes("No current user")) {
        window.location.reload();
      } else {
        console.log("Could not load user detail", name, message);
      }
      // alert("Could not load user detail, check your internet connection")
    });
  };

  /*
  useEffect
  */
  useEffect(() => {
    if (ready) {
      loadUserInfo();
      setTab(0);
    }
  }, [ready, id, formId]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      {/* {id && <Container maxWidth="xl" sx={{ m: 2, ml: 0 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" href="/talent">
            Talent
          </Link>
          <Typography color="text.primary">{formFirstName + ' ' + formLastName}</Typography>
        </Breadcrumbs>
      </Container> */}
      {/* <Dialog
        open={true}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>User Profile</Title>
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.75)", fontSize: "0.9rem" }}
            >
              View or edit a user profile
            </Typography>
          </Stack>
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tab} onChange={handleTabChange} aria-label="tabs">
                <Tab
                  label="General"
                  {...a11yProps(0)}
                  sx={{ textTransform: "none" }}
                />
                {<Tab
                  label="Documents"
                  {...a11yProps(1)}
                  sx={{ textTransform: "none" }}
                />}
              </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
              <Grid
                container
                direction="column"
                alignContent="center"
                justifyContent="center"
                spacing={3}
              >
                <Grid item xs={12} md={8} lg={4}>
                  <UserProfilePicture
                    userID={formId}
                    version={version}
                    readOnly={id ? true : false}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={4}>
                  <TextInput
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    required={true}
                    value={formFirstName}
                    helperText={formFirstNameHelper}
                    error={formFirstNameHelper !== ""}
                    onChange={(val) => {
                      setformFirstName(val.target.value);
                      setFormFirstNameHelper("");
                    }}
                    sx={{ width: 350 }}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={4}>
                  <TextInput
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    required={true}
                    value={formLastName}
                    helperText={formLastNameHelper}
                    error={formLastNameHelper !== ""}
                    onChange={(val) => {
                      setformLastName(val.target.value);
                      setFormLastNameHelper("");
                    }}
                    sx={{ width: 350 }}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <TextInput
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Phone Number"
                    value={formPhoneNumber}
                    helperText={formPhoneNumberHelper}
                    error={
                      formPhoneNumber.trim() &&
                      !validPhoneNumber(formPhoneNumber)
                    }
                    onChange={(val) => {
                      setformPhoneNumber(val.target.value);
                      setformPhoneNumberHelper(phoneNumFormatMsg);
                    }}
                    sx={{ width: 350 }}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <TextInput
                    id="taxID"
                    name="taxID"
                    label="Tax ID (ABN, VAT, etc)"
                    value={formTaxID}
                    onChange={(val) => setformTaxID(val.target.value)}
                    sx={{ width: 350 }}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <TextInput
                    id="email"
                    name="email"
                    label="Email"
                    value={formEmail}
                    disabled={true}
                    onChange={(val) => setformEmail(val.target.value)}
                    sx={{ width: 350 }}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <TextInput
                    id="expertise"
                    name="expertise"
                    label="Expertise"
                    value={formExpertise}
                    onChange={(val) => setformExpertise(val.target.value)}
                    sx={{ width: 350 }}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <TextInput
                    id="industry"
                    name="industry"
                    label="Industry"
                    value={formIndustry}
                    onChange={(val) => setformIndustry(val.target.value)}
                    sx={{ width: 350 }}
                  />
                </Grid>
                <Grid item xs={12} md={8} lg={8}>
                  <TextInput
                    id="portfolio"
                    name="portfolio"
                    label="Portfolio"
                    value={formPortfolio}
                    onChange={(val) => setformPortfolio(val.target.value)}
                    fullWidth
                    // sx={{ width: 500 }}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            {<TabPanel value={tab} index={1}>
              <Grid
                container
                direction="row"
                alignContent="center"
                justifyContent="center"
                spacing={3}
              >
                <Grid item xs={12} md={8} lg={8}>
                  <Typography>Attachments:</Typography>
                  {!loading && (
                    <UserProfileAttachmentUpload
                      userID={formId}
                      fileDescription={ATTACHMENT_TYPE_DRIVERSLICENSE}
                      readOnly={id ? true : false}
                    />
                  )}
                  {!loading && (
                    <UserProfileAttachmentUpload
                      userID={formId}
                      fileDescription={ATTACHMENT_TYPE_WORKERSCOMP}
                      readOnly={id ? true : false}
                    />
                  )}
                  {!loading && (
                    <UserProfileAttachmentUpload
                      userID={formId}
                      fileDescription={ATTACHMENT_TYPE_PUBLICINDEMNITY}
                      readOnly={id ? true : false}
                    />
                  )}
                </Grid>
              </Grid>
            </TabPanel>}
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  sx={{ width: 100, m: 1, p: 2 }}
                  onClick={() => {
                    updateUserInfo();
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  sx={{ width: 100, m: 1, p: 2 }}
                  onClick={() => {
                    cancelUpdate();
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default function UserProfileView() {
  return <UserProfileContent />;
}

import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../components/Title";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { API, graphqlOperation } from "aws-amplify";
import {
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import useAuth from "../components/AuthContextProvider";
import { InvoiceApproval } from "../components/InvoiceApproval";
import { S3Link } from "../components/S3Link";
import { InvoiceUpload } from "../components/InvoiceUpload";
import { InvoiceStatus } from "../components/InvoiceStatus";
import { Container } from "@mui/system";
import {
  listProjectInvoices,
  listProjectInvoicesForTalent,
} from "../graphql/customQueries";
import * as queries from "../graphql/queries";
import { ROUTES } from "../components/routes";
import { useNavigate } from "react-router-dom";
import { graphqlGetAllItems } from "../graphql/functions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function SubRow(props) {
  const { del, accountTypeAgency, accountTypeTalent, inv } = props;
  const [projectTitle, setProjectTitle] = React.useState("");
  const [orgTitle, setOrgTitle] = React.useState("");
  const [deliverableTitle, setDeliverableTitle] = React.useState("");
  const [deliverableBudget, setDeliverableBudget] = React.useState("");
  const navigate = useNavigate();

  const handleInvoiceClick = (event, item) => {
    if (accountTypeAgency) {
      navigate(`/invoice/${item}`);
    }
  };

  const getProjectInfo = async (id) => {
    var exec = await API.graphql({
      query: queries.getProject,
      variables: { id: del.projectID },
    });
    setProjectTitle(exec.data.getProject.Title);
    setOrgTitle(exec.data.getProject.Organisation.Name);
  };
  getProjectInfo(del.projectID);

  const getDeliverableInfo = async (id) => {
    var exec = await API.graphql({
      query: queries.getDeliverable,
      variables: { id: del.deliverableID },
    });
    setDeliverableTitle(exec.data.getDeliverable.Title);
    setDeliverableBudget(exec.data.getDeliverable.Budget);
  };
  getDeliverableInfo(del.deliverableID);

  return (
    <React.Fragment>
      <StyledTableRow
        hover
        key={del.id}
        onDoubleClick={(evt) => handleInvoiceClick(evt, inv.id)}
      >
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell>${del.total}</StyledTableCell>
        <StyledTableCell>{del.hours} hrs</StyledTableCell>
        {accountTypeTalent && <StyledTableCell>{orgTitle}</StyledTableCell>}
        <StyledTableCell sx={{ width: 50 }}>{projectTitle}</StyledTableCell>
        <StyledTableCell sx={{ width: 50 }}>{deliverableTitle}</StyledTableCell>
        <StyledTableCell>${deliverableBudget}</StyledTableCell>
        {accountTypeAgency && <StyledTableCell></StyledTableCell>}
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        {accountTypeAgency && <StyledTableCell></StyledTableCell>}
      </StyledTableRow>
    </React.Fragment>
  );
}

function Row(props) {
  const {
    inv,
    userID,
    talent,
    accountTypeAgency,
    accountTypeTalent,
    invoiceAction,
  } = props;
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [talentName, setTalentName] = React.useState("");
  const deliverables = JSON.parse(inv.Deliverables);

  const handleInvoiceClick = (event, item) => {
    navigate(`/invoice/${item}`);
  };

  const getTalentName = async (id) => {
    var exec = await API.graphql({
      query: queries.getUser,
      variables: { id: id },
    });
    // console.log(exec);
    setTalentName(
      `${exec.data.getUser.FirstName} ${exec.data.getUser.LastName}`
    );
  };
  getTalentName(talent);

  return (
    <React.Fragment>
      <StyledTableRow
        hover
        key={inv.id}
        onDoubleClick={(evt) => handleInvoiceClick(evt, inv.id)}
      >
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell>{inv.invoiceReferenceNumber}</StyledTableCell>
        <StyledTableCell>
          <Stack direction="column">
            <div>${inv.Total}</div>
            <div>{inv.TaxInclusive ? "ex. GST" : "inc. GST"}</div>
          </Stack>
        </StyledTableCell>
        <StyledTableCell>{inv.Hours}</StyledTableCell>
        {accountTypeTalent && <StyledTableCell></StyledTableCell>}
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        <StyledTableCell></StyledTableCell>
        {accountTypeAgency && <StyledTableCell>{talentName}</StyledTableCell>}
        <StyledTableCell>
          <InvoiceStatus status={inv.Status} />
        </StyledTableCell>
        <StyledTableCell>
          {inv.File && <S3Link file={inv.File} />}
        </StyledTableCell>
        {accountTypeAgency && (
          <StyledTableCell>
            <InvoiceApproval inv={inv} fetchInvoices={invoiceAction} />
          </StyledTableCell>
        )}
      </StyledTableRow>

      {open && (
        <>
          {deliverables.length > 0 ? (
            deliverables.map((del) => {
              return (
                <SubRow
                  del={del}
                  accountTypeAgency={accountTypeAgency}
                  accountTypeTalent={accountTypeTalent}
                  inv={inv}
                />
              );
            })
          ) : (
            <StyledTableRow hover>
              <StyledTableCell>
                <>There are no invoices</>
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          )}
        </>
      )}
    </React.Fragment>
  );
}

function InvoicesContent() {
  const [loading, setLoading] = React.useState(true);
  const [projects, setProjects] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [talent, setTalent] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const { ready, userID, userOrgID, accountTypeAgency, accountTypeTalent } =
    useAuth();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchProjects();
  };
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (ready) {
      // fetchTalent();
      // fetchProjects();
      fetchInvoices();
    }
  }, [ready]);

  const fetchProjects = async () => {
    setLoading(true);
    if (accountTypeAgency) {
      try {
        var results = await graphqlGetAllItems(listProjectInvoices, {
          organisationProjectsId: userOrgID,
          sortDirection: "DESC",
        });

        var filtered = results.filter((x) => !x._deleted);
        setProjects(filtered);
        // getCount(filtered);
        // setLoading(false);
        // fetchInvoices(filtered);
      } catch ({ name, message }) {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load projects", name, message);
        }
        // alert("Could not load projects, check your internet connection")
      }
    } else if (accountTypeTalent) {
      try {
        var results = await graphqlGetAllItems(listProjectInvoicesForTalent, {
          talentId: userID,
        });
        setProjects(results);
        // getCount(filtered);
        // setLoading(false);
        // fetchInvoices(filtered);
      } catch ({ name, message }) {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load projects", name, message);
        }
        // alert("Could not load projects, check your internet connection")
      }
    }
    // console.log(result.data.listProjects.items);
  };

  const fetchInvoices = async () => {
    setLoading(true);
    if (accountTypeAgency) {
      try {
        var results = await graphqlGetAllItems(queries.listInvoices, {});
        var filtered = results.filter(
          (x) => !x._deleted && x.Deliverables && x.Custom2 == userOrgID
        );
        setInvoices(getSortedInvoices(filtered));
        setLoading(false);
        getCount(filtered);
      } catch ({ name, message }) {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load invoices", name, message);
        }
        // alert("Could not load projects, check your internet connection")
      }
    } else if (accountTypeTalent) {
      try {
        var results = await graphqlGetAllItems(queries.listInvoices, {
          filter: { owner: { eq: userID } },
        });
        var filtered = results.filter((x) => !x._deleted && x.Deliverables);
        setInvoices(getSortedInvoices(filtered));
        setLoading(false);
        getCount(filtered);
      } catch ({ name, message }) {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load invoices", name, message);
        }
        // alert("Could not load projects, check your internet connection")
      }
    }
  };

  const getCount = (invs) => {
    var c = 0;
    invs.map((inv) => {
      if (inv.Deliverables) {
        // var deliverables = JSON.parse(inv.Deliverables);
        c += 1; //deliverables.length;
      }
    });
    setCount(c);
  };

  const getSortedInvoices = (items) => {
    const statusOrder = {
      SUBMITTED: 0,
      APPROVED: 1,
      REJECTED: 2,
    };

    items.sort((a, b) => {
      const statusA = a.Status.toUpperCase();
      const statusB = b.Status.toUpperCase();

      if (statusOrder[statusA] < statusOrder[statusB]) {
        return -1;
      }
      if (statusOrder[statusA] > statusOrder[statusB]) {
        return 1;
      }
      return 0;
    });
    return items;
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      {/* <InvoiceUpload selectProject open={open} handleClose={handleClose} /> */}
      <Box
        sx={{
          mb: 2,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>Invoices</Title>
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.75)", fontSize: "0.9rem" }}
            >
              View the list invoices for all projects
            </Typography>
          </Stack>
          {accountTypeTalent && (
            <Button
              variant="contained"
              sx={{ m: 1, textTransform: "none" }}
              onClick={() => navigate(ROUTES.addinvoice)}
            >
              Add Invoice
            </Button>
          )}
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>Reference</StyledTableCell>
                    <StyledTableCell>Total</StyledTableCell>
                    <StyledTableCell>Hours</StyledTableCell>
                    {accountTypeTalent && (
                      <StyledTableCell>Agency</StyledTableCell>
                    )}
                    <StyledTableCell>Project</StyledTableCell>
                    <StyledTableCell>Deliverable</StyledTableCell>
                    <StyledTableCell>Deliverable Budget</StyledTableCell>
                    {accountTypeAgency && (
                      <StyledTableCell>Talent/Supplier</StyledTableCell>
                    )}
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>File</StyledTableCell>
                    {accountTypeAgency && (
                      <StyledTableCell>Approval</StyledTableCell>
                    )}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {invoices &&
                    invoices
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((inv) => {
                        return (
                          <Row
                            key={inv.id}
                            inv={inv}
                            userID={userID}
                            accountTypeAgency={accountTypeAgency}
                            accountTypeTalent={accountTypeTalent}
                            talent={inv.invoiceTalentId}
                            invoiceAction={() => window.location.reload()}
                          />
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default function Invoices() {
  return <InvoicesContent />;
}

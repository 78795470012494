import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { Stack } from "@mui/system";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as models from "../models";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useAuth from "../components/AuthContextProvider";
import { listTalents } from "../graphql/customQueries";
import {
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
  Breadcrumbs,
  Link,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { FormMode } from "../globals";
import TextInput from "../components/TextInput";
import Title from "../components/Title";

const getProjectDetails = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      Title
      Overview
      Deadline
      ClientBudget
      Budget
    }
  }
`;

function DeliverableContent() {
  const [loading, setLoading] = React.useState(true);
  const [formMode, setformMode] = useState(FormMode.New);
  const [version, setVersion] = useState();
  const [formBudget, setformBudget] = useState(0);
  const [projID, setProjID] = useState("");
  const [formProjectTitle, setformProjectTitle] = useState("");
  const [formTitle, setformTitle] = useState("");
  const [formTitleHelper, setFormTitleHelper] = useState("");
  const [formBrief, setformBrief] = useState("");
  const [formBriefHelper, setFormBriefHelper] = useState("");
  const [formDeadline, setformDeadline] = useState("");
  const [formDeadlineHelper, setFormDeadlineHelper] = useState("");
  const [formAllocateAmount, setformAllocateAmount] = useState("");
  const [formAllocateAmountHelper, setFormAllocateAmountHelper] = useState("");
  const [formAllocate, setformAllocate] = useState("");
  const [formResource, setformResource] = useState("");
  const [proposals, setProposals] = useState([]);
  const [talent, setTalent] = useState([]);
  const [checked, setChecked] = React.useState([]);
  const { id, projectID } = useParams();
  const { ready, userID, userOrgID, accountTypeAgency, accountTypeTalent } =
    useAuth();
  const navigate = useNavigate();

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const fetchTalent = async () => {
    setLoading(true);
    const result = API.graphql({
      query: listTalents,
      variables: { filter: { talentOrganisationId: { eq: userOrgID } } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var filtered = val.data.listTalents.items.filter((x) => !x._deleted);
        setTalent(filtered);
      })
      .catch(({ name, message }) => {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load talent", name, message);
        }
        // alert("Could not load talent, check your internet connection")
      });
  };

  const processProposals = (props) => {
    var talSelList = [];
    props.items.map((prop) => {
      if (!prop._deleted && prop.proposalTalentId) {
        talSelList.push(prop.proposalTalentId);
      }
    });
    setChecked(talSelList);
  };

  const processInvitations = () => {
    checked.map((x) => {
      // if the proposal hasn't been deleted and a proposal already exists for the checked talent
      if (
        proposals.items.find(
          (pro) => !pro._deleted && x === pro.proposalTalentId
        )
      ) {
        // console.log(x, 'will NOT be added');
      } else {
        // console.log(x, 'will be added');
        addNewProposal(x);
      }
    });
  };

  const addNewProposal = (talentID) => {
    var tal = talent.find((x) => x.User.id === talentID);

    const newProposal = {
      owner: userID,
      Amount: 0,
      Scope: "",
      Approved: 0,
      Status: models.ProposalStatus.SENT,
      // projectID: projID,
      deliverableID: id,
      proposalTalentId: talentID,
      ToEmail: tal.User.Email,
    };

    const exec = API.graphql({
      query: mutations.createProposal,
      variables: { input: newProposal },
    });
    exec
      .then((x) => {
        console.log(x);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const getProject = (id) => {
    setProjID(id);
    const exec = API.graphql({
      query: getProjectDetails,
      variables: { id: id },
    });
    exec
      .then(async (x) => {
        setformProjectTitle(x.data.getProject.Title);
        setLoading(false);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const getProjectAll = (id) => {
    setProjID(id);
    const exec = API.graphql({
      query: getProjectDetails,
      variables: { id: id },
    });
    exec
      .then(async (x) => {
        setformProjectTitle(x.data.getProject.Title);
        setformBrief(x.data.getProject.Overview);
        setformDeadline(x.data.getProject.Deadline);
        setformBudget(x.data.getProject.Budget);
        setResourceBudgetAmountOnLoad(
          x.data.getProject.Budget,
          x.data.getProject.Budget
        );
        setLoading(false);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  useEffect(() => {
    if (ready) {
      fetchTalent();
      loadExistingDeliverable();
    }
  }, [ready, id, projectID]);

  const loadExistingDeliverable = () => {
    if (id) {
      if (accountTypeAgency) setformMode(FormMode.Edit);
      const exec = API.graphql({
        query: queries.getDeliverable,
        variables: { id: id },
      });
      exec
        .then((x) => {
          setVersion(x.data.getDeliverable._version);
          setformTitle(x.data.getDeliverable.Title);
          setformBrief(x.data.getDeliverable.Brief);
          setformDeadline(x.data.getDeliverable.Deadline);
          setformResource(x.data.getDeliverable.Resource);
          setProposals(x.data.getDeliverable.Proposals);
          // setResourceBudgetPercent(x.data.getDeliverable.Budget);
          setformBudget(x.data.getDeliverable.Budget);
          setformAllocateAmount(x.data.getDeliverable.Budget);
          setformAllocate(x.data.getDeliverable.Allocated);
          setProjID(x.data.getDeliverable.projectID);
          getProject(x.data.getDeliverable.projectID);
          //
          processProposals(x.data.getDeliverable.Proposals);
          setLoading(false);
        })
        .catch(({ name, message }) => {
          setLoading(false);
          if (message.includes("No current user")) {
            window.location.reload();
          } else {
            console.log("Could not load deliverable", name, message);
          }
          // alert("Could not load proposals, check your internet connection")
        });
    } else {
      setVersion("");
      setformTitle("");
      setformBrief("");
      // setResourceBudgetAmount('');
      setformDeadline("");
      setformResource(null);
      getProjectAll(projectID);
    }
  };

  /*
  updateDeliverable
  */
  const updateDeliverable = () => {
    var resource;
    var resourceStatus;
    if (formResource == null) {
      resource = null;
      resourceStatus = models.ResourceStatus.NOT_ASSIGNED;
    } else {
      resource = formResource.id;
      resourceStatus = models.ResourceStatus.ASSIGNED;
    }

    if (!validateForm()) {
      return;
    }

    const deliverable = {
      id: id,
      _version: version,
      Title: formTitle,
      Brief: formBrief,
      Deadline: formDeadline,
      Budget: formAllocateAmount,
      Allocated: formAllocate,
      deliverableResourceId: resource,
      ResourceStatus: resourceStatus,
      Custom3: null
    };

    const exec = API.graphql({
      query: mutations.updateDeliverable,
      variables: { input: deliverable },
    });
    exec
      .then((x) => {
        //console.log(x);
        processInvitations();
        //navigate(`/deliverable/${x.data.updateDeliverable.id}`);
        navigate(`/project/${projID}/deliverables`);
      })
      .catch((x) => {
        console.log(x);
      });
  };
  /*
  deleteProject
  */
  const deleteDeliverable = () => {
    const deliverable = {
      id: id,
      _version: version,
    };
    const exec = API.graphql({
      query: mutations.deleteDeliverable,
      variables: { input: deliverable },
    });
    exec
      .then((x) => {
        // console.log(x);
        //navigate(`/deliverable/${x.data.updateDeliverable.id}`);
        navigate(-1);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  /*
  addNewDeliverable
  */
  const addNewDeliverable = () => {
    if (!validateForm()) {
      return;
    }

    const newDeliverable = {
      owner: userID,
      projectID: projectID,
      Title: formTitle,
      Brief: formBrief,
      Deadline: formDeadline,
      Budget: formAllocateAmount,
      Allocated: formAllocate,
      ResourceStatus: models.ResourceStatus.NOT_ASSIGNED,
    };

    const exec = API.graphql({
      query: mutations.createDeliverable,
      variables: { input: newDeliverable },
    });
    exec
      .then((x) => {
        // console.log(x);
        navigate(`/deliverable/${x.data.createDeliverable.id}`);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const validateForm = () => {
    // form validation
    let formValid = true;
    if (formTitle.trim() === "") {
      setFormTitleHelper("Title can't be empty.");
      formValid = false;
    }
    if (formBrief.trim() === "") {
      setFormBriefHelper("Brief can't be empty.");
      formValid = false;
    }
    if (formDeadline.trim() === "") {
      setFormDeadlineHelper("Deadline can't be empty.");
      formValid = false;
    }

    if (parseFloat(formAllocateAmount) <= 0 || parseFloat(formAllocate) <= 0) {
      setFormAllocateAmountHelper("Budget can't be empty.");
      formValid = false;
    }

    return formValid;
  };

  const setResourceBudgetPercent = (val) => {
    if (val > 100) {
      val = 100;
    } else if (val < 0) {
      val = 0;
    }
    setformAllocate(val);
    setformAllocateAmount(((val / 100.0) * formBudget).toFixed(2));
  };

  const setResourceBudgetAmount = (val) => {
    if (val > formBudget) {
      val = formBudget;
    } else if (val < 0) {
      val = 0;
    }
    setformAllocateAmount(val);
    setformAllocate(((val / formBudget) * 100.0).toFixed(0));
  };

  const setResourceBudgetAmountOnLoad = (val, budget) => {
    if (val > budget) {
      val = budget;
    } else if (val < 0) {
      val = 0;
    }
    setformAllocateAmount(val);
    setformAllocate(((val / budget) * 100.0).toFixed(0));
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      <Container maxWidth="xl" sx={{ m: 2, ml: 0 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" href="/projects">
            Projects
          </Link>
          <Link underline="hover" href={`/project/${projID}/deliverables`}>
            {formProjectTitle}
          </Link>
          {formMode == FormMode.New && (
            <Typography color="text.primary">New Deliverable</Typography>
          )}
          {formMode == FormMode.Edit && (
            <Typography color="text.primary">{formTitle}</Typography>
          )}
        </Breadcrumbs>
      </Container>
      {/* <Dialog
      open={true}
      aria-labelledby="confirm-dialog-title"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog-title">
        {"Are you sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          Are you sure?
        </DialogContentText>
      </DialogContent>
    </Dialog> */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>{formTitle}</Title>
          </Stack>
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            // style={{ minHeight: '100vh' }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid container direction="row" justifyContent="center" spacing={3}>
              <Grid item xs={12} md={8} lg={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextInput
                      disabled={accountTypeTalent}
                      required
                      id="Title"
                      // name=""
                      label="Title"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> </InputAdornment>
                        ),
                      }}
                      value={formTitle}
                      onChange={(val) => {
                        setformTitle(val.target.value);
                        setFormTitleHelper("");
                      }}
                      error={formTitleHelper !== ""}
                      helperText={formTitleHelper}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextInput
                      disabled={accountTypeTalent}
                      required
                      id="Brief"
                      // name=""
                      label="Brief"
                      fullWidth
                      multiline
                      rows={4}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> </InputAdornment>
                        ),
                      }}
                      value={formBrief}
                      onChange={(val) => {
                        setformBrief(val.target.value);
                        setFormBriefHelper("");
                      }}
                      error={formBriefHelper !== ""}
                      helperText={formBriefHelper}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextInput
                      disabled={accountTypeTalent}
                      required
                      id="deadline"
                      // name=""
                      label="Deadline"
                      type="date"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">Date</InputAdornment>
                        ),
                      }}
                      value={formDeadline}
                      onChange={(val) => {
                        setformDeadline(val.target.value);
                        setFormDeadlineHelper("");
                      }}
                      error={formDeadlineHelper !== ""}
                      helperText={formDeadlineHelper}
                    />
                  </Grid>
                  <Grid item xs={8} md={12} lg={12}>
                    <Stack direction="row" spacing={2}>
                      <TextInput
                        disabled={accountTypeTalent}
                        required
                        id="allocateAmount"
                        name="allocateAmount"
                        label="Budget ($)"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> </InputAdornment>
                          ),
                        }}
                        value={formAllocateAmount}
                        type="number"
                        onChange={(val) => {
                          setResourceBudgetAmount(val.target.value);
                          setFormAllocateAmountHelper("");
                        }}
                        error={formAllocateAmountHelper !== ""}
                        helperText={formAllocateAmountHelper}
                      />
                      {accountTypeAgency && (
                        <TextInput
                          disabled={accountTypeTalent}
                          required
                          id="allocate"
                          name="allocate"
                          label="Budget (%)"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {" "}
                              </InputAdornment>
                            ),
                          }}
                          value={formAllocate}
                          type="number"
                          onChange={(val) =>
                            setResourceBudgetPercent(val.target.value)
                          }
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              {accountTypeAgency && formMode == FormMode.Edit && (
                <Grid item xs={12} md={8} lg={6}>
                  <Box sx={{ mb: 3 }}>
                    {formResource && (
                      <TextInput
                        id="resource"
                        name="resource"
                        label="Assigned To Talent"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"> </InputAdornment>
                          ),
                        }}
                        value={
                          formResource
                            ? formResource.FirstName +
                              " " +
                              formResource.LastName
                            : ""
                        }
                        // onChange={(val) => setformInvite(val.target.value)}
                        sx={{ width: "20rem", mr: 2, mb: 2 }}
                      />
                    )}
                    {!formResource && (
                      <FormControl>
                        <InputLabel id="resource">
                          Assign to a Talent now
                        </InputLabel>
                        <Select
                          labelId="selectResource"
                          id="selectResource"
                          value={formResource}
                          label="Resource"
                          onChange={(val) => setformResource(val.target.value)}
                          sx={{ width: "20rem" }}
                        >
                          {talent &&
                            talent.map((tal) => {
                              if (!tal._deleted) {
                                return (
                                  <MenuItem key={tal.id} value={tal.User}>
                                    {tal.User.FirstName} {tal.User.LastName}
                                  </MenuItem>
                                );
                              }
                            })}
                        </Select>
                      </FormControl>
                    )}
                    {formResource && (
                      <Button
                        variant="contained"
                        sx={{ p: 2 }}
                        onClick={() => {
                          setformResource(null);
                        }}
                      >
                        Unassign
                      </Button>
                    )}
                  </Box>
                  {/* above is to select from the org's talent pool, below is to invite a new talent */}
                  <Divider light={true} sx={{ color: "grey  " }}>
                    OR
                  </Divider>
                  <Box>
                    <Typography
                      sx={{
                        ml: 2,
                        color: "rgba(0, 0, 0, 0.75)",
                        fontSize: "0.8rem",
                      }}
                    >
                      Invite Talents to submit proposal
                    </Typography>
                    <Box
                      sx={{
                        width: 300,
                        height: 120,
                        overflow: "auto",
                        border: "1px solid rgba(0, 0, 0, 0.25)",
                        borderRadius: "5px",
                      }}
                    >
                      <List dense component="div" role="list">
                        {talent.map((value) => {
                          if (!value._deleted) {
                            const labelId = `transfer-list-item-${value.User.id}-label`;
                            return (
                              <ListItem
                                key={value.User.id}
                                role="listitem"
                                button
                                onClick={handleToggle(value.User.id)}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    checked={
                                      checked.indexOf(value.User.id) !== -1
                                    }
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                      "aria-labelledby": labelId,
                                    }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  id={labelId}
                                  primary={`${value.User.FirstName} ${value.User.LastName}`}
                                />
                              </ListItem>
                            );
                          }
                        })}
                        <ListItem />
                      </List>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {accountTypeAgency && formMode == FormMode.New && (
                  <Button
                    variant="contained"
                    sx={{ width: 100, m: 1, p: 2 }}
                    onClick={() => addNewDeliverable()}
                  >
                    Add
                  </Button>
                )}
                {accountTypeAgency && formMode == FormMode.Edit && (
                  <Button
                    variant="contained"
                    sx={{ width: 100, m: 1, p: 2 }}
                    onClick={() => updateDeliverable()}
                  >
                    Update
                  </Button>
                )}
                {accountTypeAgency && formMode == FormMode.Edit && (
                  <Button
                    variant="contained"
                    sx={{ width: 100, m: 1, p: 2 }}
                    onClick={() => {
                      deleteDeliverable();
                    }}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  variant="contained"
                  sx={{ width: 100, m: 1, p: 2 }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default function DeliverableView() {
  return <DeliverableContent />;
}

import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import { DRAWER_LIST } from "./menu";
import MenuItem from "./MenuItem";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useDrawerContext } from "../components/DrawerContext";
import useAuth from "../components/AuthContextProvider";
import { MENU_ACCESS } from "./constants";
import { Analytics } from "aws-amplify";

const MenuItemsList = () => {
  const {
    user,
    userID,
    firstName,
    accountTypeAgency,
    accountTypeTalent,
    login,
  } = useAuth();
  const { pathname } = useLocation();
  const { isOpened, toggleIsOpened } = useDrawerContext();
  const navigate = useNavigate();

  const menuAccessible = (access) => {
    return (
      (accountTypeAgency &&
        (access == MENU_ACCESS.AGENCY ||
          access == MENU_ACCESS.AGENCY_AND_TALENT)) ||
      (accountTypeTalent &&
        (access == MENU_ACCESS.TALENT ||
          access == MENU_ACCESS.AGENCY_AND_TALENT))
    );
  };

  const sendEvent = (name) => {
    Analytics.record({
      name,
    });
  };

  return (
    <Grid>
      <List sx={{ p: 0 }}>
        <ListItem
          button
          sx={{
            "&.Mui-selected": {
              backgroundColor: "lightBlue",
              color: "black",
            },
            "&:hover": {
              backgroundColor: "primary.light",
              color: "black",
            },
            justifyContent: "center",
          }}
          onClick={() => toggleIsOpened(!isOpened)}
        >
          <ListItemIcon
            sx={{ color: "black", minWidth: "auto" }}
            className="introtour-step-open-side-menu"
          >
            {isOpened ? <ChevronLeftIcon /> : <MenuIcon />}
          </ListItemIcon>
        </ListItem>
      </List>
      <List sx={{ p: 0 }}>
        {DRAWER_LIST.map(({ literal, route, className, Icon, access }) => {
          if (menuAccessible(access)) {
            return (
              <div className={className} key={route}>
                <MenuItem
                  Icon={Icon}
                  literal={literal}
                  route={route}
                  key={route}
                  selected={pathname === route}
                  onClick={() => {
                    sendEvent(`MenuItem_${route}Click`);
                    navigate(route);
                  }}
                />
              </div>
            );
          }
        })}
      </List>
    </Grid>
  );
};

export default MenuItemsList;

import * as React from 'react';
import { useCallback, useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../components/Copyright';
import { Auth } from 'aws-amplify';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useParams, useNavigate } from 'react-router-dom';
import useAuth from '../components/AuthContextProvider';
import { API, graphqlOperation } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import logo from '../logo1.png';
import TextInput from '../components/TextInput';

export default function SignUpVerify() {
  const [loading, setLoading] = React.useState(true);
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const navigate = useNavigate();
  const { id } = useParams();
  const { login } = useAuth();

  const signin = () => {
    login().then(() => {
      navigate('/dashboard');
    });
  }

  const handleSubmit = (event) => {
    setLoading(true);
    setErrorMsg('');
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      verificationCode: data.get('verificationCode'),
    });
    try {
      const resp = Auth.confirmSignUp(id, data.get('verificationCode'));
      resp.then((val) => {
        console.log(val);
        setErrorMsg(val);

        if (val.toString().startsWith("SUCCESS")) {
          // handle success
          setDialogVisible(true);
          setTimeout(() => { signin() }, 2000);
        }
        else {
          // handle errors
          const err = val.toString();
          const desc = err.split(':')[1];
          setErrorMsg(desc);
          if (err.startsWith("CodeMismatchException")) {
            // wrong code
          }
          else if (err.startsWith("NotAuthorizedException")) {
            // already confirmed
          }
          else if (err.includes("Exception")) {
            // Unknown exception
          }
        }
      });
    } catch (error) {
      setErrorMsg(error);
      console.log('error confirming sign up', error);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        p: 1,
        caretColor: 'transparent'
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ m: 2 }}>
              <img width="100px" src={logo} alt="Logo" />
            </Box>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Typography sx={{ mt: 3 }}>
              * indicates a required field
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  Please enter the verification code you received via email
                  <TextInput
                    name="verificationCode"
                    required
                    fullWidth
                    id="verificationCode"
                    label="Verification Code"
                    autoFocus
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 5, mb: 12 }}>
                <div style={{ position: 'relative' }}>
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, height: '60px' }}
                    >
                      Verify
                    </Button>
                  </div>
                </div>
              </Box>
              <Box>
                {errorMsg && errorMsg.length > 0 &&
                  <div>
                    {/* <Typography sx={{ mt: 1, color: 'red' }}>
                  The verification code you entered is incorrect.
                </Typography> */}
                    <Typography sx={{ mt: 2, mb: 3, color: 'red' }}>
                      {errorMsg}
                    </Typography>
                  </div>
                }
              </Box>
            </Box>
          </Box>
          <Dialog
            open={dialogVisible}
            aria-labelledby="success-dialog-title"
            aria-describedby="success-dialog-description"
          >
            <DialogTitle id="success-dialog-title">
              {"Your account has been created"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="success-dialog-description">
                Welcome onboard, let's get started!
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </Box>
    </Box>
  );
}
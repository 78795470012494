import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "../components/Title";
import SearchIcon from "@mui/icons-material/Search";
import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as models from "../models";
import useAuth from "../components/AuthContextProvider";
import {
  Chip,
  Container,
  Grid,
  IconButton,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import { ClientAdd } from "../components/ClientAdd";
import TextInput from "../components/TextInput";
import axios from "axios";
import AlertDialog from "../components/AlertDialog";
import { XeroIntegration } from "../globals";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function ClientsContent() {
  const [loading, setLoading] = React.useState(true);
  const [clients, setClients] = useState([]);
  const [page, setPage] = React.useState(0);
  const [searchString, setSearchString] = useState("");
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [currentToken, setCurrentToken] = useState(); // pagination follows https://dev.to/onlybakam/implementing-pagination-with-aws-appsync-5d08
  const [nextToken, setNextToken] = useState();
  const [tokens, setTokens] = useState([]);
  const [fetchMode, setFetchMode] = useState("FETCH"); // when switch between search and list, need to reset pages
  const {
    ready,
    userID,
    userOrgID,
    email,
    accountTypeAgency,
    accountTypeTalent,
  } = useAuth();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    fetchClients();
  };
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alert, setAlert] = useState({});
  const [importing, setImporting] = React.useState(false);
  const [xeroLink, setXeroLink] = useState({ linkState: "" });

  const openAlert = (title, description) => {
    setAlert({ title, description });
    setAlertOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage === page) {
      return;
    }
    setPage(newPage);
    if (page > newPage) {
      // backward
      setCurrentToken(tokens.pop());
      setTokens([...tokens]);
      setNextToken(null);
    } else {
      // forward
      setTokens((v) => [...v, currentToken]);
      setCurrentToken(nextToken);
      setNextToken(null);
    }
  };

  const resetPage = () => {
    setPage(0);
    setCurrentToken(null);
    setTokens([]);
    setNextToken(null);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resetPage();
  };

  useEffect(() => {
    if (!ready) {
      return;
    }
    if (!searchString) {
      fetchClients();
    } else {
      // only fire search after user stop typing
      const timer = setTimeout(() => {
        searchClients();
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }

    getXeroLink();
  }, [ready, currentToken, rowsPerPage, searchString]);

  const getXeroLink = () => {
    const result = API.graphql({
      query: queries.listOAuthLinks,
      variables: {
        filter: {
          AppName: { eq: XeroIntegration.AppName },
          organisationOAuthLinksId: { eq: userOrgID },
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var filtered = val.data.listOAuthLinks.items.filter((x) => !x._deleted);
        // console.log(filtered);
        if (filtered.length > 0) setXeroLink(filtered[0]);
        setLoading(false);
      })
      .catch(({ name, message }) => {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load integrations", name, message);
        }
        // alert("Could not load integrations, check your internet connection")
      });
  };

  const importFromXero = async () => {
    try {
      setImporting(true);
      let config = {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "OPTIONS, POST",
          "Access-Control-Allow-Headers":
            "Access-Control-Allow-Origin,Access-Control-Allow-Methods,Access-Control-Allow-Headers,Content-Type",
        },
      };
      let data = {
        userID,
        userOrgID,
      };
      const response = await axios.post(
        `https://x69bj9sw77.execute-api.ap-southeast-2.amazonaws.com/default/Xero_GetClientList`,
        data,
        config
      );
      //console.log(response.data);
      openAlert("Xero Client Import", "Import was completed successfully.");
      // window.location.reload();
      setImporting(false);
      fetchClients();
    } catch (err) {
      console.log(err);
    }
  };

  const fetchClients = () => {
    setLoading(true);
    if (fetchMode !== "FETCH") {
      resetPage();
      setFetchMode("FETCH");
    }
    const result = API.graphql({
      query: queries.listClientsByOrg,
      variables: {
        organisationClientsId: userOrgID,
        sortDirection: "DESC",
        limit: rowsPerPage,
        nextToken: currentToken,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var filtered = val.data.listClientsByOrg.items.filter(
          (x) => !x._deleted
        );
        setClients(filtered);
        setNextToken(val.data.listClientsByOrg.nextToken);
        setLoading(false);
      })
      .catch(({ name, message }) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        }
        else {
          console.log("Could not load clients", name, message);
        }
        // alert("Could not load invitations, check your internet connection");
      });
  };

  const searchClients = () => {
    if (!searchString) {
      return;
    }

    setLoading(true);
    if (fetchMode !== "SEARCH") {
      resetPage();
      setFetchMode("SEARCH");
    }
    const result = API.graphql({
      query: queries.searchClients,
      variables: {
        limit: rowsPerPage,
        nextToken: currentToken,
        filter: {
          organisationClientsId: { eq: userOrgID },
          Name: { wildcard: `*${searchString}*` },
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var filtered = val.data.searchClients.items.filter((x) => !x._deleted);
        setClients(filtered);
        setNextToken(val.data.searchClients.nextToken);
        setLoading(false);
      })
      .catch(({ name, message }) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load clients", name, message);
        }
        // alert("Could not load clients, check your internet connection")
      });
};

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      {alertOpen && (
        <AlertDialog
          close={() => setAlertOpen(false)}
          title={alert.title}
          description={alert.description}
        />
      )}
      <Box
        sx={{
          mb: 2,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>Clients</Title>
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.75)", fontSize: "0.9rem" }}
            >
              View the list of clients
            </Typography>
          </Stack>
          {accountTypeAgency && (
            <div>
              {/* Xero Integration */}
              {xeroLink.linkState === models.OAuthLinkState.CONNECTED && (
                <Button
                  variant="contained"
                  sx={{ m: 1, textTransform: "none" }}
                  onClick={() => {
                    importFromXero();
                  }}
                  disabled={importing}
                >
                  Import from Xero
                  {importing && (
                    <CircularProgress
                      size={16}
                      sx={{ ml: 1, color: green[500] }}
                    />
                  )}
                </Button>
              )}
              <Button
                variant="contained"
                sx={{ m: 1, textTransform: "none" }}
                onClick={() => {
                  handleOpen();
                }}
              >
                Add Client
              </Button>
            </div>
          )}
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <ClientAdd userID={userID} userOrgID={userOrgID} open={open} handleClose={handleClose} />
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                border: 1,
                borderRadius: 1,
                borderColor: "darkgrey",
              }}
            >
              <IconButton sx={{ p: "10px" }} aria-label="menu">
                <SearchIcon />
              </IconButton>
              <TextInput
                sx={{
                  outline: "none",
                  width: "400px",
                  "& fieldset": { border: "none" },
                }}
                value={searchString}
                onChange={(val) => setSearchString(val.target.value)}
                placeholder="Search client"
              />
            </Box>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={-1} // to use server side pagination, see MUI doc
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{ disabled: !nextToken }}
            ></TablePagination>
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Address</StyledTableCell>
                  <StyledTableCell>Primary Contact</StyledTableCell>
                  <StyledTableCell>Phone Number</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {clients.length > 0 ? (
                  clients.map((cli) => {
                    return (
                      <StyledTableRow key={cli.id}>
                        <StyledTableCell>
                          <Stack direction="column">
                            {cli.Name}{" "}
                            {cli.id.startsWith("xero") ? (
                              <Chip
                                label="XERO"
                                color="primary"
                                size="small"
                                sx={{ width: "100px" }}
                              />
                            ) : (
                              <></>
                            )}
                          </Stack>
                        </StyledTableCell>
                        <StyledTableCell>{cli.Address}</StyledTableCell>
                        <StyledTableCell>{cli.PrimaryContact}</StyledTableCell>
                        <StyledTableCell>{cli.PhoneNumber}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <StyledTableRow>
                    <StyledTableCell>No clients to display</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </Box>
  );
}

export default function Clients() {
  return <ClientsContent />;
}

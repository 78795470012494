import Header from "./Header";
import Drawer from "./Drawer.js";
import { Outlet } from "react-router-dom";
import { DrawerContextProvider } from "./DrawerContext";
import Box from "@mui/material/Box";
import Footer from "./Footer";

const Layout = () => {
  return (
    <DrawerContextProvider>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Header />
        <Box
          sx={{
            display: "flex",
            flex: 1,
            backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900]
          }}
        >
          <Drawer />
          <main style={{ flex: 1 }}><Outlet /></main>
        </Box>
        <Footer />
      </Box>
    </DrawerContextProvider>
  );
};

export default Layout;

import * as React from 'react';
import Copyright from '../components/Copyright';
import { Box, useTheme } from "@mui/material";

const Footer = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        // background: theme.palette.primary.dark,
        // color: theme.palette.secondary.light,
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, 0.12)', 
        caretColor: 'transparent'
      }}
    >
       <Copyright />
    </Box>
  );
};

export default Footer;

import * as React from 'react';
import { useCallback, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import * as models from '../models';
import useAuth from './AuthContextProvider';

export function InvitationApproval({ inv, fetchInvitations }) {
  const { userID } = useAuth();

  const addContract = () => {
    const contract = {
      owner: inv.From.id,
      Status: models.ContractStatus.UNDEFINED
    };

    const exec = API.graphql({ query: mutations.createContract, variables: { input: contract } });
    exec.then((x) => {
      console.log(x);
      addTalent(x.data.createContract);
    }).catch((err) => {
      console.log("Could not add contract", err);
    });
  }

  const addTalent = (contract) => {
    const talent = {
      owner: inv.From.id,
      talentOrganisationId: inv.From.id,
      talentUserId: userID,
      talentContractId: contract.id
    };

    const exec = API.graphql({ query: mutations.createTalent, variables: { input: talent } });
    exec.then((x) => {
      console.log(x);
    }).catch((err) => {
      console.log("Could not add talent", err);
    });
  }

  const approveInvite = () => {
    const invite = {
      id: inv.id,
      _version: inv._version,
      Status: models.InvitationStatus.ACCEPTED,
    };

    const exec = API.graphql({ query: mutations.updateInvitation, variables: { input: invite } });
    exec.then((x) => {
      // console.log(x);
      addContract();
      fetchInvitations();
    }).catch((x) => {
      console.log(x);
    });
  }

  const declineInvite = () => {
    const invite = {
      id: inv.id,
      _version: inv._version,
      Status: models.InvitationStatus.REJECTED,
    };

    const exec = API.graphql({ query: mutations.updateInvitation, variables: { input: invite } });
    exec.then((x) => {
      // console.log(x);
      fetchInvitations();
    }).catch((x) => {
      console.log(x);
    });
  }

  const deleteInvite = () => {
    const invite = {
      id: inv.id,
      _version: inv._version
    };

    const exec = API.graphql({ query: mutations.deleteInvitation, variables: { input: invite } });
    exec.then((x) => {
      console.log(x);
      fetchInvitations();
    }).catch((err) => {
      console.log("Could not delete invitation", err);
    });
  }
  return (
    <>
      {/* <IconButton color="success" sx={{ border: 1, m: 1 }} onClick={() => { approveInvite() }}>
        <DoneIcon />
      </IconButton>
      <IconButton color="warning" sx={{ border: 1, m: 1 }} onClick={() => { declineInvite() }}>
        <ClearIcon />
      </IconButton> */}
      {inv.Status == models.InvitationStatus.SENT ? <>
        <IconButton color="success" sx={{ border: 1, m: 1 }} onClick={() => { approveInvite() }}>
          <DoneIcon />
        </IconButton>
        <IconButton color="warning" sx={{ border: 1, m: 1 }} onClick={() => { declineInvite() }}>
          <ClearIcon />
        </IconButton>
      </> :
        <>
          <IconButton color="error" sx={{ border: 1, m: 1 }} onClick={() => { deleteInvite() }}>
            <DeleteIcon />
          </IconButton>
        </>}
    </>
  );
}
import * as React from "react";
import Chip from "@mui/material/Chip";
import * as models from "../models";
import useAuth from "../components/AuthContextProvider";
import { Tooltip } from "@mui/material";

export function ProposalStatus({ status }) {
  const { accountTypeAgency, accountTypeTalent } = useAuth();

  if (accountTypeAgency && status == models.ProposalStatus.SENT) {
    return (
      <Tooltip
        placement="top"
        title="Your invitation to submit a proposal has been sent to the Talent"
      >
        <Chip label="Sent" color="primary" />
      </Tooltip>
    );
  } else if (accountTypeTalent && status == models.ProposalStatus.SENT) {
    return (
      <Tooltip
        placement="top"
        title="You have received an invitation to submit a proposal"
      >
        <Chip label="Received" color="primary" />
      </Tooltip>
    );
  } else if (status == models.ProposalStatus.ACCEPTED) {
    return (
      <Tooltip
        placement="top"
        title="The Talent has accepted your invitation to submit a proposal"
      >
        <Chip label="Accepted" color="success" />
      </Tooltip>
    );
  } else if (status == models.ProposalStatus.DECLINED) {
    return (
      <Tooltip
        placement="top"
        title="The Talent has declined your invitation for proposal"
      >
        <Chip label="Declined" color="error" />
      </Tooltip>
    );
  } else if (status == models.ProposalStatus.SUBMITTED) {
    return (
      <Tooltip placement="top" title="Talent has submitted a proposal">
        <Chip label="Submitted" color="primary" />
      </Tooltip>
    );
  } else if (status == models.ProposalStatus.APPROVED) {
    return (
      <Tooltip placement="top" title="You have accepted the proposal">
        <Chip label="Approved" color="success" />
      </Tooltip>
    );
  } else if (status == models.ProposalStatus.REJECTED) {
    return (
      <Tooltip placement="top" title="You have rejected the proposal">
        <Chip label="Rejected" color="error" />
      </Tooltip>
    );
  }

  return <div>(err)</div>;
}

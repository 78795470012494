export const ROUTES = {
  dashboard: "/dashboard",
  projects: "/projects",
  project: "/project/:id/general",
  projectDeliverables: "/project/:id/deliverables",
  projectAdd: "/project/add",
  deliverable: "/deliverable/:id",
  deliverableAdd: "/deliverable/add/:projectID",
  organization: "/organisation",
  organizationIntegrations: "/organisation/integrations",
  clients: "/clients",
  agencies: "/agencies",
  talent: "/talent",
  contract: "/contract/:id",
  contractRet: "/contract/:id/:ret",
  proposals: "/proposals",
  proposalView: "/proposal/:id",
  budget: "/budget",
  addinvoice: "/addinvoice",
  invoices: "/invoices",
  invoiceView: "/invoice/:id",
  invitations: "/invitations",
  settings: "/settings",
  subscription: "/subscription",
  profile: "/profile",
  profileView: "/profile/:id",
  xeroLink: "/xerolink",
};

import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "../components/Copyright";
import { Auth } from "aws-amplify";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as models from "../models/index";
import logo from "../logo1.png";
import TextInput from "../components/TextInput";
import useAuth from "../components/AuthContextProvider";

async function Submit(
  username,
  password,
  given_name,
  family_name,
  phone_number,
  accountType
) {
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        given_name,
        family_name,
        phone_number,
        "custom:AccountType": accountType,
      },
      autoSignIn: {
        // optional - enables auto sign in after user is confirmed
        enabled: true,
      },
    });
    console.log(user);
    return user;
  } catch (error) {
    // console.log('error signing up:', error);
    return error;
  }
}

export default function SignUp() {
  const phoneNumFormatMsg = "Please use mobile format +61XXXXXXXXXX";
  const passwordPolicyMsg =
    "Please enter a minimum of 8 characters containing at least 1 uppercase, 1 lowercase, 1 number, and 1 symbol.";

  const [loading, setLoading] = React.useState(false);
  const [accountType, setAccountType] = React.useState("");
  const [dialogVisible, setDialogVisible] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [errorUsername, setErrorUsername] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState(false);
  const [errorPhoneNum, setErrorPhoneNum] = React.useState(false);
  const [helperPhoneNum, setHelperPhoneNum] = React.useState(phoneNumFormatMsg);
  const [helperUsername, setHelperEmail] = React.useState("");
  const [helperPassowrd, setHelperPassword] = React.useState(passwordPolicyMsg);
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const navigate = useNavigate();
  const { authed } = useAuth();

  useEffect(() => {
    if (authed) {
      navigate("/dashboard");
    }
  }, [authed]);

  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
  };

  const handleTermsAcceptedChange = (event) => {
    setTermsAccepted(event.target.checked);
  };

  const nextStep = (email) => {
    navigate(`/signupverify/${email}`);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setErrorMsg("");
    setErrorPhoneNum(false);
    setTermsAccepted(true);
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    //   firstName: data.get('firstName'),
    //   lastName: data.get('lastName'),
    //   phoneNum: data.get('phoneNum'),
    // });

    // Amplify doesn't check length of mobile, have to do it ourself
    if (!/^\+61(0)?[1-9]\d{8}$/.test(data.get("phoneNum"))) {
      setErrorPhoneNum(true);
      setHelperPhoneNum(phoneNumFormatMsg);
      setLoading(false);
      return;
    }

    if (!termsAccepted) {
      setTermsAccepted(false);
      setErrorMsg("Please accept the terms and conditions.");
      setLoading(false);
      return;
    }

    const resp = Submit(
      data.get("email"),
      data.get("password"),
      data.get("firstName"),
      data.get("lastName"),
      data.get("phoneNum"),
      accountType
    );
    resp.then((val) => {
      setLoading(false);
      // console.log(val);
      if (val.toString().startsWith("[object")) {
        // handle success
        // console.log("Success!", val.username);
        // setDialogVisible(true);
        // setTimeout(() => {nextStep()}, 2000);
        nextStep(data.get("email"));
      } else {
        // handle errors
        const err = val.toString();
        const desc = err.split(":")[1].trim(); // use trim to remove the leading space char, easy for following process
        setErrorMsg(desc);
        if (err.startsWith("InvalidPasswordException")) {
          setErrorPassword(true);
          setHelperPassword("weak password." + passwordPolicyMsg);
        } else if (err.startsWith("InvalidParameterException")) {
          if (desc.includes("phone number")) {
            // example: Invalid phone number format.
            setErrorPhoneNum(true);
            setHelperPhoneNum(`Incorrect format. ${helperPhoneNum}`);
          } else if (desc.startsWith("Username")) {
            // example: Username should be an email.
            setErrorUsername(true);
            setHelperEmail("Incorrect Email.");
          }
        } else if (err.startsWith("UsernameExistsException")) {
          setErrorUsername(true);
          setHelperEmail(desc);
        } else if (err.includes("Exception")) {
          // Unknown exception
        }
      }
    });
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ m: 2 }}>
              <img width="100px" src={logo} alt="Logo" />
            </Box>
            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Typography sx={{ mt: 3 }}>* indicates a required field</Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    required
                    fullWidth
                    error={errorPhoneNum}
                    id="phoneNum"
                    label="Mobile Phone Number"
                    name="phoneNum"
                    autoComplete="phone-number"
                    defaultValue="+61"
                    onChange={() => {
                      setErrorPhoneNum(false);
                      setHelperPhoneNum(phoneNumFormatMsg);
                    }}
                    helperText={helperPhoneNum}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    required
                    fullWidth
                    error={errorUsername}
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={() => {
                      setErrorUsername(false);
                      setHelperEmail("");
                    }}
                    helperText={helperUsername}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    required
                    fullWidth
                    error={errorPassword}
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    onChange={() => {
                      setErrorPassword(false);
                      setHelperPassword(passwordPolicyMsg);
                    }}
                    helperText={helperPassowrd}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl>
                    <FormLabel id="accountType">
                      Select the type of account *
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="accountType-group"
                      defaultValue="agency"
                      name="accountType-group"
                      value={accountType}
                      onChange={handleAccountTypeChange}
                    >
                      <FormControlLabel
                        value="AGENCY"
                        control={<Radio />}
                        label="Agency"
                      />
                      <FormControlLabel
                        value="TALENT"
                        control={<Radio />}
                        label="Freelancer"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox value="allowExtraEmails" color="primary" />
                    }
                    label="I want to receive inspiration, marketing promotions and updates via email."
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    required
                    control={<Checkbox value="acceptTerms" color="primary" />}
                    label={
                      <Typography>
                        I have read the{" "}
                        <Link href="https://www.coflowx.com/privacy">
                          privacy policy
                        </Link>{" "}
                        and accept the{" "}
                        <Link href="https://www.coflowx.com/terms">
                          terms and conditions
                        </Link>
                        . *
                      </Typography>
                    }
                    onChange={handleTermsAcceptedChange}
                    checked={termsAccepted}
                  />
                </Grid>
              </Grid>
              <Box sx={{ mt: 5, mb: 12 }}>
                <div style={{ position: "relative" }}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, height: "60px" }}
                    >
                      Sign Up
                    </Button>
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {loading && (
                      <CircularProgress
                        size={36}
                        sx={{
                          color: green[500],
                          // left: '50%',
                          // marginTop: '30px',
                          // marginLeft: '-12px',
                        }}
                      />
                    )}
                  </div>
                </div>
              </Box>
              <Box>
                {errorMsg && errorMsg.length > 0 && (
                  <div>
                    <Typography sx={{ mt: 1, color: "red" }}>
                      There were errors in data you entered, please review the
                      information below:
                    </Typography>
                    <Typography sx={{ mt: 2, mb: 3, color: "red" }}>
                      {errorMsg}
                    </Typography>
                  </div>
                )}
              </Box>
              <Grid sx={{ mt: 5 }} container justifyContent="flex-end">
                <Grid item>
                  <Link href="/signin" variant="body2">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* <Dialog
        open={dialogVisible}
        aria-labelledby="success-dialog-title"
        aria-describedby="success-dialog-description"
      >
        <DialogTitle id="success-dialog-title">
          {"Your account has been created"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="success-dialog-description">
            Welcome onboard, let's get started!
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </Box>
    </Box>
  );
}

import * as React from "react";
import { useCallback, useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as models from "../models";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import useAuth from "../components/AuthContextProvider";
import { ProposalStatus } from "../components/ProposalStatus";
import {
  Breadcrumbs,
  CircularProgress,
  Container,
  Link,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { ProposalApproval } from "../components/ProposalApproval";
import { Stack } from "@mui/system";
import { FormMode } from "../globals";
import TextInput from "../components/TextInput";
import Title from "../components/Title";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // for snow theme

const StyledTextField = styled(TextField)(({ theme }) => ({
  "&.Mui-disabled": {
    color: "red",
  },
}));

function ProposalContent() {
  const [loading, setLoading] = React.useState(true);
  const [formMode, setformMode] = useState(FormMode.New);
  const [version, setVersion] = useState();
  const [formBrief, setformBrief] = useState("");
  const [formScope, setformScope] = useState("");
  const [formAmount, setformAmount] = useState("");
  const [formApproved, setformApproved] = useState("");
  const [formStatus, setformStatus] = useState("");
  const [formTalent, setformTalent] = useState("");
  const [formProject, setformProject] = useState("");
  const [formDeliverable, setformDeliverable] = useState("");
  const [formReadOnly, setformReadOnly] = useState(false);
  const [proposal, setProposal] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const { ready, userID, accountTypeAgency, accountTypeTalent } = useAuth();

  const getProject = (id) => {
    const exec = API.graphql({
      query: queries.getProject,
      variables: { id: id },
    });
    exec.then((x) => {
      setformProject(x.data.getProject.Title);
      setLoading(false);
    });
  };

  const getDeliverable = (id) => {
    const exec = API.graphql({
      query: queries.getDeliverable,
      variables: { id: id },
    });
    exec.then((x) => {
      setformDeliverable(x.data.getDeliverable.Title);
      setformBrief(x.data.getDeliverable.Brief);
      getProject(x.data.getDeliverable.projectID);
    });
  };

  const updateProposal = () => {
    if (accountTypeAgency) {
      const proposal = {
        id: id,
        _version: version,
        Approved: formApproved,
      };

      const exec = API.graphql({
        query: mutations.updateProposal,
        variables: { input: proposal },
      });
      exec
        .then((x) => {
          console.log(x);
          navigate("/proposals");
        })
        .catch((x) => {
          console.log(x);
        });
    } else if (accountTypeTalent) {
      const proposal = {
        id: id,
        _version: version,
        Scope: formScope,
        Amount: formAmount,
      };

      const exec = API.graphql({
        query: mutations.updateProposal,
        variables: { input: proposal },
      });
      exec
        .then((x) => {
          console.log(x);
          navigate("/proposals");
        })
        .catch((x) => {
          console.log(x);
        });
    }
  };

  const loadExistingProposal = () => {
    if (id) {
      setformMode(FormMode.Edit);
      const exec = API.graphql({
        query: queries.getProposal,
        variables: { id: id },
      });
      exec
        .then((x) => {
          setVersion(x.data.getProposal._version);
          setformScope(x.data.getProposal.Scope);
          setformAmount(x.data.getProposal.Amount);
          setformApproved(x.data.getProposal.Approved);
          setformStatus(x.data.getProposal.Status);
          setformTalent(x.data.getProposal.Talent);
          setProposal(x.data.getProposal);
          getDeliverable(x.data.getProposal.deliverableID);
          if (accountTypeAgency) setformReadOnly(true);
          else if (accountTypeTalent)
            setformReadOnly(
              x.data.getProposal.Status === models.ProposalStatus.SUBMITTED ||
                x.data.getProposal.Status === models.ProposalStatus.APPROVED
            );
        })
        .catch(({ name, message }) => {
          setLoading(false);
          if (message.includes("No current user")) {
            window.location.reload();
          } else {
            console.log("Could not load proposal detail", name, message);
          }
          // alert("Could not load proposal detail, check your internet connection")
        });
    }
  };

  /*
  useEffect
  */
  useEffect(() => {
    if (ready) {
      loadExistingProposal();
    }
  }, [ready, id]);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      {/* <Container maxWidth="xl" sx={{ m: 2, ml: 0 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" href="/proposals">
            Proposals
          </Link>
          <Typography color="text.primary">View Proposal</Typography>
        </Breadcrumbs>
      </Container> */}
      {/* <Dialog
        open={true}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure?
          </DialogContentText>
        </DialogContent>
      </Dialog> */}
      <Box
        sx={{
          mb: 4,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>{formDeliverable}</Title>
          </Stack>
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 1,
                mb: 3,
              }}
            >
              <TextInput
                id="project"
                name="project"
                label="Project"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"> </InputAdornment>
                  ),
                }}
                value={formProject}
                readonly
                sx={{ width: "60vw", caretColor: "transparent", m: 1 }}
              />
              <TextInput
                id="brief"
                name="brief"
                label="Brief"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"> </InputAdornment>
                  ),
                }}
                value={formBrief}
                readonly
                sx={{ width: "60vw", caretColor: "transparent", m: 1 }}
              />
            </Box>
            <Grid container direction="row" justifyContent="center" spacing={3}>
              <Grid item xs={12} md={8} lg={8}>
                <Grid container spacing={3}>
                  <Grid item>
                    <Typography
                      sx={{
                        ml: 2,
                        color: "rgba(0, 0, 0, 0.75)",
                        fontSize: "0.8rem",
                      }}
                    >
                      Status
                    </Typography>
                    <ProposalStatus status={formStatus} />
                  </Grid>
                  <Grid item>
                    {accountTypeAgency && (
                      <Typography
                        sx={{
                          ml: 2,
                          color: "rgba(0, 0, 0, 0.75)",
                          fontSize: "0.8rem",
                        }}
                      >
                        Approval
                      </Typography>
                    )}
                    {accountTypeTalent && (
                      <Typography
                        sx={{
                          ml: 2,
                          color: "rgba(0, 0, 0, 0.75)",
                          fontSize: "0.8rem",
                        }}
                      >
                        Acceptance
                      </Typography>
                    )}
                    {proposal && (
                      <ProposalApproval
                        pro={proposal}
                        fetchProposals={loadExistingProposal}
                      />
                    )}
                  </Grid>
                  {accountTypeAgency && (
                    <Grid item xs={8} md={8} lg={12}>
                      <TextInput
                        id="talent"
                        name="talent"
                        label="Talent"
                        value={`${formTalent.FirstName} ${formTalent.LastName}`}
                        disabled
                        // sx={{ width: 400 }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={12} lg={12}>
                    <ReactQuill
                      theme="snow"
                      value={formScope}
                      onChange={(val) => setformScope(val)}
                    />
                  </Grid>
                  <Grid item xs={4} md={4} lg={8}>
                    <TextInput
                      id="amount"
                      name="amount"
                      label="Amount"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      type="number"
                      value={formAmount}
                      disabled={formReadOnly}
                      onChange={(val) => setformAmount(val.target.value)}
                      // sx={{ width: 160 }}
                    />
                  </Grid>
                  <Grid item xs={4} md={4} lg={8}>
                    <TextInput
                      id="approved"
                      name="approved"
                      label="Approved"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      type="number"
                      value={formApproved}
                      disabled={accountTypeTalent}
                      onChange={(val) => setformApproved(val.target.value)}
                      // sx={{ width: 160 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ mt: 4 }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  sx={{ width: 100, m: 1, p: 2 }}
                  onClick={() => {
                    updateProposal();
                  }}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  sx={{ width: 100, m: 1, p: 2 }}
                  onClick={() => {
                    navigate("/proposals");
                  }}
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default function ProposalView() {
  return <ProposalContent />;
}

import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Container } from "@mui/system";
import Title from "../components/Title";
import { green } from "@mui/material/colors";
import { API, graphqlOperation } from "aws-amplify";
import * as models from "../models";
import useAuth from "../components/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import * as queries from "../graphql/queries";
import { listProjectInvoices } from "../graphql/customQueries";
import { CSVExporter } from "../components/CSVExporter";
import InfoIcon from "@mui/icons-material/Info";
import { graphqlGetAllItems } from "../graphql/functions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function BudgetContent() {
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [projects, setProjects] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [budgetList, setBudgetList] = useState([]);
  const { ready, userID, userOrgID, accountTypeAgency, accountTypeTalent } =
    useAuth();
  // const navigate = useNavigate();

  useEffect(() => {
    if (ready) {
      fetchProjects();
    }
  }, [ready]);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      var results = await graphqlGetAllItems(listProjectInvoices, {
        organisationProjectsId: userOrgID,
        sortDirection: "DESC",
      });
      setProjects(results);
      // setLoading(false);
      // generateBudgetReport(filtered);
      fetchInvoices(results);
    } catch ({ name, message }) {
      setLoading(false);
      if (message.includes("No current user")) {
        window.location.reload();
      } else {
        console.log("Could not load projects", name, message);
      }
      // alert(
      //   "Could not load project invoices, check your internet connection"
      // );
    }
  };

  const fetchInvoices = async (projs) => {
    try {
      var results = await graphqlGetAllItems(queries.listInvoices, {});
      setInvoices(results);
      setLoading(false);
      generateBudgetReport(projs, results);
    } catch ({ name, message }) {
      setLoading(false);
      if (message.includes("No current user")) {
        window.location.reload();
      } else {
        console.log("Could not load invoices", name, message);
      }
      // alert("Could not load projects, check your internet connection")
    }
  };

  const getInvoicesTotal = (invs, prj) => {
    var sum = 0;
    invs.map((inv) => {
      if (inv.Deliverables) {
        var deliverables = JSON.parse(inv.Deliverables);
        deliverables.map((deliv) => {
          if (deliv.projectID === prj.id && inv.Status != "REJECTED") {
            sum += Number(deliv.subTotal);
          }
        });
      }
    });
    return sum;
  };

  const getDelivarablesAllocated = (prj) => {
    var sum = 0;
    prj.Deliverables.items
      .filter((x) => !x._deleted)
      .map((del) => {
        sum += del.Allocated;
      });
    return sum;
  };

  const getDelivarablesAllocatedAmount = (prj) => {
    var sum = 0;
    prj.Deliverables.items
      .filter((x) => !x._deleted)
      .map((del) => {
        sum += del.Budget;
      });
    return sum;
  };

  const getApprovedInvoicesTotal = (invs, prj) => {
    var sum = 0;
    invs.map((inv) => {
      if (inv.Deliverables) {
        var deliverables = JSON.parse(inv.Deliverables);
        deliverables.map((deliv) => {
          if (inv.Status == models.InvoiceStatus.APPROVED) {
            sum += Number(deliv.subTotal);
          }
        });
      }
    });
    return sum;
  };

  const generateBudgetReport = (projs, invs) => {
    var budList = [];
    projs.map((prj) => {
      const bud = [];
      bud["id"] = prj.id;
      bud["Project"] = prj.Title;
      bud["ClientBudget"] = prj.ClientBudget;
      bud["Budget"] = prj.Budget;
      bud["Allocated"] = getDelivarablesAllocated(prj);
      bud["AllocatedAmount"] = getDelivarablesAllocatedAmount(prj);
      bud["Balance"] = bud["Budget"] - bud["AllocatedAmount"];
      bud["ProfitForecastAmount"] =
        bud["ClientBudget"] - bud["AllocatedAmount"];
      bud["ProfitForecast"] =
        ((bud["ClientBudget"] - bud["AllocatedAmount"]) / bud["ClientBudget"]) *
        100.0;
      bud["Invoiced"] = getInvoicesTotal(invs, prj);
      bud["BalanceActual"] = bud["Budget"] - bud["Invoiced"];
      bud["Approved"] = getApprovedInvoicesTotal(invs, prj);
      // bud["Outstanding"] = bud["Budget"] - bud["Approved"];
      bud["ProfitAmount"] = bud["ClientBudget"] - bud["Invoiced"];
      bud["Profit"] =
        100.0 -
        ((bud["ClientBudget"] - bud["ProfitAmount"]) / bud["ClientBudget"]) *
          100.0;
      budList.push(bud);
    });
    setBudgetList(budList);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      <Box
        sx={{
          mb: 2,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>Budget</Title>
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.75)", fontSize: "0.9rem" }}
            >
              View a report of the budget for all projects
            </Typography>
          </Stack>
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={budgetList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Stack direction="column">
                        <div>Project</div>
                        <div style={{ padding: 5, color: "#000000" }}>
                          Project
                        </div>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="column" alignItems="flex-start">
                        <div style={{ textAlign: "left" }}>Client Fee</div>
                        <Tooltip title="Client's fee for the project">
                          <IconButton color="primary">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="column" alignItems="flex-start">
                        <div style={{ textAlign: "left" }}>Budgeted</div>
                        <Tooltip title="Internal budget">
                          <IconButton color="primary">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="column" alignItems="flex-start">
                        <div style={{ textAlign: "left" }}>Allocated</div>
                        <Tooltip title="Total amount allocated to talent">
                          <IconButton color="primary">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="column" alignItems="flex-start">
                        <div style={{ textAlign: "left" }}>Balance</div>
                        <Tooltip title="Budgeted amount minus allocated">
                          <IconButton color="primary">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="column" alignItems="flex-start">
                        <div style={{ textAlign: "left" }}>
                          Profit (Forecast)
                        </div>
                        <Tooltip title="Client budget minus allocated">
                          <IconButton color="primary">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="column" alignItems="flex-start">
                        <div style={{ textAlign: "left" }}>All Invoices</div>
                        <Tooltip title="Total of all invoiced amount">
                          <IconButton color="primary">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </StyledTableCell>
                    {false && (
                      <StyledTableCell>
                        <Stack direction="column" alignItems="flex-start">
                          <div style={{ textAlign: "left" }}>
                            Approved Invoices
                          </div>
                          <Tooltip title="Total of all approved invoiced amount">
                            <IconButton color="primary">
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </StyledTableCell>
                    )}
                    <StyledTableCell>
                      <Stack direction="column" alignItems="flex-start">
                        <div style={{ textAlign: "left" }}>
                          Balance (Actual)
                        </div>
                        <Tooltip title="Internal budget minus invoices">
                          <IconButton color="primary">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Stack direction="column" alignItems="flex-start">
                        <div style={{ textAlign: "left" }}>Profit (Actual)</div>
                        <Tooltip title="Client budget minus invoices">
                          <IconButton color="primary">
                            <InfoIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {budgetList &&
                    budgetList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((bud) => (
                        <StyledTableRow key={bud.id}>
                          <StyledTableCell>{bud.Project}</StyledTableCell>
                          <StyledTableCell>
                            $
                            {bud.ClientBudget.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                            })}
                          </StyledTableCell>
                          <StyledTableCell>
                            $
                            {bud.Budget.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                            })}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack direction="column">
                              <div>
                                $
                                {bud.AllocatedAmount.toLocaleString(undefined, {
                                  maximumFractionDigits: 0,
                                })}
                              </div>
                              <div>
                                (
                                {bud.Allocated.toLocaleString(undefined, {
                                  maximumFractionDigits: 0,
                                })}
                                %)
                              </div>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            $
                            {bud.Balance.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                            })}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack
                              direction="column"
                              sx={{
                                color:
                                  bud.ProfitForecastAmount >= 0
                                    ? "green"
                                    : "red",
                              }}
                            >
                              <div>
                                $
                                {bud.ProfitForecastAmount.toLocaleString(
                                  undefined,
                                  { maximumFractionDigits: 0 }
                                )}
                              </div>
                              <div>
                                (
                                {bud.ProfitForecast.toLocaleString(undefined, {
                                  maximumFractionDigits: 0,
                                })}
                                %)
                              </div>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell>
                            $
                            {bud.Invoiced.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                            })}
                          </StyledTableCell>
                          {false && (
                            <StyledTableCell>
                              $
                              {bud.Approved.toLocaleString(undefined, {
                                maximumFractionDigits: 0,
                              })}
                            </StyledTableCell>
                          )}
                          <StyledTableCell>
                            $
                            {bud.BalanceActual.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                            })}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Stack
                              direction="column"
                              sx={{
                                color: bud.ProfitAmount >= 0 ? "green" : "red",
                              }}
                            >
                              <div>
                                $
                                {bud.ProfitAmount.toLocaleString(undefined, {
                                  maximumFractionDigits: 0,
                                })}
                              </div>
                              <div>
                                (
                                {bud.Profit.toLocaleString(undefined, {
                                  maximumFractionDigits: 0,
                                })}
                                %)
                              </div>
                            </Stack>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default function Budget() {
  return <BudgetContent />;
}

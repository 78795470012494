import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Title from "../components/Title";
import { API, graphqlOperation } from "aws-amplify";
import { Container } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useAuth from "../components/AuthContextProvider";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SubscriptionViewContent() {
  const { userOrgID, email } = useAuth();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // fetchClients();
  }, []);

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        p: 1,
      }}
    >
      <Container maxWidth="xl">
        <Box
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">
            Pick the best plan for your business
          </Typography>
          <Stack direction="row" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              style={{ borderRadius: "50px" }}
              onClick={() => setValue(0)}
              sx={{ m: 1, fontSize: 20, textTransform: "none" }}
              color={value == 0 ? "error" : "primary"}
            >
              Monthly
            </Button>
            <Button
              variant="contained"
              style={{ borderRadius: "50px" }}
              onClick={() => setValue(1)}
              sx={{ m: 1, fontSize: 20, textTransform: "none" }}
              color={value == 1 ? "error" : "primary"}
            >
              Yearly
            </Button>
          </Stack>
          <Box
            visibility="hidden"
            sx={{ height: 0, borderBottom: 1, borderColor: "divider" }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Item One" {...a11yProps(0)} />
              <Tab label="Item Two" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Stack direction="row">
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "20vw"
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Free
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  $0
                </Typography>
                <Typography sx={{ my: 2 }}>up to 5 projects</Typography>
                <Typography sx={{ my: 2 }}>unlimited freelancers</Typography>
                <Typography sx={{ my: 2 }}>etc...</Typography>
                <Button variant="contained" sx={{ my: 2, textTransform: 'none' }}>
                  Subscribe
                </Button>
              </Box>
              <Box
                sx={{
                  mx: 8,
                  mt: 2,
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: "black",
                }}
              ></Box>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "20vw"
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Pro
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  $9pm
                </Typography>
                <Typography sx={{ my: 2 }}>unlimited projects</Typography>
                <Typography sx={{ my: 2 }}>unlimited freelancers</Typography>
                <Typography sx={{ my: 2 }}>etc...</Typography>
                <stripe-buy-button
                  buy-button-id="buy_btn_1Nv2N9HiiZVd3q7MJe1geMWA"
                  publishable-key="pk_test_51NuOX8HiiZVd3q7MN8fWZVSI8mj9Eb8Tw4QqZDX56RdAYe15av4pkPI6zvBQ9sgKrlDFhijVgQwPoeo1P6o4Adwr00TLhoZNXD"
                  client-reference-id={userOrgID}
                  customer-email={email}
                ></stripe-buy-button>
              </Box>
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Stack direction="row">
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "20vw"
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Free
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  $0
                </Typography>
                <Typography sx={{ my: 2 }}>up to 5 projects</Typography>
                <Typography sx={{ my: 2 }}>unlimited freelancers</Typography>
                <Typography sx={{ my: 2 }}>etc...</Typography>
                <Button variant="contained" sx={{ my: 2, textTransform: 'none' }}>
                  Subscribe
                </Button>
              </Box>
              <Box
                sx={{
                  mx: 8,
                  mt: 2,
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderColor: "black",
                }}
              ></Box>
              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "20vw"
                }}
              >
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  Pro
                </Typography>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  $7pm
                </Typography>
                <Typography sx={{ my: 2 }}>unlimited projects</Typography>
                <Typography sx={{ my: 2 }}>unlimited freelancers</Typography>
                <Typography sx={{ my: 2 }}>etc...</Typography>
                <Button variant="contained" sx={{ my: 2, textTransform: 'none' }}>
                  Subscribe
                </Button>
              </Box>
            </Stack>
          </CustomTabPanel>
        </Box>
      </Container>
    </Box>
  );
}

export default function SubscriptionView() {
  return <SubscriptionViewContent />;
}

import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useFilePicker } from "use-file-picker";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";
import useAuth from "../components/AuthContextProvider";
import TextInput from "./TextInput";
import { graphqlGetAllItems } from "../graphql/functions";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid grey",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  caretColor: "transparent",
};

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $talentId: ID
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Organisation {
          Name
          Email
        }
        Title
        Deliverables(filter: { deliverableResourceId: { eq: $talentId } }) {
          items {
            id
            Title
            deliverableResourceId
          }
        }
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

export function InvoiceUpload({ selectProject, projectID, open, handleClose }) {
  const [loading, setLoading] = React.useState(true);
  const [projects, setProjects] = useState([]);
  const { ready, userID, userOrgID, accountTypeAgency, accountTypeTalent } =
    useAuth();
  const [deliverables, setDeliverables] = useState([]);
  const [formProject, setformProject] = useState(projectID ? projectID : "");
  const [formProjectHelper, setFormProjectHelper] = useState("");
  const [formDeliverable, setformDeliverable] = useState("");
  const [formDeliverableHelper, setformDeliverableHelper] = useState("");
  const [formTotal, setformTotal] = useState(0);
  const [formTotalHelper, setFormTotalHelper] = useState("");
  const [formSubTotal, setformSubTotal] = useState(0);
  const [formSubTotalHelper, setformSubTotalHelper] = useState("");
  const [formInvoiceRef, setformInvoiceRef] = useState("");
  const [formInvoiceRefHelper, setformInvoiceRefHelper] = useState("");
  const [formGST, setformGST] = useState(true);
  const [formHours, setformHours] = useState(0);
  const [formHoursHelper, setformHoursHelper] = useState("");
  const [formSupplier, setFormSupplier] = useState("");
  const [formSupplierHelper, setFormSupplierHelper] = useState("");
  const [
    openFileSelector,
    { filesContent, uploading, errors, plainFiles, clear },
  ] = useFilePicker({
    // multiple: true,
    readAs: "ArrayBuffer", // available formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: [".pdf"], // accepted file extensions
    limitFilesConfig: { min: 1, max: 1 }, // number of files
    // minFileSize: 1, // in megabytes
    maxFileSize: 10, // in megabytes
    // readFilesContent: false, // ignores file content
  });

  const setProject = (proj) => {
    setformProject(proj);
    fetchDeliverables(proj);
  };

  useEffect(() => {
    if (ready) {
      fetchProjects();
    }
  }, [ready]);

  const fetchProjects = async () => {
    setLoading(true);
    if (accountTypeAgency) {
      try {
        var results = await graphqlGetAllItems(queries.listProjectsByOrg, {
          organisationProjectsId: userOrgID,
          sortDirection: "DESC",
        });
        setProjects(results);
        setLoading(false);
      } catch ({ name, message }) {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load projects", name, message);
        }
        // alert("Could not load projects, check your internet connection")
      }
    } else if (accountTypeTalent) {
      try {
        var results = await graphqlGetAllItems(listProjects, {
          talentId: userID,
        });
        var filtered = results.filter((prj) => prj.Deliverables.items.length > 0);
        setProjects(filtered);
        setLoading(false);
        // fetchDeliverables();
      } catch ({ name, message }) {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load projects", name, message);
        }
        // alert("Could not load projects, check your internet connection")
      }
    }
  };

  const fetchDeliverables = (id) => {
    setLoading(true);
    const filter = { projectID: { eq: id } };
    if (accountTypeTalent) {
      filter.deliverableResourceId = { eq: userID };
    }
    const result = API.graphql({
      query: queries.listDeliverables,
      variables: { filter: filter },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var filtered = val.data.listDeliverables.items.filter(
          (x) => !x._deleted
        );
        console.log(filtered);
        setDeliverables(filtered);
        setLoading(false);
      })
      .catch(({ name, message }) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load deliverables", name, message);
        }
        // alert("Could not load deliverables, check your internet connection")
      });
  };

  const validateForm = () => {
    var valid = true;

    if (formProject.trim() === "") {
      setFormProjectHelper("Project can't be empty");
      valid = false;
    }
    if (formDeliverable.trim() === "") {
      setformDeliverableHelper("Deliverable can't be empty.");
      valid = false;
    }
    if (formInvoiceRef.trim() === "") {
      setformInvoiceRefHelper("Deliverable can't be empty.");
      valid = false;
    }
    if (accountTypeTalent) {
      if (typeof formHours === "string" && formHours.trim() === "") {
        setformHoursHelper("Hours can't be empty");
        valid = false;
      } else if (!(parseFloat(formHours) >= 0)) {
        setformHoursHelper("Hours must be non-negative number");
        valid = false;
      }
      if (typeof formSubTotal === "string" && formSubTotal.trim() === "") {
        setformSubTotalHelper("Sub Total can't be empty");
        valid = false;
      } else if (!(parseFloat(formSubTotal) >= 0)) {
        // use not >=0 instead of <0, to cover parseFloat() returns NaN which always false compare to numbers
        setformSubTotalHelper("Sub Total must be non-negative number");
        valid = false;
      }
    }
    if (accountTypeAgency) {
      if (formSupplier.trim() === "") {
        setFormSupplierHelper("Supplier can't be empty");
        valid = false;
      }
      if (typeof formTotal === "string" && formTotal.trim() === "") {
        setFormTotalHelper("Total can't be empty");
        valid = false;
      } else if (!(parseFloat(formTotal) > 0)) {
        // use ! >0 instead of <=0, to cover parseFloat() returns NaN which always false compare to numbers
        setFormTotalHelper("Total must be postive number");
        valid = false;
      }
    }

    return valid;
  };

  /*
  addNewInvoice
  */
  const addNewInvoice = (fileKey) => {
    if (!validateForm()) {
      return;
    }

    let toEmail = projects.find((x) => x.id == formProject).Organisation.Email;
    const newInvoice = {
      owner: userID,
      ToEmail: toEmail,
      deliverableID: formDeliverable,
      invoiceTalentId: accountTypeAgency ? "" : userID,
      Total: formTotal,
      Hours: formHours,
      Status: accountTypeAgency ? "APPROVED" : "SUBMITTED",
      Type: accountTypeAgency ? "SUPPLIER" : "TALENT",
      Supplier: formSupplier,
      invoiceReferenceNumber: formInvoiceRef,
      File: fileKey,
    };
    const exec = API.graphql({
      query: mutations.createInvoice,
      variables: { input: newInvoice },
    });
    exec
      .then((x) => {
        console.log(x);
        handleClose();
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const uploadFile = async () => {
    console.log("Uploading...");
    // console.log(filesContent[0]);
    const blob = new Blob([filesContent[0].content]); //, { type: "application/octet-stream;charset=utf-8" });
    // saveFile(blob, filesContent[0].name);
    // return;
    const filename = `public/Invoices/${formDeliverable}/${Date.now()}_${
      filesContent[0].name
    }`;
    const upload = Storage.put(filename, blob, {
      resumable: true,
      completeCallback: (event) => {
        console.log(`Successfully uploaded ${event.key}`);
        let eventKey = filename;
        console.log(eventKey);
        addNewInvoice(eventKey);
      },
      progressCallback: (progress) => {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
      errorCallback: (err) => {
        console.error("Unexpected error while uploading", err);
      },
    });
  };

  const updateGST = (val) => {
    formGST ? setformTotal((val * 1.1).toFixed(2)) : setformTotal(val);
  };

  const updateGSTCheckbox = (event) => {
    setformGST(event.target.checked);
    event.target.checked
      ? setformTotal((formSubTotal * 1.1).toFixed(2))
      : setformTotal(formSubTotal);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={3}>
          {selectProject && (
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="project">Project</InputLabel>
                <Select
                  id="project"
                  label="Project"
                  value={formProject}
                  error={formProjectHelper !== ""}
                  onChange={(val) => {
                    setProject(val.target.value);
                    setFormProjectHelper("");
                  }}
                >
                  {projects &&
                    projects.map((prj) => {
                      {
                        /* set a key for each MenuItem see https://react.dev/learn/rendering-lists#keeping-list-items-in-order-with-key */
                      }
                      return (
                        <MenuItem value={prj.id} key={prj.id}>
                          {prj.Title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <FormHelperText error={formProjectHelper !== ""}>
                {formProjectHelper}
              </FormHelperText>
            </Grid>
          )}
          {selectProject && (
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel id="deliverable">Deliverable</InputLabel>
                <Select
                  id="deliverable"
                  label="Deliverable"
                  value={formDeliverable}
                  error={formDeliverableHelper !== ""}
                  disabled={!formProject}
                  onChange={(val) => {
                    setformDeliverable(val.target.value);
                    setformDeliverableHelper("");
                  }}
                >
                  {deliverables &&
                    deliverables.map((del) => {
                      return (
                        <MenuItem value={del.id} key={del.id}>
                          {del.Title}
                        </MenuItem>
                      );
                    })}
                </Select>
                <FormHelperText error={formDeliverableHelper !== ""}>
                  {formDeliverableHelper}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}

          {accountTypeTalent && (
            <Grid item xs={12}>
              <TextInput
                required
                id="Hours"
                // name=""
                label="Hours"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"> </InputAdornment>
                  ),
                }}
                type="number"
                value={formHours}
                helperText={formHoursHelper}
                error={formHoursHelper !== ""}
                onChange={(val) => {
                  setformHours(val.target.value);
                  setformHoursHelper("");
                }}
              />
            </Grid>
          )}
          {accountTypeTalent && (
            <Grid item xs={12}>
              <TextInput
                required
                id="subTotal"
                // name=""
                label="Sub Total (excl. GST)"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                type="number"
                value={formSubTotal}
                helperText={formSubTotalHelper}
                error={formSubTotalHelper !== ""}
                onChange={(val) => {
                  setformSubTotal(val.target.value);
                  setformSubTotalHelper("");
                  updateGST(val.target.value);
                }}
              />
            </Grid>
          )}
          {accountTypeTalent && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formGST}
                    onChange={(event) => updateGSTCheckbox(event)}
                  />
                }
                label="GST"
              />
            </Grid>
          )}
          {accountTypeTalent && formGST && (
            <Grid item xs={12}>
              <TextInput
                required
                id="total"
                // name=""
                label="Total (incl. GST)"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                type="number"
                value={formTotal}
                // onChange={(val) => setformTotal(val.target.value)}
              />
            </Grid>
          )}

          {accountTypeAgency && (
            <Grid item xs={12}>
              <TextInput
                required
                fullWidth
                id="supplier"
                label="Supplier"
                value={formSupplier}
                onChange={(val) => setFormSupplier(val.target.value)}
              />
            </Grid>
          )}
          {accountTypeAgency && (
            <Grid item xs={12}>
              <TextInput
                required
                id="total"
                label="Total (incl. GST)"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                type="number"
                value={formTotal}
                helperText={formTotalHelper}
                error={formTotalHelper !== ""}
                onChange={(val) => setformTotal(val.target.value)}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextInput
              required
              id="invoiceRef"
              // name=""
              label="Invoice Reference"
              fullWidth
              value={formInvoiceRef}
              helperText={formInvoiceRefHelper}
              error={formInvoiceRefHelper !== ""}
              onChange={(val) => setformInvoiceRef(val.target.value)}
            />
          </Grid>

          <Grid item xs={12}>
            <Div>File containing the invoice:</Div>
            <Div>
              {plainFiles.map((file) => (
                <Div key={file.name}>{file.name}</Div>
              ))}
              <Button
                sx={{ m: 1 }}
                variant="contained"
                onClick={() => openFileSelector()}
              >
                Select file
              </Button>
              <Button sx={{ m: 1 }} variant="contained" onClick={() => clear()}>
                Clear
              </Button>
            </Div>
            <Div style={{ color: "red" }}>
              {errors.length > 0 && "Errors: "}
              {errors.length > 0 &&
                errors[0].fileSizeTooSmall &&
                "File size is too small!"}
              {errors.length > 0 &&
                errors[0].fileSizeToolarge &&
                "File size is too large!"}
              {errors.length > 0 &&
                errors[0].readerError &&
                "Problem occured while reading file!"}
              {errors.length > 0 &&
                errors[0].maxLimitExceeded &&
                "Too many files"}
              {errors.length > 0 &&
                errors[0].minLimitNotReached &&
                "Not enough files"}
            </Div>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              disabled={uploading || filesContent.length <= 0}
              sx={{ width: 100, m: 1, p: 2 }}
              onClick={() => {
                uploadFile();
              }}
            >
              Add
            </Button>
            <Button
              variant="contained"
              disabled={uploading}
              sx={{ width: 100, m: 1, p: 2 }}
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

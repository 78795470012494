import { API, graphqlOperation } from "aws-amplify";

export const graphqlGetAllItems = async (query, variables) => {
    var results = [];
    var queryName;
    var nextToken;

    variables.limit = 100;  // max rows per call

    // try {
      var result = await API.graphql({
        query: query,
        variables: variables,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      // Get name of the query
      for (var prop in result.data) {
        queryName = prop;
        results.push(...result.data[prop].items);
        nextToken = result.data[prop].nextToken;
      }
      // is there more data?
      while (nextToken) {
        variables.nextToken = nextToken;
        result = await API.graphql({
          query: query,
          variables: variables,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        // Get name of the query
        for (var prop2 in result.data) {
          results.push(...result.data[prop2].items);
          nextToken = result.data[prop2].nextToken;
        }
      }
      return results.filter((x) => !x._deleted);
    // } catch ({ name, message }) {
    //   console.log(`Could not load ${queryName}`, name, message);
    // }
  };

import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Copyright from '../components/Copyright';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useAuth from '../components/AuthContextProvider';
import { Auth } from 'aws-amplify';
import logo from '../logo1.png';
import TextInput from '../components/TextInput';

export default function SignIn() {
  const [errorMsg, setErrorMsg] = React.useState('');
  const [errorUsername, setErrorUsername] = React.useState(false);
  const [errorPassword, setErrorPassword] = React.useState(false);
  const navigate = useNavigate();
  const { login, authed } = useAuth();

  useEffect(() => {
    if (authed) {
      navigate("/dashboard");
    }
  }, [authed]);

  const signin = () => {
    login().then(() => {
      navigate('/dashboard');
    });
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });

    // validate form
    if (data.get("password").length === 0 && data.get("email").length === 0) {
      setErrorPassword(true);
      setErrorUsername(true);
      setErrorMsg("Please enter email and password");
      return;
    }

    if (data.get("password").length === 0) {
      setErrorPassword(true);
      setErrorMsg("Password can't be empty.");
      return;
    }

    if (data.get("email").length === 0) {
      setErrorUsername(true);
      setErrorMsg("Email address can't be empty");
      return;
    }
    
    const user = Auth.signIn(data.get('email'), data.get('password'));
    user.then((val) => {
      // console.log(user);
      signin();
    }).catch((error) => {
      console.log('error signing in', error);
      const err = error.toString();
      const desc = err.split(':')[1];
      //no need to process individual exceptions, any error is some sort of login failure
      setErrorMsg(desc);
      setErrorUsername(true);
      setErrorPassword(true);
    });
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: 'transparent'
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box sx={{ m: 4, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <img width="100px" src={logo} alt="Logo" />
              <Typography sx={{ ml: 1, fontSize: 36, fontWeight: 'fontWeightHeavy' }}>CoFlowx</Typography>
            </Box>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextInput
                margin="normal"
                required
                fullWidth
                error={errorUsername}
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={() => setErrorUsername(false)}
              />
              <TextInput
                margin="normal"
                required
                fullWidth
                error={errorPassword}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={() => setErrorPassword(false)}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Box>
                {errorMsg && errorMsg.length > 0 &&
                  <div>
                    <Typography sx={{ mt: 1, color: 'red' }}>
                      {errorMsg}
                    </Typography>
                  </div>
                }
              </Box>
              <Grid container sx={{ mt: 2 }}>
                <Grid item xs>
                  <Link href="/forgotpassword" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item xs>
                  <Link href="/signup" variant="body2">
                    Don't have an account? Sign Up
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Box>
    </Box >
  );
}

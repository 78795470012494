import * as React from 'react';
import Chip from '@mui/material/Chip';
import * as models from '../models';
import { Tooltip } from '@mui/material';
import useAuth from "../components/AuthContextProvider";

export function InvoiceStatus({ status }) {
  const { accountTypeAgency, accountTypeTalent } = useAuth();

  if (status == models.InvoiceStatus.REJECTED) {
    return (
      <Tooltip
        placement="top"
        title="The invoice has been rejected"
      >
        <Chip label="Rejected" color="error" />
      </Tooltip>
    )
  }
  else if (accountTypeAgency && status == models.InvoiceStatus.SUBMITTED) {
    return (
      <Tooltip
        placement="top"
        title="The invoice has been submitted for your approval"
      >
        <Chip label="Submitted" color="primary" />
      </Tooltip>
    )
  }
  else if (accountTypeTalent && status == models.InvoiceStatus.SUBMITTED) {
    return (
      <Tooltip
        placement="top"
        title="The invoice has been submitted to the Agency for approval"
      >
        <Chip label="Submitted" color="primary" />
      </Tooltip>
    )
  }
  else if (accountTypeAgency && status == models.InvoiceStatus.APPROVED) {
    return (
      <Tooltip
        placement="top"
        title="The invoice has been approved"
      >
        <Chip label="Approved" color="success" />
      </Tooltip>
    )
  }
  else if (accountTypeTalent && status == models.InvoiceStatus.APPROVED) {
    return (
      <Tooltip
        placement="top"
        title="The invoice has been approved by the Agency"
      >
        <Chip label="Approved" color="success" />
      </Tooltip>
    )
  }

  return (
    <div>(err)</div>
  );
}

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import App from "./App";
import { AuthProvider } from "./components/AuthContextProvider";
import theme from "./theme";
import Support from "./components/Hotjar";

Amplify.configure(awsconfig); // Configures the Amplify libraries with the cloud backend set up via the Amplify CLI
document.title = "CoFlowx";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <BrowserRouter>
          <Support>
            <App />
          </Support>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import * as mutations from "../graphql/mutations";
import Title from "../components/Title";
import { API, Storage } from "aws-amplify";
import * as queries from "../graphql/queries";
import { useFilePicker } from "use-file-picker";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import useAuth from "../components/AuthContextProvider";
import { Container } from "@mui/system";
import TextInput from "../components/TextInput";
import { ROUTES } from "../components/routes";

const Div = styled("div")(({ theme }) => ({
  ...theme.typography.body1,
  padding: theme.spacing(1),
}));

const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $talentId: ID
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Organisation {
          id
          Name
          Email
        }
        organisationProjectsId
        Title
        Deliverables(filter: { deliverableResourceId: { eq: $talentId } }) {
          items {
            id
            Title
            deliverableResourceId
          }
        }
        _deleted
      }
      nextToken
      startedAt
    }
  }
`;

function MultiProjectInvoiceForm() {
  const [loading, setLoading] = React.useState(false);
  const [projects, setProjects] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [agency, setAgency] = useState();
  const { ready, userID, userOrgID, accountTypeAgency, accountTypeTalent } =
    useAuth();
  const navigate = useNavigate();

  const [formData, setFormData] = useState([
    {
      projectID: "",
      deliverableID: "",
      hours: "",
      subTotal: "",
      gst: false,
      supplier: "",
      total: "",
    },
  ]);

  const [formTotalHours, setFormTotalHours] = useState(0.0);
  const [formTotalAmount, setFormTotalAmount] = useState(0.0);
  const [formInvoiceRefNumber, setFormInvoiceRefNumber] = useState("");
  const [formDueDate, setFormDueDate] = useState("");
  const [formDueDateHelper, setFormDueDateHelper] = useState("");
  const [formInvoiceRefNumberHelper, setFormInvoiceRefNumberHelper] =
    useState("");
  const [deliverables, setDeliverables] = useState(
    Array(formData.length).fill([])
  );
  const [lineErrors, setLineErrors] = useState(
    formData.map(() => ({
      projectError: "",
      deliverableError: "",
      hoursError: "",
      subTotalError: "",
      supplierError: "",
      totalError: "",
    }))
  );

  const selectAgency = (agen) => {
    setAgency(agen);
    fetchProjects(agen.Organisation.id);
  };

  const handleAddClick = () => {
    setFormData([
      ...formData,
      {
        projectID: "",
        deliverableID: "",
        hours: "",
        subTotal: "",
        gst: false,
        supplier: "",
        total: "",
      },
    ]);

    // Adding a lineErrors entry for the new line
    setLineErrors([
      ...lineErrors,
      {
        projectIDError: "",
        deliverableIDError: "",
        hoursError: "",
        subTotalError: "",
        supplierError: "",
        totalError: "",
      },
    ]);
  };
  const handleChange = (e, i) => {
    const { name, value } = e.target;
    const onChangeVal = [...formData];
    onChangeVal[i][name] = value;
    setFormData(onChangeVal);

    let hours = 0;
    onChangeVal.forEach(function (element) {
      if (element.hours) {
        hours += parseFloat(element.hours);
      }
    });
    setFormTotalHours(hours);

    let amount = 0;
    onChangeVal.forEach((element) => {
      if (element.total) {
        amount += parseFloat(element.total);
      }
    });
    setFormTotalAmount(amount);
  };

  const handleGSTChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.checked;
    const onChangeVal = [...formData];
    onChangeVal[i][name] = value;
    setFormData(onChangeVal);
  };

  const handleDeleteClick = (i) => {
    const deleteVal = [...formData];
    deleteVal.splice(i, 1); // remove formData for the deleted line
    setFormData(deleteVal);

    const deleteLineError = [...lineErrors];
    deleteLineError.splice(i, 1); // remove validation errors for the deleted line
    setLineErrors(deleteLineError);

    const newDeliverables = [...deliverables];
    newDeliverables.splice(i, 1); // remove the deliverables for the deleted line
    setDeliverables(newDeliverables);
  };

  const [
    openFileSelector,
    { filesContent, uploading, errors, plainFiles, clear },
  ] = useFilePicker({
    readAs: "ArrayBuffer", // available formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: [".pdf"], // accepted file extensions
    limitFilesConfig: { min: 1, max: 1 }, // number of files
    maxFileSize: 10, // in megabytes
  });

  useEffect(() => {
    if (!ready) {
      return;
    }
    //fetchProjects();
    fetchAgencies();
  }, [ready]);

  const setProject = (proj, index) => {
    const newFormData = [...formData];
    newFormData[index].projectID = proj;
    setFormData(newFormData);
    fetchDeliverables(proj, index);
  };

  const fetchAgencies = () => {
    setLoading(true);
    const result = API.graphql({
      query: queries.listTalents,
      variables: { filter: { talentUserId: { eq: userID } } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var filtered = val.data.listTalents.items.filter((x) => !x._deleted);
        setAgencies(filtered);
        setLoading(false);
      })
      .catch(({ name, message }) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load projects", name, message);
        }
        // alert("Could not load projects, check your internet connection")
      });
    // }
  };

  const fetchProjects = (agencyID) => {
    // if (accountTypeAgency) {
    //   const result = API.graphql({
    //     query: queries.listProjectsByOrg,
    //     variables: {
    //       organisationProjectsId: userOrgID,
    //       sortDirection: "DESC",
    //       limit: 100,
    //     },
    //     authMode: "AMAZON_COGNITO_USER_POOLS",
    //   });
    //   result
    //     .then((val) => {
    //       let filtered = val.data.listProjectsByOrg.items.filter(
    //         (x) => !x._deleted
    //       );
    //       setProjects(filtered);
    //     })
    //     .catch(({ name, message }) => {
    //       setLoading(false);
    //       if (message.includes("No current user")) {
    //         window.location.reload();
    //       } else {
    //         console.log("Could not load projects", name, message);
    //       }
    //       // alert("Could not load projects, check your internet connection")
    //     });
    // } else if (accountTypeTalent)
    // {
    const result = API.graphql({
      query: listProjects,
      variables: { talentId: userID },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        let projs = val.data.listProjects.items.filter((x) => !x._deleted);
        let filtered = projs.filter(
          (prj) =>
            prj.Deliverables.items.length > 0 &&
            prj.organisationProjectsId == agencyID
        );
        setProjects(filtered);
      })
      .catch(({ name, message }) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load projects", name, message);
        }
        // alert("Could not load projects, check your internet connection")
      });
    // }
  };

  const fetchDeliverables = (projectId, index) => {
    setLoading(true);
    const filter = { projectID: { eq: projectId } };
    if (accountTypeTalent) {
      filter.deliverableResourceId = { eq: userID };
    }
    const result = API.graphql({
      query: queries.listDeliverables,
      variables: { filter: filter },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        const filtered = val.data.listDeliverables.items.filter(
          (x) => !x._deleted
        );

        const newDeliverables = [...deliverables];
        newDeliverables[index] = filtered; // adds the deliverables for the selected project
        setDeliverables(newDeliverables);

        setLoading(false);
      })
      .catch(({ name, message }) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load deliverables", name, message);
        }
        // alert("Could not load deliverables, check your internet connection")
      });
  };

  const validateForm = () => {
    let valid = true;

    const newLineErrors = [...lineErrors];

    if (formInvoiceRefNumber.trim() === "") {
      setFormInvoiceRefNumberHelper("Invoice Reference Number can't be empty");
      valid = false;
    }

    if (formDueDate.trim() === "") {
      setFormDueDate("");
      setFormDueDateHelper("Due Date can't be empty");
      valid = false;
    }

    for (let i = 0; i < formData.length; i++) {
      const { projectID, deliverableID, hours, subTotal, supplier, total } =
        formData[i];
      const lineError = {};

      if (projectID.trim() === "") {
        lineError.projectError = "Project can't be empty";
        valid = false;
      }

      if (deliverableID.trim() === "") {
        lineError.deliverableError = "Deliverable can't be empty";
        valid = false;
      }

      if (accountTypeTalent) {
        if (
          (typeof hours === "string" && hours.trim() === "") ||
          parseFloat(hours) === 0
        ) {
          lineError.hoursError = "Hours can't be empty or zero";
          valid = false;
        } else if (parseFloat(hours) < 0) {
          lineError.hoursError = "Hours must be non-negative number";
          valid = false;
        }
        if (typeof subTotal === "string" && subTotal.trim() === "") {
          lineError.subTotalError = "Sub Total can't be empty";
          valid = false;
        } else if (parseFloat(subTotal) < 0) {
          lineError.subTotalError = "Sub Total must be non-negative number";
          valid = false;
        }
      }

      if (accountTypeAgency) {
        if (supplier.trim() === "") {
          lineError.supplierError = "Supplier can't be empty";
          valid = false;
        }
        if (typeof total === "string" && total.trim() === "") {
          lineError.totalError = "Total can't be empty";
          valid = false;
        } else if (!(parseFloat(total) > 0)) {
          lineError.totalError = "Total must be postive number";
          valid = false;
        }
      }

      newLineErrors[i] = lineError;
    }

    setLineErrors(newLineErrors);
    return valid;
  };

  const addNewInvoice = (fileKey) => {
    if (!validateForm()) {
      return;
    }

    const invoiceDeliverables = [];
    let toOrg = projects.find(
      (x) => x.id === formData[0].projectID
    ).Organisation;
    let toEmail = toOrg.Email;
    let toOrgId = toOrg.id;

    formData.forEach((data) => {
      const invoiceItem = {
        deliverableID: data.deliverableID,
        projectID: data.projectID,
        subTotal: data.subTotal,
        hours: data.hours,
        supplier: data.supplier,
        tax: data.gst,
        total: data.total,
      };
      invoiceDeliverables.push(invoiceItem);
    });

    const newInvoice = {
      owner: userID,
      ToEmail: toEmail,
      invoiceTalentId: accountTypeAgency ? "" : userID,
      Total: formTotalAmount,
      Hours: formTotalHours,
      Status: accountTypeAgency ? "APPROVED" : "SUBMITTED",
      Type: accountTypeAgency ? "SUPPLIER" : "TALENT",
      File: fileKey,
      invoiceReferenceNumber: formInvoiceRefNumber,
      Deliverables: JSON.stringify(invoiceDeliverables),
      deliverableID: " ",
      Custom2: toOrgId,
      DueDate: formDueDate,
    };

    const exec = API.graphql({
      query: mutations.createInvoice,
      variables: { input: newInvoice },
    });

    exec
      .then((x) => {
        console.log(x);
        navigate(ROUTES.invoices);
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const uploadFile = async () => {
    if (!validateForm()) {
      return;
    }

    console.log("Uploading...");
    console.log(filesContent[0]);
    try {
      const blob = new Blob([filesContent[0].content]); //, { type: "application/octet-stream;charset=utf-8" });
      const filename = `public/Invoices/${Date.now()}_${filesContent[0].name}`;
      const upload = Storage.put(filename, blob, {
        resumable: true,
        completeCallback: (event) => {
          console.log(`Successfully uploaded ${event.key}`);
          let eventKey = filename;
          console.log(eventKey);
          addNewInvoice(eventKey);
        },
        progressCallback: (progress) => {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
        errorCallback: (err) => {
          console.error("Unexpected error while uploading", err);
          alert("Could not upload invoice, check your internet connection");
        },
      });
    } catch (error) {
      console.error("Error while uploading the invoice:", error);
      alert("Could not upload invoice, please try again.");
    }
  };

  const updateGST = (val, i) => {
    let value = 0;
    if (formData[i]["gst"]) {
      value = (val * 1.1).toFixed(2);
    } else {
      value = val;
    }
    const name = "total";
    const onChangeVal = [...formData];
    onChangeVal[i][name] = value;
    setFormData(onChangeVal);

    let amount = 0;
    onChangeVal.forEach((element) => {
      if (element.total) {
        amount += parseFloat(element.total);
      }
    });
    setFormTotalAmount(amount);
  };

  const updateGSTTotal = (event, i) => {
    let value = 0;
    if (event.target.checked) {
      value = (formData[i]["subTotal"] * 1.1).toFixed(2);
    } else {
      value = formData[i]["subTotal"];
    }
    const name = "total";
    const onChangeVal = [...formData];
    onChangeVal[i][name] = value;
    setFormData(onChangeVal);

    let amount = 0;
    onChangeVal.forEach((element) => {
      if (element.total) {
        amount += parseFloat(element.total);
      }
    });
    setFormTotalAmount(amount);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      <Box
        sx={{
          mb: 2,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>Add Invoice</Title>
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.75)", fontSize: "0.9rem" }}
            >
              Upload a single invoice for multiple projects or deliverables.
            </Typography>
          </Stack>
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Box
            component="form"
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
              ml: 10,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Div>Select the agency:</Div>
                <FormControl fullWidth required disabled={agency}>
                  <InputLabel id="agency">Agency</InputLabel>
                  <Select
                    id="agency"
                    name="agencyID"
                    label="agency"
                    value={agency}
                    onChange={(val) => {
                      selectAgency(val.target.value);
                    }}
                  >
                    {agencies &&
                      agencies.map((agen) => {
                        return (
                          <MenuItem key={agen.id} value={agen}>
                            {agen.Organisation.Name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container xs={12}>
              {/* <Grid container item spacing={2} sx={{ m: 0.5 }}>
                <Grid item xs={6.125}>
                  <TextInput
                    required
                    id="invoice-Reference"
                    name="invoiceRefNumber"
                    label="Invoice Reference Number"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start"> </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    autoFocus={true}
                    value={formInvoiceRefNumber}
                    helperText={formInvoiceRefNumberHelper}
                    error={formInvoiceRefNumberHelper !== ""}
                    onChange={(val) => {
                      setFormInvoiceRefNumber(val.target.value);
                      setFormInvoiceRefNumberHelper("");
                    }}
                  />
                </Grid>
                {accountTypeTalent && (
                  <Grid item xs={2}>
                    <TextInput
                      required
                      id="dueDate"
                      name="dueDate"
                      label="Due Date"
                      type="date"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"></InputAdornment>
                        ),
                      }}
                      value={formDueDate}
                      helperText={formDueDateHelper}
                      error={formDueDateHelper !== ""}
                      onChange={(val) => {
                        setFormDueDate(val.target.value);
                        setFormDueDateHelper("");
                      }}
                    />
                  </Grid>
                )}
              </Grid> */}
              {agency && (
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12}>
                    <TextInput
                      required
                      id="invoice-Reference"
                      name="invoiceRefNumber"
                      label="Invoice Reference Number"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start"> </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      autoFocus={true}
                      value={formInvoiceRefNumber}
                      helperText={formInvoiceRefNumberHelper}
                      error={formInvoiceRefNumberHelper !== ""}
                      onChange={(val) => {
                        setFormInvoiceRefNumber(val.target.value);
                        setFormInvoiceRefNumberHelper("");
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Div>Line items:</Div>
                    {formData.map((data, index) => (
                      <Grid
                        container
                        item
                        spacing={2}
                        sx={{ m: 0.5 }}
                        key={index}
                      >
                        <Grid item xs={2}>
                          <FormControl fullWidth required>
                            <InputLabel id="project">Project</InputLabel>
                            <Select
                              id="project"
                              name="projectID"
                              label="Project"
                              value={data.projectID}
                              onChange={(val) => {
                                handleChange(val, index);
                                setProject(val.target.value, index);
                                lineErrors[index].projectError = "";
                              }}
                            >
                              {projects?.map((prj) => {
                                return (
                                  <MenuItem value={prj.id} key={prj.id}>
                                    {prj.Title}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                          <FormHelperText
                            error={lineErrors[index]?.projectError !== ""}
                          >
                            {lineErrors[index]?.projectError}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={2}>
                          <FormControl fullWidth required>
                            <InputLabel id="deliverable">
                              Deliverable
                            </InputLabel>
                            <Select
                              id="deliverable"
                              name="deliverableID"
                              label="Deliverable"
                              value={data.deliverableID}
                              disabled={!data.projectID}
                              onChange={(val) => {
                                handleChange(val, index);
                                lineErrors[index].deliverableError = "";
                              }}
                            >
                              {deliverables[index]?.map((del) => {
                                return (
                                  <MenuItem value={del.id} key={del.id}>
                                    {del.Title}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <FormHelperText
                              error={lineErrors[index]?.deliverableError !== ""}
                            >
                              {lineErrors[index]?.deliverableError}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        {accountTypeTalent && (
                          <Grid item xs={2}>
                            <TextInput
                              required
                              id="hours"
                              name="hours"
                              label="Hours"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {" "}
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                              value={data.hours}
                              onChange={(val) => {
                                handleChange(val, index);
                                lineErrors[index].hoursError = "";
                              }}
                            />
                            <FormHelperText
                              error={lineErrors[index]?.hoursError !== ""}
                            >
                              {lineErrors[index]?.hoursError}
                            </FormHelperText>
                          </Grid>
                        )}

                        {accountTypeTalent && (
                          <Grid item xs={2}>
                            <TextInput
                              required
                              id="subTotal"
                              name="subTotal"
                              label="Sub Total (excl. GST)"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                              value={data.subTotal}
                              onChange={(val) => {
                                handleChange(val, index);
                                lineErrors[index].subTotalError = "";
                                updateGST(val.target.value, index);
                              }}
                            />
                            <FormHelperText
                              error={lineErrors[index]?.subTotalError !== ""}
                            >
                              {lineErrors[index]?.subTotalError}
                            </FormHelperText>
                          </Grid>
                        )}
                        {accountTypeTalent && (
                          <Grid item xs={1}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  name="gst"
                                  checked={data.gst}
                                  onChange={(event) => {
                                    handleGSTChange(event, index);
                                    updateGSTTotal(event, index);
                                  }}
                                />
                              }
                              label="GST"
                            />
                          </Grid>
                        )}
                        {accountTypeTalent && data.gst && (
                          <Grid item xs={2}>
                            <TextInput
                              required
                              id="total"
                              name="total"
                              label="Total (incl. GST)"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                              value={data.total}
                              onChange={(val) => handleChange(val, index)}
                            />
                          </Grid>
                        )}
                        {accountTypeAgency && (
                          <Grid item xs={2}>
                            <TextInput
                              required
                              fullWidth
                              id="supplier"
                              name="supplier"
                              label="Supplier"
                              value={data.supplier}
                              onChange={(val) => {
                                handleChange(val, index);
                                lineErrors[index].supplierError = "";
                              }}
                            />
                            <FormHelperText
                              error={lineErrors[index]?.supplierError !== ""}
                            >
                              {lineErrors[index]?.supplierError}
                            </FormHelperText>
                          </Grid>
                        )}
                        {accountTypeAgency && (
                          <Grid item xs={2}>
                            <TextInput
                              required
                              id="total"
                              name="total"
                              label="Total (incl. GST)"
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                ),
                              }}
                              type="number"
                              value={data.total}
                              onChange={(val) => {
                                handleChange(val, index);
                                lineErrors[index].totalError = "";
                              }}
                            />
                            <FormHelperText
                              error={lineErrors[index]?.totalError !== ""}
                            >
                              {lineErrors[index]?.totalError}
                            </FormHelperText>
                          </Grid>
                        )}
                        {formData.length > 1 && (
                          <Button
                            sx={{ m: 1 }}
                            size="small"
                            variant="contained"
                            onClick={() => handleDeleteClick(index)}
                          >
                            Delete
                          </Button>
                        )}
                        <Grid item xs={12}>
                          {formData.length - 1 === index && (
                            <Box sx={{ "& button": { m: 1 } }}>
                              <div>
                                <Button
                                  variant="contained"
                                  size="medium"
                                  onClick={() => handleAddClick()}
                                >
                                  Add Project
                                </Button>
                              </div>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item xs={12}>
                      <Div sx={{ mb: 2 }}>Totals:</Div>
                      <Stack direction="row" spacing={2}>
                        {accountTypeTalent && (
                          <TextInput
                            required
                            disabled
                            id="totalHours"
                            name="totalHours"
                            label="Total Hours"
                            type="number"
                            value={formTotalHours}
                          />
                        )}
                        <TextInput
                          required
                          disabled
                          id="totalAmount"
                          name="totalAmount"
                          label="Total Amount"
                          type="number"
                          value={formTotalAmount}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Div>File containing the invoice:</Div>
                      <Div>
                        {plainFiles.map((file) => (
                          <Div key={file.name}>{file.name}</Div>
                        ))}
                        <Button
                          sx={{ m: 1 }}
                          variant="contained"
                          onClick={() => openFileSelector()}
                        >
                          Select file
                        </Button>
                        <Button
                          sx={{ m: 1 }}
                          variant="contained"
                          onClick={() => clear()}
                        >
                          Clear
                        </Button>
                      </Div>
                      <Div style={{ color: "red" }}>
                        {errors.length > 0 && "Errors: "}
                        {errors.length > 0 &&
                          errors[0].fileSizeTooSmall &&
                          "File size is too small!"}
                        {errors.length > 0 &&
                          errors[0].fileSizeToolarge &&
                          "File size is too large!"}
                        {errors.length > 0 &&
                          errors[0].readerError &&
                          "Problem occured while reading file!"}
                        {errors.length > 0 &&
                          errors[0].maxLimitExceeded &&
                          "Too many files"}
                        {errors.length > 0 &&
                          errors[0].minLimitNotReached &&
                          "Not enough files"}
                      </Div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        required
                        id="dueDate"
                        name="dueDate"
                        label="Due Date"
                        type="date"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Date
                            </InputAdornment>
                          ),
                        }}
                        value={formDueDate}
                        helperText={formDueDateHelper}
                        error={formDueDateHelper !== ""}
                        onChange={(val) => {
                          setFormDueDate(val.target.value);
                          setFormDueDateHelper("");
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        disabled={uploading || filesContent.length <= 0}
                        sx={{ width: 100, m: 1, p: 2 }}
                        onClick={() => {
                          uploadFile();
                        }}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Box>
        </Container>
      )}
    </Box>
  );
}

export default function MultiProjectInvoice() {
  return <MultiProjectInvoiceForm />;
}

import * as React from 'react';
import { useCallback, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import * as mutations from '../graphql/mutations';
import { useFilePicker } from 'use-file-picker';
import { getUser } from '../graphql/queries';
import { S3Link } from './S3Link';
import { CircularProgress, Container, Stack } from '@mui/material';
import { green } from '@mui/material/colors';

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}));

export function UserProfileAttachmentUpload({ userID, fileDescription, readOnly }) {
  const [loading, setLoading] = React.useState(true);
  const [id, setId] = useState('');
  const [version, setVersion] = useState();
  const [fileLink, setfileLink] = useState('');
  const [fileUploaded, setfileUploaded] = useState(false);
  const [openFileSelector, { filesContent, uploading, errors, plainFiles, clear }] = useFilePicker({
    // multiple: true,
    readAs: 'ArrayBuffer', // available formats: "Text" | "BinaryString" | "ArrayBuffer" | "DataURL"
    accept: ['.pdf'], // accepted file extensions
    limitFilesConfig: { min: 1, max: 1 }, // number of files
    // minFileSize: 1, // in megabytes
    maxFileSize: 10, // in megabytes
    // readFilesContent: false, // ignores file content
  });

  useEffect(() => {
    if (userID) {
      loadExistingAttachment();
    }
  }, [userID]);

  const loadExistingAttachment = () => {
    const exec = API.graphql({ query: getUser, variables: { id: userID } });
    exec.then((x) => {
      // console.log(x.data.getUser);
      x.data.getUser.Attachments.items.forEach((y) => {
        if (y.Description == fileDescription && !fileUploaded) {
          setfileUploaded(true);
          // console.log(y);
          setId(y.id);
          setVersion(y._version);
          setfileLink(y.File);
        }
      });
      setLoading(false);
    });
  }

  const uploadFile = async () => {
    console.log("Uploading...");
    console.log(filesContent[0]);
    const blob = new Blob([filesContent[0].content]); //, { type: "application/octet-stream;charset=utf-8" });
    // saveFile(blob, filesContent[0].name);
    // return;
    const filename = `public/Users/${userID}/${Date.now()}_${filesContent[0].name}`;
    const upload = Storage.put(filename, blob, {
      resumable: true,
      completeCallback: (event) => {
        console.log(`Successfully uploaded ${event.key}`);
        let eventKey = filename;
        console.log(eventKey);
        if (id)
          updateAttachment(eventKey, filename, filesContent[0].content.byteLength);
        else
          addAttachment(eventKey, filename, filesContent[0].content.byteLength);
      },
      progressCallback: (progress) => {
        console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
      errorCallback: (err) => {
        console.error('Unexpected error while uploading', err);
      }
    })
  }

  const addAttachment = async (fileKey, name, length) => {
    const attachment = {
      userID: userID,
      File: fileKey,
      Size: length,
      Type: name,
      Description: fileDescription,
    };
    const exec = API.graphql({ query: mutations.createAttachment, variables: { input: attachment } });
    exec.then((x) => {
      console.log(x);
      setfileLink(fileKey);
      setfileUploaded(true);
    }).catch((x) => {
      console.log(x);
    });
  }

  const updateAttachment = async (fileKey, name, length) => {
    const attachment = {
      id: id,
      _version: version,
      File: fileKey,
      Size: length,
      Type: name,
      Description: fileDescription,
    };

    const exec = API.graphql({ query: mutations.updateAttachment, variables: { input: attachment } });
    exec.then((x) => {
      console.log(x);
      setfileLink(fileKey);
      setfileUploaded(true);
    }).catch((x) => {
      console.log(x);
    });
  }

  const deleteAttachment = async () => {
    clear();
    const attachment = {
      id: id,
      _version: version,
      File: '',
      Size: 0,
      Type: '',
      Description: '',
    };

    const exec = API.graphql({ query: mutations.updateAttachment, variables: { input: attachment } });
    exec.then((x) => {
      console.log(x);
      setfileLink('');
      setfileUploaded(false);
    }).catch((x) => {
      console.log(x);
    });
  }

  return (
    <Box sx={{ border: 1, mb: 1, borderColor: 'darkgrey', borderRadius: 1 }}>
      <Div>
        <Stack direction="row" alignItems="center">
          {fileUploaded ? <CheckCircleIcon sx={{ color: "green", mr: 1 }} /> : <ErrorIcon sx={{ color: "red", mr: 1 }} />} {fileDescription}:
        </Stack>
        {fileLink &&
          <Stack direction="row">
            <S3Link file={fileLink} />
            {!readOnly && <IconButton color="error" sx={{ border: 1, m: 1 }} onClick={() => { deleteAttachment() }}>
              <DeleteIcon />
            </IconButton>}
          </Stack>}
      </Div>
      {!loading ? !fileLink &&
        <>
          <Div>
            {plainFiles.map(file => (
              <Div key={file.name}>{file.name}</Div>
            ))}
            {!readOnly &&
              <>
                <Button sx={{ m: 1 }} variant='contained' onClick={() => openFileSelector()}>Select file</Button>
                <Button sx={{ m: 1 }} variant='contained' onClick={() => clear()}>Clear</Button>
                <Button sx={{ m: 1 }} variant='contained' onClick={() => uploadFile()}>Upload</Button>
              </>
            }
          </Div>
          <Div style={{ color: 'red' }}>
            {errors.length > 0 && "Errors: "}
            {errors.length > 0 && errors[0].fileSizeTooSmall && 'File size is too small!'}
            {errors.length > 0 && errors[0].fileSizeToolarge && 'File size is too large!'}
            {errors.length > 0 && errors[0].readerError && 'Problem occured while reading file!'}
            {errors.length > 0 && errors[0].maxLimitExceeded && 'Too many files'}
            {errors.length > 0 && errors[0].minLimitNotReached && 'Not enough files'}
          </Div>
        </> :
        <CircularProgress
          size={72}
          sx={{
            color: green[500],
          }}
        />}
    </Box>
  );
}
import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import { CardActionArea } from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Title from "../components/Title";
import {
  Avatar,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { green } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import * as models from "../models";
import useAuth from "../components/AuthContextProvider";
import { useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import { ContractStatus } from "../components/ContractStatus";
import LaunchIcon from "@mui/icons-material/Launch";
import { listProjectsForTalent } from "../graphql/customQueries";
import EditIcon from "@mui/icons-material/Edit";
import { Checkbox } from "@mui/material";
import { UserProfilePicture } from "../components/UserProfilePicture";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid grey",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function TalentsContent() {
  const [loading, setLoading] = React.useState(true);
  const [invitations, setInvitations] = useState([]);
  const {
    ready,
    firstName,
    userID,
    userOrgID,
    accountTypeAgency,
    accountTypeTalent,
  } = useAuth();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [projects, setProjects] = useState([]);
  const [talent, setTalent] = useState([]);
  const [formName, setformName] = useState("");
  const [formNameHelper, setFormNameHelper] = useState("");
  const [formEmail, setformEmail] = useState("");
  const [formEmailHelper, setFormEmailHelper] = useState("");
  const [formMessage, setformMessage] = useState("");
  const [formMessageHelper, setFormMessageHelper] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  var [talentToDelete, setTalentToDelete] = useState(null);
  const navigate = useNavigate();

  const handleConfirmDeleteShow = (tal) => {
    setConfirmDelete(true);
    setTalentToDelete(tal);
  };

  const handleConfirmDeleteHide = () => {
    setConfirmDelete(false);
    setTalentToDelete(null);
  };

  useEffect(() => {
    if (ready) {
      fetchTalent();
      // fetchInvitations();
    }
  }, [ready]);

  const fetchProjects = async (id) => {
    const result = await API.graphql({
      query: listProjectsForTalent,
      variables: { talentId: id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    var filtered = result.data.listProjects.items.filter(
      (x) =>
        !x._deleted &&
        x.Deliverables.items.find(
          (y) => !y._deleted && y.deliverableResourceId == id
        )
    );
    return filtered.length;
  };

  const fetchTalent = () => {
    setLoading(true);
    const result = API.graphql({
      query: queries.listTalents,
      variables: { filter: { talentOrganisationId: { eq: userOrgID } } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    result
      .then((val) => {
        var filtered = val.data.listTalents.items.filter((x) => !x._deleted);
        // setTalent(filtered);
        setCount(filtered.length);
        getProjectCounts(filtered);
        // getContract();
        setLoading(false);
      })
      .catch(({ name, message }) => {
        if (message.includes("No current user")) {
          window.location.reload();
        } else {
          console.log("Could not load talent", name, message);
        }
        // alert("Could not load talent, check your internet connection")
      });
  };

  const getProjectCounts = (items) => {
    items.map((tal, index) => {
      tal["ProjectCount"] = "0";
      // **********************
      // TODO Fetch project counts
      // There is an issue here with the promise not forcing the item
      // to be redrawn.
      // **********************
      // var fetch = fetchProjects(tal.User.id);
      // fetch.then((x) => {
      //   tal['ProjectCount'] = x;
      //   tal['createdAt'] = '234';
      // });
    });
    // console.log(items);
    setTalent(items);
  };

  const getContract = (id) => {
    const exec = API.graphql({
      query: queries.getContract,
      variables: { id: id },
    });
    exec.then((x) => {
      console.log(x.data.getContract.Status);
    });
  };

  const fetchInvitations = async () => {
    const result = await API.graphql({
      query: queries.listInvitations,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    setInvitations(result.data.listInvitations.items);
  };

  const invite = () => {
    var valid = true;
    if (formName.trim() === "") {
      setformName("");
      setFormNameHelper("Name can't be empty");
      valid = false;
    }
    if (formEmail.trim() === "") {
      setformEmail("");
      setFormEmailHelper("Email can't be empty");
      valid = false;
    }
    // stop this validation because the field has been removed from the UI
    // if (formMessage.trim() === "") {
    //   setformMessage("");
    //   setFormMessageHelper("Message can't be empty");
    //   valid = false;
    // }
    if (!valid) {
      return;
    }

    const newInvite = {
      owner: userOrgID,
      invitationFromId: userOrgID,
      FromName: firstName,
      ToEmail: formEmail.trim(),
      ToName: formName.trim(),
      Message: formMessage.trim(),
      Status: models.InvitationStatus.SENT,
    };

    const exec = API.graphql({
      query: mutations.createInvitation,
      variables: { input: newInvite },
    });
    exec
      .then((x) => {
        console.log(x);
        // fetchInvitations();
      })
      .catch((err) => {
        console.log("Could not create invitation", err);
      });
    handleClose();
  };

  const deleteTalent = () => {
    setConfirmDelete(false);

    const talent = {
      id: talentToDelete.id,
      _version: talentToDelete._version,
    };

    const exec = API.graphql({
      query: mutations.deleteTalent,
      variables: { input: talent },
    });
    exec
      .then((x) => {
        console.log(x);
        fetchTalent();
      })
      .catch((err) => {
        console.log("Could not delete invitation", err);
      });
  };

  // const linkToContract = (id) => {
  //   navigate(`/contract/${id}/talent`);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateContractRate = (contract, rate, includeGST, gstValue, onFail) => {
    const exec = API.graphql({
      query: mutations.updateContract,
      variables: {
        input: {
          id: contract.id,
          _version: contract._version,
          Rate: rate,
          Custom1: gstValue,
          Custom2: includeGST
        },
      },
    })
      .then((val) => {
        fetchTalent();
      })
      .catch((err) => {
        console.log(err);
        onFail();
      });
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: "transparent",
        mb: 3,
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextInput
                required
                id="name"
                name="name"
                label="Name"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"> </InputAdornment>
                  ),
                }}
                value={formName}
                helperText={formNameHelper}
                error={formNameHelper !== ""}
                onChange={(val) => {
                  setformName(val.target.value);
                  setFormNameHelper("");
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                required
                id="email"
                name="email"
                label="Email"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"> </InputAdornment>
                  ),
                }}
                value={formEmail}
                helperText={formEmailHelper}
                error={formEmailHelper !== ""}
                onChange={(val) => {
                  setformEmail(val.target.value);
                  setFormEmailHelper("");
                }}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextInput
                required
                id="message"
                name="message"
                label="Message"
                fullWidth
                multiline
                rows={8}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start"> </InputAdornment>
                  ),
                }}
                value={formMessage}
                helperText={formMessageHelper}
                error={formMessageHelper !== ""}
                onChange={(val) => {
                  setformMessage(val.target.value);
                  setFormMessageHelper("");
                }}
              />
            </Grid> */}
            <Grid item xs={12}>
              <Button
                variant="contained"
                sx={{ width: 100, m: 1, p: 2 }}
                onClick={() => {
                  invite();
                }}
              >
                Invite
              </Button>
              <Button
                variant="contained"
                sx={{ width: 100, m: 1, p: 2 }}
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Dialog
        open={confirmDelete}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">{"Remove Talent"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Are you sure you want to remove this talent from your network?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteTalent}>Yes</Button>
          <Button onClick={handleConfirmDeleteHide} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          mb: 2,
          backgroundColor: "#ececec",
          borderBottom: "1px solid darkgrey",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "end",
            justifyContent: "space-between",
            p: 2,
            pl: 3,
            pr: 3,
          }}
        >
          <Stack>
            <Title>Talent</Title>
            <Typography
              sx={{ color: "rgba(0, 0, 0, 0.75)", fontSize: "0.9rem" }}
            >
              View the list of talents in your network
            </Typography>
          </Stack>
          {accountTypeAgency && (
            <div>
              <Button
                variant="contained"
                sx={{ m: 1, textTransform: "none" }}
                onClick={() => {
                  handleOpen();
                }}
              >
                Invite
              </Button>
            </div>
          )}
          {/* <CSVExporter data={projects} /> */}
        </Box>
      </Box>
      {loading ? (
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: "flex",
              flexDirection: "column",
            }}
          ></Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Box sx={{ display: "flex", justifyContent: "end", mb: 2 }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Name</StyledTableCell>
                  {/* <StyledTableCell>Expertise</StyledTableCell>
                  <StyledTableCell>Industry</StyledTableCell> */}
                  {/* <StyledTableCell>Contract</StyledTableCell>
                  <StyledTableCell>Active Projects</StyledTableCell> */}
                  <StyledTableCell>Hourly Rate</StyledTableCell>
                  {/* <StyledTableCell>Invoice</StyledTableCell> */}
                  <StyledTableCell>Delete</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {/* {invitations && invitations.map((inv) => {
                if (!inv._deleted) {
                  return (
                    <StyledTableRow key={inv.id}>
                      <StyledTableCell>{inv.To} <Chip label="Invited" color="primary" /></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </StyledTableRow>);
                }
              })} */}
                {talent &&
                  talent
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((tal) => {
                      return (
                        <StyledTableRow
                          key={tal.id}
                          onDoubleClick={() =>
                            navigate(`/profile/${tal.User.id}`)
                          }
                        >
                          <StyledTableCell sx={{ width: "400px" }}>
                            <Card
                              sx={{
                                bgcolor: "transparent", // otherwise bgcolor is white different from the page
                                boxShadow: "none",
                                "& .MuiCardActionArea-focusHighlight": {
                                  color: "transparent", // remove onhover highlight effect
                                },
                              }}
                            >
                              <CardActionArea
                                onClick={() =>
                                  navigate(`/profile/${tal.User.id}`)
                                }
                              >
                                <Stack direction="row">
                                  <UserProfilePicture
                                    userID={tal.User.id}
                                    readOnly
                                  />
                                  <Box
                                    sx={{
                                      ml: 1,
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <Typography sx={{ fontWeight: "bold" }}>
                                      {tal.User.FirstName} {tal.User.LastName}
                                    </Typography>
                                    <Typography variant="caption">
                                      Expertise: {tal.User.Expertise}
                                    </Typography>
                                    <Typography variant="caption">
                                      Industry: {tal.User.Industry}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </CardActionArea>
                            </Card>
                          </StyledTableCell>
                          {/* <StyledTableCell>
                            <Stack direction="column">
                              Status: Active
                              <Stack direction="row">
                                <IconButton
                                  sx={{ border: 1, m: 1 }}
                                  onClick={() =>
                                    linkToContract(tal.Contract.id)
                                  }
                                >
                                  <LaunchIcon />
                                </IconButton>
                              </Stack>
                            </Stack>
                          </StyledTableCell> */}
                          {/* <StyledTableCell>{tal.ProjectCount}</StyledTableCell> */}
                          <StyledTableCell>
                            <RateCellContent
                              contract={tal.Contract}
                              update={updateContractRate}
                            />
                          </StyledTableCell>
                          {/* <StyledTableCell>$0</StyledTableCell> */}
                          <StyledTableCell>
                            <IconButton
                              color="error"
                              sx={{ border: 1, m: 1 }}
                              onClick={() => handleConfirmDeleteShow(tal)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </Box>
  );
}

const RateCellContent = ({ contract, update }) => {
  const [rate, setRate] = useState(contract.Rate !== null ? contract.Rate : 0);
  const [showInput, setShowInput] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [includeGST, setIncludeGST] = useState(contract.Custom2 === 'true');
  const [gstValue, setGstValue] = useState(contract.Custom1 || 0);
  const [showGstInput, setShowGstInput] = useState(false);

  const onClick = () => {
    if (showInput) {
      update(contract, rate, includeGST, gstValue, () => setErrorMsg('Failed to update'));
      setShowInput(false);
    }
  };

  const onGstEditClick = () => {
    setShowGstInput(true);
  };

  const updateGstValue = () => {
    if (showGstInput) {
      setGstValue(gstValue);
      setShowGstInput(false);
      update(contract, rate, includeGST, gstValue, () => setErrorMsg('Failed to update'));
    }
  };

  useEffect(() => {
    if ((contract.Custom1 !== gstValue || (contract.Custom2 === 'true') !== includeGST) && !showGstInput) {
      update(contract, rate, includeGST, gstValue, () => setErrorMsg('Failed to update'));
    }
  }, [gstValue, includeGST, showGstInput]);

  return (
    <>
      {showInput === false ? (
        <Stack direction="row" alignItems="center">
          ${rate}
          <IconButton
            size="small"
            onClick={() => {
              setShowInput(true);
            }}
          >
            <EditIcon />
          </IconButton>
          <label>
            <input
              type="checkbox"
              checked={includeGST}
              onChange={() => setIncludeGST(!includeGST)}
            />
            GST
          </label>
        </Stack>
      ) : (
        <>
          <TextInput
            value={rate}
            onChange={(e) => {
              setRate(e.target.value);
              setErrorMsg('');
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
              endAdornment: <Button onClick={onClick}>Update</Button>,
            }}
            type="number"
            helperText={errorMsg}
            error={errorMsg !== ''}
            sx={{ width: '18ch' }}
            size="small"
            margin="dense"
          />
        </>
      )}
      {includeGST && (
        <Stack direction="row" alignItems="center">
          {showGstInput ? (
            <TextInput
              value={gstValue}
              onChange={(e) => {
                setGstValue(e.target.value);
                setErrorMsg('');
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
                endAdornment: <Button onClick={updateGstValue}>Update</Button>,
              }}
              type="number"
              sx={{ width: '18ch' }}
              size="small"
              margin="dense"
            />
          ) : (
            <>
              {gstValue}%
              <IconButton size="small" onClick={onGstEditClick}>
                <EditIcon />
              </IconButton>
            </>
          )}
        </Stack>
      )}
    </>
  );
};

export default function Talents() {
  return <TalentsContent />;
}

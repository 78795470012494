import * as React from "react";
import { useCallback, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import PublishIcon from "@mui/icons-material/Publish";
import { API, graphqlOperation, Storage } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as models from "../models";
import useAuth from "../components/AuthContextProvider";
import { Chip, Grid, Stack } from "@mui/material";

export function ProposalApproval({ pro, fetchProposals }) {
  const { userID, accountTypeAgency, accountTypeTalent } = useAuth();

  const acceptProposal = () => {
    const proposal = {
      id: pro.id,
      _version: pro._version,
      Status: models.ProposalStatus.ACCEPTED,
    };

    const exec = API.graphql({
      query: mutations.updateProposal,
      variables: { input: proposal },
    });
    exec
      .then((x) => {
        // console.log(x);
        fetchProposals();
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const declineProposal = () => {
    const proposal = {
      id: pro.id,
      _version: pro._version,
      Status: models.ProposalStatus.DECLINED,
    };

    const exec = API.graphql({
      query: mutations.updateProposal,
      variables: { input: proposal },
    });
    exec
      .then((x) => {
        // console.log(x);
        fetchProposals();
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const submitProposal = () => {
    const proposal = {
      id: pro.id,
      _version: pro._version,
      Status: models.ProposalStatus.SUBMITTED,
    };

    const exec = API.graphql({
      query: mutations.updateProposal,
      variables: { input: proposal },
    });
    exec
      .then((x) => {
        // console.log(x);
        fetchProposals();
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const approveProposal = () => {
    const proposal = {
      id: pro.id,
      _version: pro._version,
      Status: models.ProposalStatus.APPROVED,
    };

    const exec = API.graphql({
      query: mutations.updateProposal,
      variables: { input: proposal },
    });
    exec
      .then((x) => {
        // console.log(x);
        fetchProposals();
      })
      .catch((x) => {
        console.log(x);
      });
  };

  const rejectProposal = () => {
    const proposal = {
      id: pro.id,
      _version: pro._version,
      Status: models.ProposalStatus.REJECTED,
    };

    const exec = API.graphql({
      query: mutations.updateProposal,
      variables: { input: proposal },
    });
    exec
      .then((x) => {
        // console.log(x);
        fetchProposals();
      })
      .catch((x) => {
        console.log(x);
      });
  };

  if (accountTypeAgency) {
    if (pro.Status == models.ProposalStatus.ACCEPTED) {
      return <Chip label="Waiting for talent to submit" color="primary" />;
    } else if (pro.Status == models.ProposalStatus.DECLINED) {
      return;
    } else if (pro.Status == models.ProposalStatus.SENT) {
      return <Chip label="Waiting for talent to accept" color="primary" />;
    } else if (pro.Status == models.ProposalStatus.APPROVED) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          // alignItems="center"
          justifyContent="start"
        >
          <Grid item>Reject proposal?</Grid>
          <Grid item>
            <Stack direction="horizontal">
              {/* <IconButton color="success" sx={{ border: 1, m: 1 }} onClick={() => { approveProposal() }}>
            <DoneIcon />
          </IconButton> */}
              <IconButton
                color="warning"
                sx={{ border: 1, m: 1 }}
                onClick={() => {
                  rejectProposal();
                }}
              >
                <ClearIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      );
    } else if (
      pro.Status == models.ProposalStatus.SUBMITTED ||
      pro.Status == models.ProposalStatus.REJECTED
    ) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          // alignItems="center"
          justifyContent="start"
        >
          <Grid item>Accept proposal?</Grid>
          <Grid item>
            <Stack direction="horizontal">
              <IconButton
                color="success"
                sx={{ border: 1, m: 1 }}
                onClick={() => {
                  approveProposal();
                }}
              >
                <DoneIcon />
              </IconButton>
              <IconButton
                color="warning"
                sx={{ border: 1, m: 1 }}
                onClick={() => {
                  rejectProposal();
                }}
              >
                <ClearIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      );
    }
  } else if (accountTypeTalent) {
    if (pro.Status == models.ProposalStatus.SENT) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          // alignItems="center"
          justifyContent="start"
        >
          <Grid item>Accept invitation?</Grid>
          <Grid item>
            <Stack direction="horizontal">
              <IconButton
                color="success"
                sx={{ border: 1, m: 1 }}
                onClick={() => {
                  acceptProposal();
                }}
              >
                <DoneIcon />
              </IconButton>
              <IconButton
                color="warning"
                sx={{ border: 1, m: 1 }}
                onClick={() => {
                  declineProposal();
                }}
              >
                <ClearIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      );
    } else if (pro.Status == models.ProposalStatus.ACCEPTED) {
      return (
        <>
          {/* <IconButton color="success" sx={{ border: 1, m: 1 }} onClick={() => { acceptProposal() }}>
            <DoneIcon />
          </IconButton> */}
          <IconButton
            color="warning"
            sx={{ border: 1, m: 1 }}
            onClick={() => {
              declineProposal();
            }}
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            color="primary"
            sx={{ border: 1, m: 1 }}
            onClick={() => {
              submitProposal();
            }}
          >
            <PublishIcon />
          </IconButton>
        </>
      );
    } else if (pro.Status == models.ProposalStatus.DECLINED) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          // alignItems="center"
          justifyContent="start"
        >
          <Grid item>Accept invitation?</Grid>
          <Grid item>
            <Stack direction="horizontal">
              <IconButton
                color="success"
                sx={{ border: 1, m: 1 }}
                onClick={() => {
                  acceptProposal();
                }}
              >
                <DoneIcon />
              </IconButton>
              {/* <IconButton
                  color="warning"
                  sx={{ border: 1, m: 1 }}
                  onClick={() => {
                    declineProposal();
                  }}
                >
                  <ClearIcon />
                </IconButton> */}
            </Stack>
          </Grid>
        </Grid>
      );
    } else if (pro.Status == models.ProposalStatus.SUBMITTED) {
      return <Chip label="Waiting for approval" color="primary" />;
    } else if (pro.Status == models.ProposalStatus.APPROVED) {
      return <Chip label="Accepted" color="primary" />;
    } else if (pro.Status == models.ProposalStatus.REJECTED) {
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          // alignItems="center"
          justifyContent="start"
        >
          <Grid item>Resubmit proposal?</Grid>
          <Grid item>
            <Stack direction="horizontal">
              <IconButton
                color="primary"
                sx={{ border: 1, m: 1 }}
                onClick={() => {
                  submitProposal();
                }}
              >
                <PublishIcon />
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      );
    }
  }
  return <>(err)</>;
}

import * as React from 'react';
import { useCallback, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../components/Title';
import { green } from '@mui/material/colors';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../graphql/queries';
import * as models from '../models';
import useAuth from '../components/AuthContextProvider';
import { InvitationStatus } from '../components/InvitationStatus';
import { InvitationApproval } from '../components/InvitationApproval';
import { Container } from '@mui/system';
import { CircularProgress, Grid, Stack, Typography } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //   backgroundColor: theme.palette.black,
  // },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function InvitationsContent() {
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [count, setCount] = React.useState(0);
  const [invitations, setInvitations] = useState([]);
  const { ready, userID, userOrgID, email, accountTypeAgency, accountTypeTalent } = useAuth();

  useEffect(() => {
    if (ready) {
      fetchInvitations();
    }
  }, [ready]);

  const fetchInvitations = () => {
    setLoading(true);
    if (accountTypeAgency) {
      const result = API.graphql({
        query: queries.listInvitations,
        variables: { filter: { invitationFromId: { eq: userOrgID } } },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      result.then((val) => {
        var filtered = val.data.listInvitations.items.filter((x) => !x._deleted);
        setInvitations(filtered);
        setCount(filtered.length);
        setLoading(false);
      }).catch(({name, message}) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        }
        else {
          console.log("Could not load invitations", name, message);
        }
        // alert("Could not load invitations, check your internet connection")
      });
    }
    else if (accountTypeTalent) {
      const result = API.graphql({
        query: queries.listInvitations,
        variables: { filter: { ToEmail: { eq: email } } },
        authMode: "AMAZON_COGNITO_USER_POOLS"
      });
      result.then((val) => {
        var filtered = val.data.listInvitations.items.filter((x) => !x._deleted);
        setInvitations(filtered);
        setCount(filtered.length);
        setLoading(false);
      }).catch(({name, message}) => {
        setLoading(false);
        if (message.includes("No current user")) {
          window.location.reload();
        }
        else {
          console.log("Could not load invitations", name, message);
        }
        // alert("Could not load invitations, check your internet connection")
      });
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[900],
        caretColor: 'transparent',
        mb: 3
      }}
    >
      <Box sx={{ mb: 2, backgroundColor: '#ececec', borderBottom: '1px solid darkgrey' }}>
        <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', p: 2, pl: 3, pr: 3 }}>
          <Stack>
            <Title>Invitations</Title>
            <Typography sx={{ color: 'rgba(0, 0, 0, 0.75)', fontSize: '0.9rem' }}>
              View the list of invitations from agencies to join their network
            </Typography>
          </Stack>
        </Box>
      </Box>
      {loading ?
        <Container maxWidth="xl">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="start"
            // style={{ minHeight: '100vh' }}
            sx={{ pt: 20 }}
          >
            <CircularProgress
              size={72}
              sx={{
                color: green[500],
              }}
            />
          </Grid>
        </Container>
        :
        <Container maxWidth="xl">
          <Box
            sx={{
              p: 0,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <StyledTableRow>
                    {accountTypeAgency && <StyledTableCell>To</StyledTableCell>}
                    {accountTypeAgency && <StyledTableCell>Email</StyledTableCell>}
                    {accountTypeTalent && <StyledTableCell>From</StyledTableCell>}
                    <StyledTableCell>Message</StyledTableCell>
                    <StyledTableCell>Status</StyledTableCell>
                    <StyledTableCell>Actions</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {invitations && invitations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((inv) => {
                    return (
                      <StyledTableRow hover key={inv.id}>
                        {accountTypeAgency && <StyledTableCell>{inv.ToName}</StyledTableCell>}
                        {accountTypeAgency && <StyledTableCell>{inv.ToEmail}</StyledTableCell>}
                        {accountTypeTalent && <StyledTableCell>{inv.From && inv.From.Name}</StyledTableCell>}
                        <StyledTableCell>{inv.Message}</StyledTableCell>
                        <StyledTableCell><InvitationStatus status={inv.Status} /></StyledTableCell>
                        <StyledTableCell><InvitationApproval inv={inv} fetchInvitations={fetchInvitations} /></StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Container>}
    </Box >
  );
}

export default function Invitations() {
  return <InvitationsContent />;
}
import { useCallback, useState, useEffect, useContext } from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { ROUTES } from "./components/routes";
import Layout from "./components/Layout";
import Dashboard from "./pages/Dashboard";
import Projects from "./pages/Projects";
import Talents from "./pages/Talents";
import Invitations from "./pages/Invitations";
import Proposals from "./pages/Proposals";
import Budget from "./pages/Budget";
import Settings from "./pages/Settings";
import HomePage from "./pages/HomePage";
import SignIn from "./pages/SignIn";
import ForgotPassword from "./pages/ForgotPassword";
import ForgotUsername from "./pages/ForgotUsername";
import ResetPassword from "./pages/ResetPassword";
import SignUp from "./pages/SignUp";
import SignUpVerify from "./pages/SignUpVerify";
import useAuth from "./components/AuthContextProvider";
import Invoices from "./pages/Invoices";
import ProjectView from "./pages/ProjectView";
import { Modal, Typography } from "@mui/material";
import ProposalView from "./pages/ProposalView";
import UserProfileView from "./pages/UserProfileView";
import Clients from "./pages/Clients";
import DeliverableView from "./pages/DeliverableView";
import Agencies from "./pages/Agencies";
import ContractView from "./pages/ContractView";
import Organization from "./pages/Organization";
import XeroLink from "./pages/XeroLink";
import { TourProvider } from "@reactour/tour";
import { introTourAgency, introTourTalent } from "./tourConfig";
import SubscriptionView from "./pages/Subscription";
import MultiProjectInvoice from "./pages/MultiProjectInvoice";
import InvoiceView from "./pages/InvoiceView";

function RequireAuth({ children }) {
  const { authed } = useAuth();
  // console.log(authed);
  return authed === true ? children : <Navigate to="/" replace />;
}

function App() {
  const { ready, accountTypeAgency, accountTypeTalent, authed, authChecked } =
    useAuth();

  return (
    <>
      {!authChecked ? (
        <Typography>Loading...</Typography>
      ) : (
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signupverify/:id" element={<SignUpVerify />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/forgotusername" element={<ForgotUsername />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route element={<Layout />}>
            <Route
              path={ROUTES.dashboard}
              element={
                <RequireAuth>
                  <Tour>
                    <Dashboard />
                  </Tour>
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.projects}
              element={
                <RequireAuth>
                  <Projects />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.project}
              element={
                <RequireAuth>
                  <ProjectView tab={0} />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.projectDeliverables}
              element={
                <RequireAuth>
                  <ProjectView tab={1} />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.projectAdd}
              element={
                <RequireAuth>
                  <ProjectView />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.deliverable}
              element={
                <RequireAuth>
                  <DeliverableView />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.deliverableAdd}
              element={
                <RequireAuth>
                  <DeliverableView />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.talent}
              element={
                <RequireAuth>
                  <Talents />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.contract}
              element={
                <RequireAuth>
                  <ContractView />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.contractRet}
              element={
                <RequireAuth>
                  <ContractView />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.organization}
              element={
                <RequireAuth>
                  <Organization tab={0} />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.organizationIntegrations}
              element={
                <RequireAuth>
                  <Organization tab={1} />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.clients}
              element={
                <RequireAuth>
                  <Clients />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.agencies}
              element={
                <RequireAuth>
                  <Agencies />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.proposals}
              element={
                <RequireAuth>
                  <Proposals />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.proposalView}
              element={
                <RequireAuth>
                  <ProposalView />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.invoices}
              element={
                <RequireAuth>
                  <Invoices />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.invoiceView}
              element={
                <RequireAuth>
                  <InvoiceView />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.addinvoice}
              element={
                <RequireAuth>
                  <MultiProjectInvoice />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.invitations}
              element={
                <RequireAuth>
                  <Invitations />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.proposals}
              element={
                <RequireAuth>
                  <Proposals />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.budget}
              element={
                <RequireAuth>
                  <Budget />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.profile}
              element={
                <RequireAuth>
                  <UserProfileView />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.profileView}
              element={
                <RequireAuth>
                  <UserProfileView />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.settings}
              element={
                <RequireAuth>
                  <Settings />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.subscription}
              element={
                <RequireAuth>
                  <SubscriptionView />
                </RequireAuth>
              }
            />
            <Route
              path={ROUTES.xeroLink}
              element={
                <RequireAuth>
                  <XeroLink />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      )}
    </>
  );
}

function Tour({ children }) {
  const { ready, accountTypeAgency, accountTypeTalent } = useAuth();

  if (accountTypeAgency) {
    return <TourProvider steps={introTourAgency}>{children}</TourProvider>;
  } else if (accountTypeTalent) {
    return <TourProvider steps={introTourTalent}>{children}</TourProvider>;
  }
}

export default App;
